import _ from 'lodash';
import { SAVED_PLACE_CUSTOM_TYPE } from '../redux/actions/savedPlacesActions/savedPlacesConstant';

/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceObjectFromGoogleSuggestions(getState, index) {
    return {
        title: getState().map.googleSearchPlaces[ index ][ 'name' ],
        lat: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lat) ? getState().map.googleSearchPlaces[ index ].geometry.location.lat() : getState().map.googleSearchPlaces[ index ].geometry.location.lat,
        lng: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lng) ? getState().map.googleSearchPlaces[ index ].geometry.location.lng() : getState().map.googleSearchPlaces[ index ].geometry.location.lng,
        address: getState().map.googleSearchPlaces[ index ][ 'formatted_address' ],
    }
}
/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceObjectFromGoogleSuggestionsVicinity(getState, index) {
    let place = {
        title: getState().map.googleSearchPlaces[ index ][ 'name' ],
        lat: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lat) ? getState().map.googleSearchPlaces[ index ].geometry.location.lat() : getState().map.googleSearchPlaces[ index ].geometry.location.lat,
        lng: _.isFunction(getState().map.googleSearchPlaces[ index ].geometry.location.lng) ? getState().map.googleSearchPlaces[ index ].geometry.location.lng() : getState().map.googleSearchPlaces[ index ].geometry.location.lng,
        address: getState().map.googleSearchPlaces[ index ][ 'vicinity' ] || getState().map.googleSearchPlaces[ index ][ 'formatted_address' ],
    }
    if (place[ 'title' ] !== place[ 'address' ]) {
        place[ 'address' ] = place[ 'title' ] + ', ' + place[ 'address' ];
    }

    return place;
}
/**
 * create object place from google suggestions for pass throw google places and user location
 * @param {*} getState 
 */
export function createPlaceGoogleSuggestionsFromRawPlace(rawPlace) {
    if(!_.isEmpty(rawPlace)) {
        let place = {
            title: rawPlace[ 'name' ],
            lat: _.isFunction(rawPlace.geometry.location.lat) ? rawPlace.geometry.location.lat() : rawPlace.geometry.location.lat,
            lng: _.isFunction(rawPlace.geometry.location.lng) ? rawPlace.geometry.location.lng() : rawPlace.geometry.location.lng,
            address: rawPlace[ 'vicinity' ] || rawPlace[ 'formatted_address' ],
        }
        if (place[ 'title' ] !== place[ 'address' ]) {
            place[ 'address' ] = place[ 'title' ] + ', ' + place[ 'address' ];
        }
    
        return place;
    }
}
/**
 * 
 * @param {*} getState 
 * @param {*} place 
 */
export function addUserLocationToPlace(getState, place) {
    return {
        ...getState().map.location,
        title: place.title,
        lat: place.lat,
        lng: place.lng,
        address: place.address
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} location 
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleNearsearchSuggestions(results, location, index) {
    return {
        title: results[ index ][ 'name' ],
        lat: location.lat,
        lng: location.lng,
        address: results[ index ][ 'vicinity' ]
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} location 
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleGetExactAddress(results, location, index) {
    let formatted_address = '';
    if (_.has(results, index)) {
        formatted_address = filterAddressComponent(results[ index ][ 'address_components' ]);
    }

    return {
        title: formatted_address,
        lat: location.lat,
        lng: location.lng,
        address: formatted_address
    }
}
/**
 * filter address components
 * @param {*} addressComponents 
 */
export function filterAddressComponent(addressComponents) {
    let filterAddress = _.filter(addressComponents, (address) => {
        return !address.types.includes("country")
            && !address.types.includes("postal_code")
            && !address.types.includes("administrative_area_level_1")
            && !address.types.includes("administrative_area_level_2")
            && !address.types.includes("administrative_area_level_3")
            && !address.types.includes("administrative_area_level_4")
            && !address.types.includes("administrative_area_level_5")
    });
    return concatFilterAddressComponent(filterAddress);
}
/**
 * concat filter address component
 * @param {*} filterAddress 
 */
export function concatFilterAddressComponent(filterAddress) {
    let formatted_address = '';
    _.forEach(filterAddress, function (value, key) {
        formatted_address += value.short_name;
        if (filterAddress.length - 1 !== key) {
            formatted_address += ', ';
        }
    });
    return formatted_address;
}
/**
 * 
 * @param {*} getState 
 * @param {*} result
 * @param {*} index 
 */
export function createPlaceObjectFromGoogleNearsearchWithCurrentLocation(getState, result) {

    return {
        ...getState().map.location,
        title: result[ 'name' ],
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        address: result[ 'vicinity' ]
    }
}
/**
 * 
 * @param {*} results 
 * @param {*} index 
 */
export function createPlaceObjectForCurrentLocation(result) {
    return {
        title: result[ 'name' ],
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        address: result[ 'vicinity' ]
    }
}

export const createSavedPlaceObject = (title, getState) => {
    return {
        title,
        type: getState().savedPlaces.savedType,
        location: {
            name: getState().savedPlaces.savedPlace.title,
            address: getState().savedPlaces.savedPlace.address,
            lat: getState().savedPlaces.savedPlace.lat,
            lng: getState().savedPlaces.savedPlace.lng
        }
    }
}

export const createBookmarkedPlaceObject = (getState) => {
    return {
        title: getState.title ? getState.title : getState().savedPlaces.savedPlace.title,
        type: SAVED_PLACE_CUSTOM_TYPE,
        isBookmarked: true,
        location: {
            name: getState.title ? getState.title : getState().savedPlaces.savedPlace.title,
            address: getState.address ? getState.address : getState().savedPlaces.savedPlace.address,
            lat: getState.lat ? getState.lat : getState().savedPlaces.savedPlace.lat,
            lng: getState.lng ? getState.lat : getState().savedPlaces.savedPlace.lng
        }
    }
}