import {
    push
} from 'connected-react-router';

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import * as CONSTANT_ACTIONS from '../savedPlacesConstant';
import {
    createPlaceObjectFromSavedPlaceObject
} from '../../../../functions/create-place-object-saved-places';
import {
    mapSetPlaceGoogleSource,
    mapInputSelectAddressFirstDestination,
    MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT,
    MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION,
    MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW
} from '../../mapActions';
import {
    searchSetCurrentReferenceName
} from '../../searchActions';
import {
    addSavedPlace
} from './savedPlaces-create';

/**
 * check for selected place exist so can put in address box
 */
export const checkSelectedPlaceExist = (savedPlace) => {
    return (dispatch, getState) => {
        let place = createPlaceObjectFromSavedPlaceObject(savedPlace);
        if (getState().savedPlaces.searchPointer === CONSTANT_ACTIONS.LOCATION_SEARCH_NAME) {
            dispatch(mapSetPlaceGoogleSource(place));
            dispatch(push(getState().savedPlaces.searchPointer));
        } else if (getState().search.currentRefName !== '') {
            if (getState().search.currentRefName !== '' && getState().search.currentRefName === 'destination') {
                dispatch(mapInputSelectAddressFirstDestination([place]));
            } else {
                dispatch(mapSetPlaceGoogleSource(place));
            }
            dispatch(push(getState().savedPlaces.searchPointer));
        } else if (getState().savedPlaces.searchPointer.includes('add')) {
            let index = getState().savedPlaces.searchPointer.split('/').pop();
            index = parseInt(index);
            if (index === MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
                dispatch(mapSetPlaceGoogleSource(place));
            } else if (index >= MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
                let places = getState().map.googlePlaces;
                places[index - 1] = place;
                dispatch(mapInputSelectAddressFirstDestination(places));
            } else if (index === MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
                let places = getState().map.googlePlaces;
                places.push(place);
                dispatch(mapInputSelectAddressFirstDestination(places));
            }
            let link = getState().savedPlaces.searchPointer;
            link = link.split('/');
            link = link.slice(0, link.length - 1);
            link = link.join('/');
            dispatch(push(link));
        }
        dispatch(searchSetCurrentReferenceName(""));
    }
}
/**
 * Saved place selected address
 * @param {*} place 
 */
export const savedPlaceSelectedAddress = (place) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.selectSavedPlacesAddress(place));

        if (getState().savedPlaces.savedType === CONSTANT_ACTIONS.SAVED_PLACE_HOME_TYPE) {
            dispatch(addSavedPlace(CONSTANT_ACTIONS.SAVED_PLACE_HOME_TYPE));
        } else if (getState().savedPlaces.savedType === CONSTANT_ACTIONS.SAVED_PLACE_WORK_TYPE) {
            dispatch(addSavedPlace(CONSTANT_ACTIONS.SAVED_PLACE_WORK_TYPE));
        } else {
            dispatch(push('/saved-places/search/add'));
        }
    }
}