import { localStorageSetFcmToken, localStorageGetFcmToken } from '../../../../functions/local-storage';
import { fcmDeviceParser } from "../../../../functions/notification";
import { GraphRequest } from "../../../../../axios";
import { appLoadingEnd } from "../../commonActions/commonChangeState";
import { appFetchFailure } from "./common-failure";
import _ from "lodash";
/**
 * Initialize Fcm and get token first time
 * @param {*} 
 */
export const appInitFcm = () => {
    return (dispatch, getState) => {
        let firebase = window.firebase;
          
        if (firebase.messaging.isSupported()) {
            let messaging = firebase.messaging();
            
            if (firebase && messaging) {
                if (Notification.permission !== 'denied' || Notification.permission === "default") {
                    Notification.requestPermission()
                        .then((permission) => {
                            if (permission === "granted") {
                                messaging.getToken()
                                .then((fcmToken) => {
                                    const token = fcmToken === null ? 'Unknown' : fcmToken;
                                    let lastFcmToken = localStorageGetFcmToken();
                                    if (!lastFcmToken) {
                                        localStorageSetFcmToken(token);
                                        dispatch(registerFcm(token));
                                    }
                                    messaging.onMessage(function (payload) {
                                        if (payload.data.action === "CHAT_NEW_MESSAGE") {
											if (!getState().router.location.pathname.includes("chat")) {
												showNotificationNewMessage(JSON.parse(payload.data));
											}
                                        } else {
                                            showNotification(payload.data);
                                        }
									});
                                })
                                .catch((err) => {
                                    // can't get token and try again
                                    dispatch(appInitFcm());
                                });
                            }
                        });
                }
                
            } else {
                // no Firebase library imported or Firebase library wasn't correctly initialized
                console.log('else Firebase');
            }
        }
    }
};

export function registerFcm(fcmToken) {
    return (dispatch, getState) => {
        fcmDeviceParser(getState, fcmToken, (obj) => {
            const registerDeviceRequestBody = {
                query : `
                    mutation GetDevice($input: ClientDeviceInput!) {
                        registerDevice(device: $input) {
                            succeed,
                            result,
                            details
                        }
                    }`,
                variables: { "input" : obj}
            }
            GraphRequest.all(registerDeviceRequestBody)
                .then(json => {
                    dispatch(appLoadingEnd());
                })
                .catch(error => {
                    dispatch(appFetchFailure(error));
                });
        });
    }
}

/**
 * Notification function
 */
function showNotificationNewMessage(data) {
	if ("serviceWorker" in navigator) {
        let body = typeof data.message !== "undefined" ? JSON.parse(data.message) : null;
		let authorType;
		if (body.authorType && !_.isNull(body.authorType) && body.authorType === "PARTNER") {
			authorType = "Partner";
		} else {
			authorType = "Support";
		}
		navigator.serviceWorker.ready.then(function (registration) {
			registration.showNotification(`${data.title}`, {
				body: `Message from ${authorType}\n` + body.text || body.media,
				icon: "./kuya_notification_icon.png",
			});
		});
	}
}

function showNotification(data) {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
			registration.showNotification(`${data.title}`, {
				body: data.body,
				icon: "./kuya_notification_icon.png",
			});
		});
    }
}