import { push } from 'connected-react-router';
import _ from 'lodash';
import * as DISPATCH_STATE from '../jobsChangeState';
import * as CONSTANT_ACTIONS from '../jobsConstant';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { Request, GraphRequest } from "../../../../../axios";
import { initMessagesFromJobChatHistory } from '../../chatActions';
import { onCloseLivePartnerLocation } from '../../chatActions';
import { convertTime } from "../../../../functions/job-time-convert";
import { changeTimeFormat } from "../../commonActions";
import { fetchJobById, fetchActiveJobRequestBody } from "../jobs-gql";
import {createMarkerPartnerLocation} from "../../mapActions";
/**
 * Fetch job information
 * @param {*} id
 * @param {*} getToken 
 */
// export function fetchJob(id, getToken = null) {
export function fetchJob(id) {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const fetchJob = {
            query: fetchJobById,
            variables: { "requestId": Number(id) }
        }
        return new Promise((resolve, reject) => {
            dispatch(getJob(fetchJob))
                .then(() => resolve())
        })
    };
}
/**
 * check to remove listener from chat
 */
export function channelRemoveListener() {
    return (dispatch, getState) => {
        if (getState().chat.channelRoom && !getState().chat.channel) {
            getState().chat.channelRoom.removeAllListeners();
        }
    }
}
/**
 * 
 * @param {*} job 
 * @param {*} getToken 
 */
// export const fetchJobInformationSuccess = (job, getToken = null) => {
export const fetchJobInformationSuccess = (job) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.fetchJobInformationSuccessDispatch(job));
    }
};
/**
 * update job after job extention
 * @param {*} getToken 
 */
export function fetchUpdatedJob(requestId) {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const getJobRequestBody =  {
            query: fetchJobById,
            variables: { "requestId": requestId ? Number(requestId) : getState().jobs.job.id}
        }
        GraphRequest.all(getJobRequestBody)
            .then(res => {
                if (!_.isNull(res.data.data) && res.data.data.request && res.data.data.request.partnerLifeCycle) {
                    dispatch(fetchJobInformationSuccess(res.data.data.request));
                    dispatch(createMarkerPartnerLocation(res.data.data.request.partnerLifeCycle.location));
                    dispatch(changeTimeFormat(convertTime(res.data.data.request.jobTime.total.value)));
                    dispatch(appLoadingEnd());
                }
            })
            .catch(error => {
                dispatch(appFetchFailure(error));
            });
    }
}
/**
 * When user click on one job
 */
export const navigateOnClickJobInJobsList = (job) => {
    return (dispatch) => {
        dispatch(onCloseLivePartnerLocation());
        if (job.state === CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(push('/jobs/' + job.id));
        } else if (job.state === "Queued" || job.state === "Scheduled" || job.state === "Matching") {
            dispatch(push(`/jobs/${job.id}/open-job`));
            dispatch(DISPATCH_STATE.setJobData(job));
        } else {
            dispatch(push('/jobs/' + job.id + '/chat/'));
        }
    }
}

// navigate from home
export const setJobsLocationPointer = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.myJobsSetSearchLocationPointer(getState().router.location.pathname));
    }
};

//job is completed and extended so we wanna to check each used payment methood
export const checkPaymentMethodUsed = () => {
    return (dispatch, getState) => {
        
        let clonnedPaymentMethod;
        if (
			!_.isNull(getState().jobs.unrated) &&
			!_.isNull(getState().jobs.unrated.payments) &&
			getState().jobs.unrated.payments.length
		) {
			clonnedPaymentMethod = [...getState().jobs.unrated.payments];
		} else if (
			!_.isNull(getState().jobs.job) &&
			!_.isNull(getState().jobs.job.payments) &&
			getState().jobs.job.payments.length
		) {
			clonnedPaymentMethod = [...getState().jobs.job.payments];
		}

        if (clonnedPaymentMethod && clonnedPaymentMethod.length) {
            let cardUsage = 0;
            let mykoinUsage = 0;
            if (clonnedPaymentMethod.length === 1 && clonnedPaymentMethod[0].cardUsage > 0) {
                if (!_.isNull(clonnedPaymentMethod[0].card) && clonnedPaymentMethod[0].card) {
                    dispatch(DISPATCH_STATE.addCardDetail(clonnedPaymentMethod[0].card));
                }
            }
    
            const paymentDetail = clonnedPaymentMethod.reduce((acc, current) => {
                if (acc.cardUsage || current.cardUsage) {
                    let accumulatorCardUsage = acc.cardUsage ? acc.cardUsage : 0;
                    let currentCardUsage = current.cardUsage ? current.cardUsage : 0;
                    cardUsage = accumulatorCardUsage + currentCardUsage;
                    if (!_.isNull(current.card) && current.card) {
                        dispatch(DISPATCH_STATE.addCardDetail(current.card));
                    } else if (!_.isNull(acc.card) && acc.card) {
                        dispatch(DISPATCH_STATE.addCardDetail(acc.card));
                    }
                }
                if (acc.mykoinUsage || acc.cardUsage) {
                    let accumulatorMykoinUsage = acc.mykoinUsage ? acc.mykoinUsage : 0;
                    let currentMykoinUsage = current.mykoinUsage ? current.mykoinUsage : 0;
                    mykoinUsage = accumulatorMykoinUsage + currentMykoinUsage;
                }
                return {"mykoinUsage": mykoinUsage, "cardUsage": cardUsage}
            });
            
            const paymentDetailObject = {
                "cardUsage": paymentDetail["cardUsage"],
                "mykoinUsage": paymentDetail["mykoinUsage"]
            } 
            dispatch(DISPATCH_STATE.detailPaymentMethod(paymentDetailObject));
        }
    }
};

export function fetchActiveJob(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(appLoadingBegin());
            const fetchJob = {
                query: fetchActiveJobRequestBody,
                variables: { "requestId": Number(id) }
            }
            dispatch(getJob(fetchJob)).then((res) => resolve(res));
        })
    };
};

export const getJob = (requestBody) => {
    return (dispatch, getState) => {
        return new Promise((resolveFetch) => {
            dispatch(DISPATCH_STATE.fetchJobInformationBegin());
            GraphRequest.all(requestBody)
                .then(json => {
                    if (json.data.data.request.chatMessagesHistory) {
                        dispatch(fetchJobInformationSuccess(json));
                        dispatch(initMessagesFromJobChatHistory(json));
                        dispatch(appLoadingEnd());
                    } else {
                        dispatch(fetchJobInformationSuccess(json.data.data.request));
                        dispatch(appLoadingEnd());
                        resolveFetch();
                    }
                })
                .catch(error => dispatch(appFetchFailure(error)));
        });
    }
}