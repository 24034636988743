import _ from "lodash";
import * as DISPATCH_STATE from '../mapChangeState';
import * as CONSTANT_ACTIONS from '../mapConstant';
import { appLoadingBegin, appLoadingEnd } from '../../commonActions';
import {createMapObject} from '../../../../functions/create-map-object';
import debounce from '../../../../functions/debounce';
import {getExactAddress} from '../../../../functions/nearby-search';
import {createPlaceObjectFromGoogleGetExactAddress} from '../../../../functions/create-place-object-from-google-suggestions';

/**
 * Initilize google script to end of page whether does not exist and dispatch google map
 * @param {*} refDispatch 
 */
export function initScript (refDispatch, listener = false, url) {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        dispatch(refDispatch);
        dispatch(DISPATCH_STATE.initializeMapScriptEnd());
        setTimeout(() => {
            dispatch(createGoogleMap(listener));
            if(!_.isNull(url)) {
                dispatch(DISPATCH_STATE.mapFinishSetLeftTopFirstTime());
                dispatch(appLoadingEnd());
            } else {
                dispatch(DISPATCH_STATE.mapFinishSetLeftTop());
                dispatch(appLoadingEnd());
            }
        }, 200)
        // dispatch(appLoadingEnd());
    }
}
/**
 * Create Google map instance
 * @param {*} listener check google map display is block or not to add listener to it or not
 */
export function createGoogleMap (listener = false) {
    return (dispatch, getState) => {
        createMapObject(dispatch, getState, 16)
            .then((res) => {
                const { data, centerLocation } = res;

                if (listener) {
                    dispatch(mapGoogleListenerOnMove(data, centerLocation));
                    dispatch(mapGoogleListenerOnDrag(data, centerLocation));
                }
                dispatch(DISPATCH_STATE.mapCreateGoogleMap(data));
            });
    }
}
/**
 * Trigger event on map for fetch google places location 
 * @param {*} data 
 */
const mapGoogleListenerOnMove = (data) => {
    return (dispatch) => {    
        // var myEfficientFn = debounce(function() {
        //     dispatch(fetchSearchPlacesGoogleForPutInSelectedByMap({lat: data.getCenter().lat(), lng: data.getCenter().lng()}));
        // }, 250);
        // myEfficientFn(); // for first time get location
        // data.addListener('center_changed', myEfficientFn);
    }
}
/**
 * Trigger event on map for animate image 
 * @param {*} data 
 */
const mapGoogleListenerOnDrag = (data) => {
    return (dispatch) => { 
        if(!_.isNull(data)) {
            var myEfficientFn = debounce(function() {
                dispatch(DISPATCH_STATE.mapOnDragEvent());
            }, 20);
    
            data.addListener('dragstart', myEfficientFn);
            data.addListener('dragend', myEfficientFn);
        }
    }
}
/**
 * Fetch google search places for when user search via search box in location search component for showing to user
 * @param {*} keyword 
 */
export function fetchSearchPlacesGoogleForPutInSelectedByMap(location) {
    return (dispatch, getState) => {
        getExactAddress(location, (results) => {
            let place = createPlaceObjectFromGoogleGetExactAddress(results,location, CONSTANT_ACTIONS.GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY);
            dispatch(DISPATCH_STATE.mapSearchPlacesFetchForCenterOfMap(place));
        })
    };
}