import * as CONSTANT_ACTIONS from './registerConstant';

export const registerFetchCountryCode = countries => ({
    type: CONSTANT_ACTIONS.REGISTER_FETCH_COUNTRY_CODE,
    payload: countries
});

export const setDefaultCountryCode = defaultCountry => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_COUNTRY_CODE,
    payload: defaultCountry
});

export const registerFetchCities = cities => ({
    type: CONSTANT_ACTIONS.REGISTER_FETCH_CITIES,
    payload: cities
});

export const registerPhoneNumberValidUnSet = () => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_VALUE_UNSET
});

export const registerPhoneNumberRequestFinish = () => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_REQUEST_FINISH
});

export const checkCodeHandlerAction = code => ({
    type: CONSTANT_ACTIONS.REGISTER_CODE_VALUE_SET,
    payload: code
});

export const registerCheckCodeReduceCount = countNumber => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CODE_REDUCE_COUNT,
    payload: countNumber
});

export const registerCheckCodeInterval = interval => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CODE_INTERVAL,
    payload: interval
});

export const registerInitCheckCodePageDispatch = data => ({
    type: CONSTANT_ACTIONS.REGISTER_INIT_CHECK_CODE_PAGE,
    payload: data
});

export const registerBackToWelcomeDispatch = () => ({
    type: CONSTANT_ACTIONS.REGISTER_BACK_TO_WELCOME
});

export const registerChangeCountrySelectedDispatch = (selected) => ({
    type: CONSTANT_ACTIONS.REGISTER_CHANGE_COUNTRY_SELECTED,
    payload: selected
});

export const phoneNumberHandlerAction = number => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_VALUE_SET,
    payload: number
});

export const registerPhoneNumberPostRequest = () => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_POST_REQUEST,
    payload: {}
});

export const registerCheckVerificationCode = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_VERIFICATION_CODE,
    payload: {}
});

export const registerCheckClientExistDispatch = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CLIENT_EXIST
});

export const registerCheckRedirectToRegisterPage = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE
});

export const clearIntervalNumberCheckcodeInterval = () => ({
    type: CONSTANT_ACTIONS.CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL
});