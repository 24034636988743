import {
	FETCH_PAYMENT_METHOD_SUCCESS,
	FETCH_ACCOUNTING_BALANCE_SUCCESS,
	BILLING_DELETE_PAYMENT_METHOD,
	BILLING_SET_ADYEN,
	BILLING_ERROR_PAYMENT,
	BILLING_UN_SET_ERROR_PAYMENT,
	BILLING_PAYMENT_METHOD_NULL,
	TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET,
	SET_NEW_ACCOUNT_BALANCE,
	DELETE_PAYMENT_ERROR_TEXT,
	SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR,
	SET_CLIENT_BALANCES,
} from "../actions/billingActions";
    
const initialState = {
  methods: null,
  balance: null,
  adyen: null,
  errorPayment: false,
  errorPaymentMessage: null,
  isShowTopUpBottomSheet: false,
  errorDeletePaymentText: null,
};
      
export default function billingReducer(state = initialState, action) {
  switch(action.type) {
    case BILLING_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        methods: action.payload[0]['data']
      }
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        methods: action.payload
      }
    case BILLING_PAYMENT_METHOD_NULL:
      return {
        ...state,
        methods: null
      }
    case FETCH_ACCOUNTING_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload
      }
    case BILLING_SET_ADYEN:
      return {
        ...state,
        adyen: action.payload
      }
    case BILLING_ERROR_PAYMENT: 
      return {
        ...state,
        errorPayment: true,
        errorPaymentMessage: action.payload
      }
    case BILLING_UN_SET_ERROR_PAYMENT: 
      return {
        ...state,
        errorPayment: false,
        errorPaymentMessage: null
      }
    case TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET:
      return {
        ...state,
        isShowTopUpBottomSheet:!state.isShowTopUpBottomSheet,
      }
    case SET_NEW_ACCOUNT_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case DELETE_PAYMENT_ERROR_TEXT:
      return {
        ...state,
        errorDeletePaymentText: action.payload
      }
    case SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR:
      return {
        ...state,
        errorDeletePaymentText: null
      }
    case SET_CLIENT_BALANCES:
      return {
        ...state,
        balance: action.payload
      }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}