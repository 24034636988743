import React from 'react';
import { Redirect } from 'react-router-dom';

import {localStorageSetTutorialBrowser} from '../../utils/functions/local-storage';
import { isIOS } from '../../utils/functions/check-browser';

export const NAME_CHROME_BROWSER = 'Chrome';
export const NAME_FIREFOX_BROWSER = 'Firefox';
export const NAME_SAFARI_BROWSER = 'Safari';

export default function AddToHomeScreen(props) {

    if (props.name === NAME_CHROME_BROWSER && !isIOS()) {
        return(<Redirect to='/tutorial/chrome' /> );
    } else if (props.name === NAME_SAFARI_BROWSER) {
        return(<Redirect to='/tutorial/safari' /> );
    } else if (props.name === NAME_FIREFOX_BROWSER  && !isIOS()) {
        return(<Redirect to='/tutorial/firefox' /> );
    } else {
        localStorageSetTutorialBrowser();
        return(<Redirect to='/home' /> );
    }
}