import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import { GraphRequest } from '../../../../../axios';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';

/**
 * Fetch countries
 */
export const fetchCountries = () => {
    const defaultCountry = {
        _id: "5c5c4ad426f970accd48d378",
        code: "+63",
        countryCode: "PH",
        icon: "🇵🇭",
        name: "Philippines",
        unicode: "U+1F1F5U+1F1ED"
    }
    return dispatch => {
        dispatch(appLoadingBegin());
        const geoBoundaryCountryCodeRequestBody = {
            query: `query CountryCodes {
                countryCodes {
                    code
                    icon
                }
            }`
        }
        GraphRequest.all(geoBoundaryCountryCodeRequestBody) 
            .then(json => {
                if (!_.isNull(json.data.data) &&  json.data.data.countryCodes) {
                    dispatch(DISPATCH_STATE.registerFetchCountryCode(json.data.data.countryCodes));
                    dispatch(appLoadingEnd());
                } else if(_.isNull(json.data.data) && json.data.errors) {
                    dispatch(DISPATCH_STATE.setDefaultCountryCode(defaultCountry));
                    dispatch(appFetchFailure(json.data.errors[0].message));
                }
            })
            .catch(error => {
                dispatch(DISPATCH_STATE.setDefaultCountryCode(defaultCountry));
                dispatch(appFetchFailure(error));
            });
    };
}
/**
 * Fetch cities
 */
export const fetchCities = () => {
    return dispatch => {
        const geoBoundaryCityRequestBody = {
			query: `query ClientCoveredCities {
                coveredCities {
                    id
                    name
                    lat
                    lng
                }
            }`,
		};
        dispatch(appLoadingBegin());
        GraphRequest.all(geoBoundaryCityRequestBody)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.coveredCities) {
                    dispatch(DISPATCH_STATE.registerFetchCities(json.data.data.coveredCities));
                    dispatch(appLoadingEnd());
                } else if (_.isNull(json.data.data) && json.data.errors) {
                    dispatch(appFetchFailure(json.data.errors[0].message))
                }
            })
            .catch(error => dispatch(appFetchFailure(error)));
    };
}
/**
 * Change country in register page
 * @param {*} value 
 */
export const registerChangeCountrySelected = value => {
    return (dispatch, getState) => {
        let selected = getState().register.countries[ value ];
        dispatch(DISPATCH_STATE.registerChangeCountrySelectedDispatch(selected));
    }
};