import React from 'react';

import MascotImg from '../../assests/images/home/waitlist/mascot.png';

export default function Mascot(props) {
    return (
        <div className="Waitlist-mascot">
            <img src={MascotImg} alt=""  className="img-responsive"/>
        </div>
    );
}