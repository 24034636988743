import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../chatChangeState';
import {
    appLoadingBegin,
    appLoadingEnd,
    appFetchFailure,
    appGoHome
} from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import * as LOG_EVENT from '../../../../analytics';
import { cancelJobRequestBody } from "../../jobsActions/jobs-gql";
/**
 * Send request for canceling job with reason in chat area
 */
export const onCancelJobRequest = (reasonObj) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.requestJobCancelBegin());
        dispatch(appLoadingBegin());
        const cancelJob = {
            query: cancelJobRequestBody,
            variables: {
                "jobId": getState().jobs.job.id,
                "cancelReason": {
                    "reason": reasonObj.reason,
                    "explanation": reasonObj.explanation,
                }
            }
        }
        GraphRequest.all(cancelJob)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.cancelJob) {
                    dispatch(DISPATCH_STATE.requestJobCancelSuccess());
                    dispatch(DISPATCH_STATE.onNeverMind());
                    dispatch(DISPATCH_STATE.emptyNewMessages());
                    dispatch(appLoadingEnd());
                    dispatch(appGoHome());
                    LOG_EVENT.logEvent(LOG_EVENT.REQUEST_CANCELLED_AFTER_MATCHED_CWA, {
                        serviceId: getState().jobs.job.product.id,
                        commercialName: getState().jobs.job.product.commercialName,
                        requestId: getState().jobs.job.id
                    } );
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                }
            })
    }
}