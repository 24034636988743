import React from 'react';
import { Redirect } from 'react-router-dom';

export class WelcomeRequired extends React.Component {

    render() {
        if(this.props.auth) {
            return(<Redirect to='/home' /> );
        } else {
            return (this.props.orRender);
        } 
    }
}
export default WelcomeRequired;