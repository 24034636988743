import * as CONSTANT_ACTIONS from './jobsConstant';

export const fetchJobsBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_JOBS_BEGIN
});

export const fetchJobsSuccess = jobs => ({
    type: CONSTANT_ACTIONS.FETCH_JOBS_SUCCESS,
    payload: jobs
});

export const fetchJobsSuccessScroll = jobs => ({
	type: CONSTANT_ACTIONS.FETCH_JOBS_SUCCESS_SCROLL,
	payload: jobs
});

export const fetchJobsUnratedSuccess = job => ({
    type: CONSTANT_ACTIONS.FETCH_JOB_UNRATED_SUCCESS,
    payload: job
});

export const fetchJobUnratedComplimentSuccess = compliment => ({
    type: CONSTANT_ACTIONS.FETCH_JOB_UNRATED_RATE_COMPLIMENT,
    payload: compliment
});

export const fetchJobChatTokenBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_JOB_CHAT_HISTORY_TOKEN_BEGIN
});

export const jobMatchingWillUnMount = () => ({
    type: CONSTANT_ACTIONS.JOB_MATCHING_WILL_UN_MOUNT
});
/**
 * When user come out from list of jobs
 */
export const willUnmountJobs = () => ({
    type: CONSTANT_ACTIONS.WILL_UNMOUNT_JOBS
})
/**
 * When user come out from job rating
 */
export const jobsRatingWillUnMount = () => ({
    type: CONSTANT_ACTIONS.JOB_RATING_UNRATED_WILL_UNMOUNT
})

export const selectJobsFromJobs = (job) => ({
    type: CONSTANT_ACTIONS.SELECT_JOB_FROM_JOBS,
    payload: job
})

export const fetchJobInformationBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_JOB_INFORMATION_BEGIN
});

export const jobRatedPartnerComplited = () => ({
    type: CONSTANT_ACTIONS.JOB_RATE_PARTNER_COMPLITED
});

export const unsetMatchingRequest = () => ({
    type: CONSTANT_ACTIONS.JOB_UNSET_MATCHING_REQUEST
})
/**
 * setting Matching job to true
 */
export const setJobMatchingState = () => ({
    type: CONSTANT_ACTIONS.JOB_MATCHING_STATE_SET
})

/**
 * jobs active count 
 * @param {*} data 
 */
export const jobsActiveCount = (data) => ({
    type: CONSTANT_ACTIONS.JOB_ACTIVE_COUNT,
    payload: data
})

/**
 * jobs active
 * @param {*} data
 */
export const jobsActive = (data) => ({
	type: CONSTANT_ACTIONS.JOB_ACTIVE,
	payload: data
})
/**
 * jobs active count increment one item
 * @param {*} data 
 */
export const jobsActiveCountIncrementOne = () => ({
    type: CONSTANT_ACTIONS.JOB_ACTIVE_COUNT,
    payload: 1
})
/**
 * jobs active count decrement one item
 * @param {*} data 
 */
export const jobsActiveCountDecrementOne = (count) => ({
    type: CONSTANT_ACTIONS.JOB_ACTIVE_COUNT,
    payload: count === 1 ? 0 : (count - 1)
})

// stop sound of matched
export const clearTrackMatched = () => ({
    type: CONSTANT_ACTIONS.STOP_TRACK_MATCHED
})

export const jobChatHistoryWillUnMountDispatch = () => ({
    type: CONSTANT_ACTIONS.JOB_CHAT_HISTOR_WILL_UNMOUNT
})

export const jobChangeStateIntervalChat = (data) => ({
    type: CONSTANT_ACTIONS.JOB_CHANGE_STATE_INTERVAL_CHAT,
    payload: data
})

export const initJobByMatchStatus = (job) => ({
    type: CONSTANT_ACTIONS.INIT_JOB_BY_MATCH_STATUS,
    payload: job
});
export const updateJobByMatchStatus = (job) => ({
    type: CONSTANT_ACTIONS.UPDATE_JOB_BY_MATCH_STATUS,
    payload: job
})

export const fetchJobInformationSuccessDispatch = (job) => ({
    type: CONSTANT_ACTIONS.FETCH_JOB_INFORMATION_SUCCESS,
    payload: job
})

export const jobInformationNavigateToEitherReceiptOrChat = () => ({
    type: CONSTANT_ACTIONS.JOB_INFORMATION_NAVIGATE_TO_EITHER_RECEIPT_OR_CHAT,
})

export const initIntervalJobStateCheck = (interval) => ({
    type: CONSTANT_ACTIONS.INIT_INTERVAL_JOB_STATE_CHECK,
    payload: interval
})

export const initIntervalMatchingJobRequest = (interval) => ({
    type: CONSTANT_ACTIONS.INIT_INTERVAL_MATCHING_JOB_REQUEST,
    payload: interval
})

export const fetchPartnerOfMatchedJobBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_PARTNER_OF_MATCHED_JOB_BEGIN
})

export const fetchPartnerOfMatchedJobSuccess = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_PARTNER_OF_MATCHED_JOB_SUCCESS,
    payload: data
})

export const cancelSetIntervalJob = () => ({
    type: CONSTANT_ACTIONS.CANCEL_SETINTERVAL_JOB
})

export const playTrackMatchedDispatch = (sound) => ({
    type: CONSTANT_ACTIONS.PLAY_TRACK_MATCHED,
    payload: sound
});

export const clearIntervalCancelRequestInterval = () => ({
    type: CONSTANT_ACTIONS.CLEAR_INTERVAL_CANCEL_REQUEST_INTERVAL
});
export const cancelRequestReduceCount = countNumber => ({
    type: CONSTANT_ACTIONS.CANCEL_REQUEST_REDUCE_COUNT,
    payload: countNumber
});
export const cancelRequestInterval = interval => ({
    type: CONSTANT_ACTIONS.CANCEL_REQUEST_INTERVAL,
    payload: interval
});
export const initCancelRequestCountDown = () => ({
    type: CONSTANT_ACTIONS.INIT_CANCEL_REQUEST_COUNT_DOWN,
});

//job is completed & user rate thumbs up 
export const toggleRatePopup = (data) => ({
    type: CONSTANT_ACTIONS.TOGGLE_RATE_POPUP,
    payload: data
});

export const appRated = () => ({
    type: CONSTANT_ACTIONS.APP_RATED,
});

export const setMyJobsLocationPointer = (pathname) => ({
	type: CONSTANT_ACTIONS.SET_MY_JOB_LOCATION_POINTER,
	payload: pathname,
});

export const myJobsSetSearchLocationPointer = (pathname) => ({
    type: CONSTANT_ACTIONS.SET_MY_JOBS_SET_SEARCH_LOCATION_POINTER,
    payload: pathname,
});

export const detailPaymentMethod =  (paymentDetail) => ({
    type: CONSTANT_ACTIONS.DETAIL_PAYMENT_METHOD,
    payload: paymentDetail
});

export const setDefaultPaymentDetails = () => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_PAYMENT_DETAILS
});

export const addCardDetail = (card) => ({
    type: CONSTANT_ACTIONS.ADD_CARD_DETAIL,
    payload: card
});

export const setDefaultCardDetail = () => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_CARD_DETAIL
});

export const setDefaultJobObject = () => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_JOB_OBJECT
});

export const fetchOpenJobsBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_OPEN_JOBS_BEGIN
});

export const fetchOpenJobsSuccessfully = (data) => ({
	type: CONSTANT_ACTIONS.FETCH_OPEN_JOBS_SUCCESSFULLY,
	payload: data,
});

export const setJobData = (job) => ({
    type: CONSTANT_ACTIONS.SET_JOB_DATA,
    payload: job
});

export const cancelOpenJobBegin = () => ({
    type: CONSTANT_ACTIONS.CANCEL_OPEN_JOB_BEGIN
});

export const updateJobByOpenStatus = (job) => ({
	type: CONSTANT_ACTIONS.UPDATE_JOB_BY_OPEN_STATUS,
	payload: job,
});