import _ from "lodash";
import { toast } from "react-toastify";	

import * as DISPATCH_STATE from "./myJobsChangeState";
import * as Constant from "./myJobsConstant";
import { fetchJobs, fetchOpenJobsQuery } from "./myjobs-gql";
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from "../commonActions";
import { navigateOnClickJobInJobsList } from "../jobsActions";
import { fetchJobById } from "../jobsActions/jobs-gql";
import { GraphRequest } from "../../../../axios";

export const fetchJobsListByRequestType = (jobType) => {
    return (dispatch, getState) => {
        switch(jobType) {
            case Constant.Open:
                return dispatch(fetchOpenJobs());
            default:
                const body = {
					query: fetchJobs,
					variables: {
						offset: 0,
						limit: 10,
						requestType: jobType === Constant.Upcoming ? jobType.toUpperCase() : jobType,
					},
				};
                return dispatch(fetchJobsByRequestType(body, jobType));
        }
    };
}

export function fetchOpenJobs() {
	return (dispatch) => {
		dispatch(appLoadingBegin());
		dispatch(DISPATCH_STATE.fetchOpenJobsBegin());
		const fetchOpenedJobsRequestBody = {
			query: fetchOpenJobsQuery,
		};

		GraphRequest.all(fetchOpenedJobsRequestBody).then((res) => {
			if (!_.isNull(res.data.data) && res.data.data) {
                const {queued, matching, scheduled} = res.data.data;
                if (queued.totalItems === 0 && matching.totalItems === 0 && scheduled.totalItems === 0) {
                    dispatch(DISPATCH_STATE.noOpenJobs());
				} else {
                    dispatch(DISPATCH_STATE.fetchOpenJobsSuccessfully(res.data.data));
                }
				dispatch(appLoadingEnd());
			}
		});
	};
}

export function fetchJobsByRequestType(body, jobType) {
	return (dispatch) => {
        dispatch(appLoadingBegin());
        dispatch(DISPATCH_STATE.fetchJobsByRequestTypeBegin());
		
        GraphRequest.all(body).then((res) => {
			if (!_.isNull(res.data.data) && res.data.data) {
                dispatch(appLoadingEnd());
                switch (jobType) {
					case Constant.Upcoming:
						return dispatch(DISPATCH_STATE.fetchUpcomingJobs(res.data.data.requests));
					case Constant.All:
						return dispatch(DISPATCH_STATE.fetchAllJobs(res.data.data.requests));
					case Constant.Completed:
						return dispatch(DISPATCH_STATE.fetchCompletedJobs(res.data.data.requests));
					case Constant.Active:
                    	return dispatch(DISPATCH_STATE.fetchActiveJobs(res.data.data.requests));
                }
			}
		});

	};
}

export const fetchJobsByOffset = (selectedTab) => {
	return (dispatch, getState) => {
		const selectedTabJobList = `${selectedTab.toLowerCase()}Jobs`;
		if (!_.isNull(getState().myJobs[selectedTabJobList])) {
			const totalOfJobsExistInArray = getState().myJobs[selectedTabJobList].items.length;
			if (getState().myJobs[selectedTabJobList].totalItems > totalOfJobsExistInArray) {
				dispatch(appLoadingBegin());
				dispatch(DISPATCH_STATE.fetchJobsByOffsetBegin());
				const getJobsHistoryRequestBody = {
					query: fetchJobs,
					variables: {
						offset: totalOfJobsExistInArray,
						limit: 10,
						requestType: selectedTab === Constant.Upcoming ? selectedTab.toUpperCase() : selectedTab,
					},
				};
				GraphRequest.all(getJobsHistoryRequestBody)
					.then((res) => {
						const mergedJobs = [...getState().myJobs[selectedTabJobList].items, ...res.data.data.requests.items];
						const obj = {
							items: mergedJobs,
							totalItems: res.data.data.requests.totalItems,
						};
						if (!_.isNull(res.data.data) && res.data.data) {
							dispatch(appLoadingEnd());
							switch (selectedTab) {
								case Constant.Upcoming:
									return dispatch(DISPATCH_STATE.fetchUpcomingJobs(obj));
								case Constant.All:
									return dispatch(DISPATCH_STATE.fetchAllJobs(obj));
								case Constant.Completed:
									return dispatch(DISPATCH_STATE.fetchCompletedJobs(obj));
								case Constant.Active:
									return dispatch(DISPATCH_STATE.fetchActiveJobs(obj));
							}
						}
	
					})
			}
		} 
	}
}

export const checkJobState = (jobId) => {
	return(dispatch) => {
		const requestBody = {
			query: fetchJobById,
			variables: {
				"requestId": jobId
			}
		}
		dispatch(appLoadingBegin());
		GraphRequest.all(requestBody)
			.then((res) => {
				if (res.data && res.data.data.request) {
					dispatch(navigateOnClickJobInJobsList(res.data.data.request));
					dispatch(appLoadingEnd());
				} else {
					dispatch(appFetchFailure(res.data.errors[0].message));
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
					dispatch(appLoadingEnd());
				}
			})
	}
}