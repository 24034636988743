import _ from 'lodash';
/**
 * Get exact address by lat long
 * @param {*} location 
 * @param {*} callback 
 */
export function getExactAddress(location, callback) {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'location': location }, callback);
    return geocoder;
}
/**
 *
 * @param {*} getState
 * @param {*} radius
 * @param {*} keyword
 * @param {*} callback
 */
export function autocompleteSearch(getState, keyword, radius, callback) {
    let pyrmont = new window.google.maps.LatLng(getState().map.location.lat, getState().map.location.lng);
    let service = new window.google.maps.places.AutocompleteService();
    let sessionToken = getSessionToken();

    var request = {
        location: pyrmont,
        radius: radius,
        sessionToken: sessionToken,
        input: keyword
    };

    service.getPlacePredictions(request, callback);
    return service;
}

var globalSessionToken;

function getSessionToken() {
    if (!globalSessionToken) {
        changeSessionToken();
    }
    return globalSessionToken;
}

function changeSessionToken() {
    globalSessionToken = new window.google.maps.places.AutocompleteSessionToken();
}

/**
 *
 * @param {*} getState
 * @param {*} placeId
 * @param {*} callback
 */
export function getDetailPlaces(getState, pleceId, callback) {
    let service = new window.google.maps.places.PlacesService(getState().map.googleMap);

    var request = {
        placeId: pleceId,
        fields: [ 'formatted_address', 'geometry', 'name', 'vicinity', 'place_id' ],
        sessionToken: getSessionToken()
    };

    service.getDetails(request, callback);
    changeSessionToken();
    return service;
}

/**
 * 
 * @param {*} results 
 */
export function sliceNearbyResult(results) {
    if (_.isArray(results)) {
        return results.slice(0, 5);
    } else {
        return [];
    }
}

export function getLocationByState(getState) {
    if (!_.isNull(getState().map.location.lat) || !_.isNull(getState().map.location.lng)) {
        return {
            lat: getState().map.location.lat,
            lng: getState().map.location.lng
        }
    } else {
        return {
            lat: getState().map.location.defaultLat,
            lng: getState().map.location.defaultLng
        }
    }
}