import { 
  SEARCH_SET_CURRENT_REFERENCE,
  SEARCH_SET_CURRENT_REFERENCE_NAME
} from '../actions/searchActions';
  
    const initialState = {
        currentRef: {},
        currentRefName: ''
    };
    
    export default function searchReducer(state = initialState, action) {
      switch(action.type) {
        case SEARCH_SET_CURRENT_REFERENCE:
          return {
            ...state,
            currentRef: action.payload
          }
        case SEARCH_SET_CURRENT_REFERENCE_NAME:
          return {
            ...state,
            currentRefName: action.payload
          }
        default:
          // ALWAYS have a default case in a reducer
          return state;
      }
    }