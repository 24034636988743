import _ from 'lodash';
import { toast } from "react-toastify";
import * as DISPATCH_STATE from '../jobsChangeState';
import { appLoadingBegin, appLoadingEnd, appFetchFailure, appGoHome } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import * as LOG_EVENT from '../../../../analytics';
import { localStorageGetReminderRateApp, localStorageGetAppIsRated } from '../../../../functions/local-storage';
import { momentDiffFromNowAsDays } from '../../../../functions/moment-now-timestamp';
import { fetchComplimentsRequestBody, jobRateRequestBody } from "../jobs-gql";
/**
 * Fetch compliment from server for rate job after is completed
 */
export const fetchJobUnratedCompliment = () => {
    return (dispatch, getState) => {
        if (_.isNull(getState().jobs.unratedCompliment)) {
            dispatch(appLoadingBegin());
            const complimentsRequestBody = {
                query: fetchComplimentsRequestBody,
                variables: {
                    "requestId": getState().jobs.unrated.id
                }
            }
            GraphRequest.all(complimentsRequestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.request) { 
                        dispatch(DISPATCH_STATE.fetchJobUnratedComplimentSuccess(res.data.data.request.product.compliments));
                        dispatch(appLoadingEnd());
                    } else {
                        dispatch(appFetchFailure(res.data.errors[0].message));
                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                        dispatch(appLoadingEnd());
                    }
            }).catch((error) => dispatch(appFetchFailure(error)))
        }
    }
}
/**
 * Post request for rating partner in completed job
 */
export const postRequestJobUnratedCompliment = (obj) => {
    return (dispatch, getState) => {
        const logObj = {
            jobDetails: {
                serviceId: getState().jobs.unrated.product.id,
                commercialName: getState().jobs.unrated.product.commercialName,
            },
            requestId: getState().jobs.unrated.id,
            currency: 'PHP',
            value: 10.00,
            // ...obj
        }
        if (obj.rate === 5) {
            logObj.isPositive = true;
            logObj.status = "positive";
            LOG_EVENT.logEvent(LOG_EVENT.JOB_RATE_CWA, logObj);
        } else {
            logObj.isPositive = false;
            logObj.status = "negative";
            LOG_EVENT.logEvent(LOG_EVENT.JOB_RATE_CWA, logObj);
        }
        if (!_.isEmpty(obj.comment)) {
            LOG_EVENT.logEvent(LOG_EVENT.FEEDBACK_GIVEN_CWA, logObj);
        }
        if (obj.compliments.length > 0) {
            LOG_EVENT.logEvent(LOG_EVENT.COMPLIMENT_GIVEN_CWA, logObj);
        }
        dispatch(appLoadingBegin());
        const postComplimentRequestBody = {
            query: jobRateRequestBody,
            variables: {
                "rating": obj,
                "jobId": getState().jobs.unrated.id 
            }
        }
        GraphRequest.all(postComplimentRequestBody)
        .then((res) => {
            if (!_.isNull(res.data.data) && res.data.data.jobRatePartner) {
                dispatch(DISPATCH_STATE.jobRatedPartnerComplited());
                //app is not rated
                if (obj.rate === 5 && !localStorageGetAppIsRated()) {
                    if (localStorageGetReminderRateApp()) {
                        // show popup for rate app after 14 days
                        const firstTimeIgnoreAppRate = localStorageGetReminderRateApp();
                        if (momentDiffFromNowAsDays(firstTimeIgnoreAppRate) >= 14) {
                            dispatch(DISPATCH_STATE.toggleRatePopup(true));
                        }
                    } else {
                        dispatch(DISPATCH_STATE.toggleRatePopup(true));
                    }
                }
                toast(`Feedback sent`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    limit: 1,
                    className: "toast-rejected-payment",
                    bodyClassName: "toastify-inner",
                    hideProgressBar: true,
                    closeOnClick: false,
                });
                dispatch(appLoadingEnd());
                dispatch(appGoHome());
            } else {
                dispatch(appFetchFailure(res.data.errors[0].message));
                toast(`${res.data.errors[0].message}`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    limit: 1,
                    className: "toast-rejected-payment",
                    bodyClassName: "toastify-inner",
                    hideProgressBar: true,
                    closeOnClick: false,
                });
                dispatch(DISPATCH_STATE.jobRatedPartnerComplited());
            }
        })
    }
}