export const fetchJobs = `
query Requests($offset: Int, $limit: Int, $requestType: JobTypeEnum) {
  requests(offset:$offset, limit:$limit, requestType: $requestType) {
    totalItems 
    items {
        id
        dateTime
        dueDate
        jobCost {
            serviceFee
            value
            currency
            displayValue
            discount
        }
        product {
            commercialName
            icon
        }
        jobLocations {
            name
            address
        }
        jobTime {
            plate {
                value
                unit
                displayUnit
                displayValue
            }
            cube {
                value
                unit
                displayUnit
                displayValue
            }
            total {
                value
                unit
                displayUnit
                displayValue
            }
            cubeNumbers
        }
        state
    }
  }
}`;

export const fetchOpenJobsQuery = `query OpenJobs {
  queued: requests(requestType: OPEN_QUEUED, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
  
  matching:requests(requestType: OPEN_MATCHING, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
  
  scheduled: requests(requestType: OPEN_SCHEDULED, limit: 50) {
    totalItems
    items {
      ...item
    }
  }
}

fragment item on ClientRequestType {
  id
      bookingId
      dateTime
      dueDate
      jobCost {
       value
       displayValue
       serviceFee
       discountedServiceFee
       extendFee
      }
      voucher {
        code
        amountOff
        percentOff
        totalDiscount
        type
      }
      partner {
        id
        givenName
        lastName
        gender
        coPartner {
          name
          displayValue
        }
        photo
        mobileNumber {
          countryCode {
            code
          }
          number
          fullNumber
        }
      }
      product {
        id
        icon
        commercialName
        mode
      }
      state
      jobLocations {
        name
        address
        lat
        lng
      }
      jobTime {
        total {
          unit
          value
          displayUnit
          displayValue
        }
        cubeNumbers
      }
}`;
