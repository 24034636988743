export const MAP_INITIALIZE_SHOW = 'MAP_INITIALIZE_SHOW'; // when should show google map
export const MAP_INITIALIZE_HIDE = 'MAP_INITIALIZE_HIDE'; // when should hide google map
export const MAP_INITIALIZE_REF = 'MAP_INITIALIZE_REF'; // when create ref to map div
export const MAP_INITIALIZE_SCRIPT = 'MAP_INITIALIZE_SCRIPT'; // map initilizing is starting
export const MAP_AFTER_INITIALIZE_SCRIPT_LOADED = 'MAP_AFTER_INITIALIZE_SCRIPT_LOADED'; // when we listen to google script loading
export const MAP_CREATE_GOOGLE_MAP = 'MAP_CREATE_GOOGLE_MAP'; // when we initilize google map
export const MAP_CREATE_MARKER = 'MAP_CREATE_MARKER'; // when we create new marker
export const MAP_CREATE_MARKER_PARTNER = 'MAP_CREATE_MARKER_PARTNER'; // when we create new marker partner
export const MAP_CREATE_MARKER_SET_POSITION = 'MAP_CREATE_MARKER_SET_POSITION'; // when partner location change
export const MAP_CREATE_MARKER_SET_LOCATION_PLACES = 'MAP_CREATE_MARKER_SET_LOCATION_PLACES'; // when put results of google places api in state
export const MAP_REMOVE_MARKER_FOR_SELECTING_LOCATION = 'MAP_REMOVE_MARKER_FOR_SELECTING_LOCATION'; // When user click on unconfirm button in map location for selecting location again
export const MAP_INITIALIZE_SCRIPT_END = 'MAP_INITIALIZE_SCRIPT_END'; // When script google is added to end of page and google map is inited
export const MAP_INITIALIZE_FINISH_ICON = 'MAP_INITIALIZE_FINISH_ICON'; // when we found center of screen we put finish icon in center of screen
export const MAP_REMOVE_GOOGLE_MARKER = 'MAP_REMOVE_GOOGLE_MARKER'; // when user leaves google map page we remove google marker
export const MAP_FINISH_SET_LEFT_TOP = 'MAP_FINISH_SET_LEFT_TOP'; // when user goes to map page we calculate screen offset for put fake marker in center
export const MAP_GET_CURRENT_LOCATION = 'MAP_GET_CURRENT_LOCATION'; // when user get current location by click on get current location on map
export const MAP_GET_CURRENT_LOCATION_SET = 'MAP_GET_CURRENT_LOCATION_SET'; // When user select his/her current location by using map
export const MAP_SET_CURRENT_LOCATION_LOCALSTORAGE = 'MAP_SET_CURRENT_LOCATION_LOCALSTORAGE'; // when user select source location we put it in localstorage for useing in future
export const MAP_GET_CURRENT_LOCATION_LOCALSTORAGE = 'MAP_GET_CURRENT_LOCATION_LOCALSTORAGE'; // when application being refresh this action run for get source loaction from localstorage whether exists
export const MAP_CREATE_CURRENT_MARKER = 'MAP_CREATE_CURRENT_MARKER'; // When use confirm specific location for putting marker
export const MAP_SEARCH_PLACES_FETCH = 'MAP_SEARCH_PLACES_FETCH'; // Fetch google places results by specific location for get nearby
export const MAP_SEARCH_PLACES_FETCH_FOR_CENTER_OF_MAP = 'MAP_SEARCH_PLACES_FETCH_FOR_CENTER_OF_MAP'; // Fetch google places results by specific location for get nearby
export const MAP_SEARCH_PLACES_FETCH_BY_MARKER_LOCATION = 'MAP_SEARCH_PLACES_FETCH_BY_MARKER_LOCATION'; // fetch nearby place from google places api when marker location is determined
export const MAP_INPUT_SELECT_ADDRESS = 'MAP_INPUT_SELECT_ADDRESS'; // when user toch one item from result of google places results
export const MAP_INPUT_SELECT_ADDRESS_FIRST_DESTINATION = 'MAP_INPUT_SELECT_ADDRESS_FIRST_DESTINATION'; // when user toch one item from result of google places results for choose first destination
export const MAP_SET_PLACE_GOOGLE = 'MAP_SET_PLACE_GOOGLE';
export const MAP_SET_PLACE_GOOGLE_SOURCE = 'MAP_SET_PLACE_GOOGLE_SOURCE';
export const SMOOTH_SCROLL_TO_TOP = 'SMOOTH_SCROLL_TO_TOP'; // when user scroll smooth to top
export const MAP_CLEAR_GOOGLE_MAP_ARRAYS_OF_DESTINATION = 'MAP_CLEAR_GOOGLE_MAP_ARRAYS_OF_DESTINATION'; // when user scroll smooth to top
export const MAP_ON_DRAG_EVENT = 'MAP_ON_DRAG_EVENT'; // when map start to drag
export const MAP_INTERVAL_MARKER_MATCHING = 'MAP_INTERVAL_MARKER_MATCHING';
export const MAP_CLEAR_INTERVAL_MARKER_MATCHING = 'MAP_CLEAR_INTERVAL_MARKER_MATCHING';

export const GOOGLE_PLACES_NEARBY_SEARCH_ACCEPT_INDEX_OF_ARRAY = 1; // google places nearby search accept index of array
export const GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY = 0; // google places get address accept index of array
export const GOOGLE_PLACES_NEARBY_SEARCH_RADIUS = '500'; // google places nearby search radius
export const MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT = 0; // when user want to change current location for job request
export const MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION = 1; // when user want to change one of destinations location for job request
export const MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW = -1; // when user want to add new destination
export const MAP_NUMBER_SLICE_RESULTS_OF_GOOGLE_SEARCH = 5; // number of slice from results of google
export const DEBOUNCE_TIME_SEARCHING_GOOGLE = 800; // debounce time for google suggestion api call
export const MIN_KEYWORD_LENGTH_FOR_GOOGLE_SUGGESTION = 2; // min keyword length for function searching google start to call
export const ADD_FIRST_STOP = "ADD_FIRST_STOP";
export const UPDATE_GOOGLE_PLACES = "UPDATE_GOOGLE_PLACES";
export const DRAG_GOOGLE_PLACE_ITEM_BEGIN = "DRAG_GOOGLE_PLACE_ITEM_BEGIN";
export const DRAG_GOOGLE_PLACE_ITEM_END = "DRAG_GOOGLE_PLACE_ITEM_END";
export const CHANGE_CURRENT_LOCATION = "CHANGE_CURRENT_LOCATION";
export const SET_PICKUP_LOCATION_TO_MAP = "SET_PICKUP_LOCATION_TO_MAP";

export const INIT_USER_LOCATION_DETAIL = "INIT_USER_LOCATION_DETAIL";
export const TOGGLE_INFO_BOTTOM_SHEET = "TOGGLE_INFO_BOTTOM_SHEET";
export const MAP_FINISH_SET_LEFT_TOP_FIRST_TIME = "MAP_FINISH_SET_LEFT_TOP_FIRST_TIME";