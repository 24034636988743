import { 
    SELECT_SAVED_PLACES_ADDRESS,
    SAVED_PLACES_FETCH_ALL,
    SAVED_PLACES_SET_TYPE,
    SAVED_PLACES_CLEAR_TYPE_AND_PLACE,
    SAVED_PLACES_FIND_HOME_WORK,
    SAVED_PLACES_SELECT_MAP,
    SAVED_PLACES_UNSELECT_MAP,
    SAVED_PLACES_WANTED_TO_DELETE_PLACE,
    SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE,
    SAVED_PLACES_SET_SEARCH_LOCATION,
    SAVED_PLACES_UNSET_SEARCH_LOCATION,
    SAVED_PLACES_CLICK_SELECTED_PLACE,
    SAVED_PLACES_CLEAR_SELECTED_PLACE,
    UNDO_REMOVE_BOOKMARK_SAVED_PLACE,
    EMPTY_TEMP_STORED_BOOKMARKED
  } from '../actions/savedPlacesActions';
    
      const initialState = {
        savedPlace: null, // put address for saving for in saved places
        all: null, // list all saved places
        savedHome: null, // place of home
        savedWork: null, // place of work
        savedType: null,
        savedMap: false,
        savedPlaceWantToDelete: null, // put delete place object for get permission from user
        searchPointer: null,
        selected: null, // when click on one of place in saved places page put place in selected
        tempStoredBookmarked: null,
      };
      
      export default function savedPlacesReducer(state = initialState, action) {
        switch(action.type) {
            case SELECT_SAVED_PLACES_ADDRESS:
                return {
                    ...state,
                    savedPlace: action.payload
                };
            case SAVED_PLACES_FETCH_ALL:
                return {
                    ...state,
                    all: action.payload
                };
            case SAVED_PLACES_SET_TYPE:
                return {
                    ...state,
                    savedType: action.payload
                };
            case SAVED_PLACES_CLEAR_TYPE_AND_PLACE:
                return {
                    ...state,
                    savedPlace: null,
                    savedType: null,
                };
            case SAVED_PLACES_FIND_HOME_WORK:
                return {
                    ...state,
                    savedHome: action.payload.home,
                    savedWork: action.payload.work,
                };
            case SAVED_PLACES_SELECT_MAP:
                return {
                    ...state,
                    savedMap: true,
                };
            case SAVED_PLACES_UNSELECT_MAP:
                return {
                    ...state,
                    savedMap: false,
                };
            case SAVED_PLACES_WANTED_TO_DELETE_PLACE:
                return {
                    ...state,
                    savedPlaceWantToDelete: action.payload,
                };
            case SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE:
                return {
                    ...state,
                    savedPlaceWantToDelete: null,
                };
            case SAVED_PLACES_SET_SEARCH_LOCATION:
                return {
                    ...state,
                    searchPointer: action.payload,
                };
            case SAVED_PLACES_UNSET_SEARCH_LOCATION:
                return {
                    ...state,
                    searchPointer: null,
                };
            case SAVED_PLACES_CLICK_SELECTED_PLACE:
                return {
                    ...state,
                    selected: action.payload,
                };
            case SAVED_PLACES_CLEAR_SELECTED_PLACE:
                return {
                    ...state,
                    selected: null,
                };
            case UNDO_REMOVE_BOOKMARK_SAVED_PLACE:
                return {
                    ...state,
                    tempStoredBookmarked: action.payload
                };
            case EMPTY_TEMP_STORED_BOOKMARKED:
                return {
                    ...state,
                    tempStoredBookmarked: null
                }
          default:
            return state;
        }
      }