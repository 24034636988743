import _ from 'lodash';
import { toast } from "react-toastify";
import {push } from "connected-react-router";

import * as DISPATCH_STATE from '../bookingChangeState';
import { Payment, GraphRequest } from '../../../../../axios';
import { appLoadingBegin, appLoadingEnd, appFetchFailure, appGoHome } from '../../commonActions';
import { initJobByMatchingStatus, setJobMatchingState, unsetMatchingRequest, updateJobByMatchingStatus } from '../../jobsActions';
import { initCancelRequestCountDown } from '../../jobsActions/jobsChangeState';
import { createReqeustObj } from '../../../../functions/create-booking-request-object';
import * as LOG_EVENT from '../../../../analytics';
import { createCurrentLocationMarkerForMatching } from '../../../actions/mapActions/actions/map-marker';
import { clientHasNoPaymentMethod, getPrimaryPayment } from "../../commonActions";
import { fetchStateOfJob } from "../../jobsActions/actions/jobs-matching";
import { checkRejectedPayment } from "../bookingActions";
import { fetchPaymentMethods } from "../../billingActions";
import { fetchProduct, getPrice, createRequest } from "../booking-gql";
import { createPlaceObjectForFetchingPrice } from "../../../../functions/create-booking-request-object";
import { clickOnServiceForShowDetail } from '../../homeActions';

/**
 * post request to server for save request job
 * @param {*} locations 
 */
export function postRequestNowBooking(requestObj) {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      dispatch(appLoadingBegin());
      LOG_EVENT.logEvent(LOG_EVENT.REQUEST_CREATED_CWA, {
        serviceId: getState().booking.product.id,
        commercialName: getState().booking.product.commercialName,
      }); 
      const createJobRequestBody = {
        query: createRequest,
        variables: {
          "requestData": requestObj
        }
      }
      GraphRequest.all(createJobRequestBody)
        .then(json => {
          if (!_.isNull(json.data.data) && json.data.data.createRequest) {
            dispatch(updateJobByMatchingStatus(json.data.data.createRequest));
            resolve(json.data.data.createRequest);
          } 
          else {
            dispatch(appFetchFailure(json.data.errors[0].message));
            toast(`${json.data.errors[0].message}`, {
              position: "bottom-center",
              autoClose: 3000,
              limit: 1,
              className: "toast-rejected-payment",
              bodyClassName: "toastify-inner",
              hideProgressBar: true,
              closeOnClick: false,
            });
            dispatch(appLoadingEnd());
            // dispatch(appGoHome());
          }
        }).catch(error => {
          dispatch(appFetchFailure(error));
        });
    })
  }
}
export function requestNowBooking(locations) {
  return (dispatch, getState) => {
    dispatch(setJobMatchingState());
    dispatch(appLoadingBegin());
    let createReqeust = createReqeustObj(getState, locations);
    dispatch(DISPATCH_STATE.bookingOnRequestNowButtonClick(createReqeust));
    dispatch(initJobByMatchingStatus(createReqeust))
    dispatch(postRequestNowBooking(createReqeust))
    .then((res) => {
        if(res) {
          dispatch(fetchStateOfJob());
          dispatch(push('/jobs/' + res.id + '/matching'));
        }
        dispatch(appLoadingEnd());
      })
  }
}
/**
 * Retry matching state by saving new request(job) by previous product
 */
export function retryRequestBooking() {
  return (dispatch, getState) => {
    dispatch(unsetMatchingRequest());
    // dispatch(cancelSetInterval());
    if (getState().booking.createReqeustObj) {
      let createReqeust = getState().booking.createReqeustObj;
      dispatch(initCancelRequestCountDown());
      dispatch(DISPATCH_STATE.bookingOnRequestNowButtonClick(createReqeust));
      dispatch(initJobByMatchingStatus(createReqeust));
      // dispatch(cancelRequestStartToCount());
      dispatch(postRequestNowBooking(createReqeust))
        .then((res) => {
          dispatch(fetchStateOfJob());
        })
    }
  }
}
export const checkPaymentMethodExist = () => {
  return (dispatch, getState) => {
    dispatch(appLoadingBegin());
    return new Promise((resolve, reject) => {
      Payment
        .exist()
        .then((res) => {
          const primaryPayment = res.data.filter(payment => payment.isPrimary === true);
          // dispatch(providePaymentMethod(true));
          // dispatch(getPrimaryPayment(primaryPayment));
          dispatch(DISPATCH_STATE.clientHasPaymentMethod(primaryPayment));
          dispatch(DISPATCH_STATE.setPaymentMethods(res.data));
          dispatch(appLoadingEnd());
          resolve(res);
        })
        .catch((error) => {
          if (typeof (error.response.status) !== 'undefined' && error.response.status === 402) {
            if (error.response.data.message === "Provide Payment method") {
              if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance < 0) {
                dispatch(checkRejectedPayment());
              } else if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance == 0) {
                dispatch(clientHasNoPaymentMethod());
              } 
              dispatch(getPrimaryPayment(null));
              dispatch(DISPATCH_STATE.changePrimaryPayment(null));
              dispatch(DISPATCH_STATE.setPaymentMethods(null));
              dispatch(appFetchFailure(error));
              dispatch(appLoadingEnd());
              resolve();
            }
          } else {
            dispatch(checkPaymentMethodExist());
            dispatch(appFetchFailure(error));
          }
        })
    })
  }
}

/**
 * Just Fetch product without location
 * @param {*} id 
 */
export function fetchBookingV5(id) {
  return (dispatch, getState) => {
    dispatch(appLoadingBegin());
    dispatch(DISPATCH_STATE.fetchBookingBegin());
    return new Promise(resolve => {
      const productRequestBody = {
        query: fetchProduct,
        variables: {
          "id": Number(id)
        }
      }
      GraphRequest.all(productRequestBody)
        .then(json => { 
          if (!_.isNull(json.data.data) && json.data.data.product)  {
            dispatch(DISPATCH_STATE.fetchBookingV5Success(json.data.data.product));
            dispatch(appLoadingEnd());
            resolve(json.data.data.product);
          } else {
            dispatch(appFetchFailure(json.data.errors[0].message));
            toast(`${json.data.errors[0].message}`, {
              position: "bottom-center",
              autoClose: 2000,
              limit: 1,
              className: "toast-rejected-payment",
              bodyClassName: "toastify-inner",
              hideProgressBar: true,
              closeOnClick: false,
            });
            dispatch(appLoadingEnd());
          }
        })
    });
  };
}
/**
 * Fetch price
 * @param {*} id 
 * @param {*} locations 
 */
export function fetchPrice(id, locations) {
  return (dispatch, getState) => {
    if (!_.isEmpty(getState().map.googlePlaces)) {
      locations.jobLocations = [
        ...locations.jobLocations,
        ...getState().map.googlePlaces
      ]
    }
    const dispatchedLocation = locations.jobLocations.map((location) => createPlaceObjectForFetchingPrice(location));
    if(dispatchedLocation) {
      dispatch(appLoadingBegin());
      dispatch(DISPATCH_STATE.fetchPriceBegin(locations));
      const priceRequestBody = {
        query: getPrice,
        variables: {
          "productId": +id,
          "priceModel": {
            "jobLocations": dispatchedLocation,
          }
        }
      }

      GraphRequest.all(priceRequestBody)
        .then((res) => {
          if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
            dispatch(DISPATCH_STATE.fetchPriceSuccess(res.data.data.productPriceList));
            dispatch(appLoadingEnd());
          } else {
            dispatch(DISPATCH_STATE.bookingErrorLocationPrice(res.data.errors[0].message));
            // toast(`${res.data.errors[0].message}`, {
            //   position: "bottom-center",
            //   autoClose: 2000,
            //   limit: 1,
            //   className: "toast-rejected-payment",
            //   bodyClassName: "toastify-inner",
            //   hideProgressBar: true,
            //   closeOnClick: false,
            // });
            dispatch(appLoadingEnd());
            // dispatch(appLoadingEnd());
          }
        })
    }
  };
}

//fetch primary payment
export const fetchPrimaryPayment = () => {
  return (dispatch, getState) => {
    dispatch(fetchPaymentMethods())
      .then((res) => {
        if (res.totalItems > 0) {
          const primaryPayment = res.items.filter(payment => payment.isPrimary === true);
          dispatch(getPrimaryPayment(primaryPayment));
        }
      })

  }
};

export const applyPromoCode = (promoCode) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(appLoadingBegin());
      const promoCodeRequestBody = {
        query: `mutation ClientApplyVoucher($voucherData: ClientApplyVoucherInput!) {
          applyVoucher(voucherData: $voucherData) {
            succeed
            result {
              id
              type
              reason
              amountOff
              percentOff
              discountedOrderAmount
            }
          }
        }`,
        variables: {
          "voucherData": {
            "code": promoCode,
            "productId": Number(getState().booking.product.id),
            "cachedPriceId": Number(getState().booking.prices.cachedPriceId)
          }
        }
      }

      GraphRequest.all(promoCodeRequestBody)
        .then((res) => {
          if(!_.isNull(res.data.data.applyVoucher) && res.data.data.applyVoucher.succeed) {            
            const {type, reason} = res.data.data.applyVoucher.result;
            dispatch(DISPATCH_STATE.resultAppliedPromoCode(res.data.data.applyVoucher.result));
            dispatch(DISPATCH_STATE.addTemporaryPromoCode(getState().booking.promoCode));
            if (!_.isNull(type)) {
              switch (type) {
                case "AMOUNT": {
                  dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
                  dispatch(DISPATCH_STATE.setAmountOff(res.data.data.applyVoucher.result.amountOff));
                }
                case "PERCENT": {
                  dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
                  dispatch(DISPATCH_STATE.setPercentOff(res.data.data.applyVoucher.result.percentOff));
                }

              }
              toast(`${getState().booking.promoCode} applied`, {
                position: "bottom-center",
                autoClose: 2000,
                limit: 1,
                className: "toast-rejected-payment",
                bodyClassName: "toastify-inner",
                hideProgressBar: true,
                closeOnClick: false,
              });
              dispatch(appLoadingEnd());
              dispatch(DISPATCH_STATE.togglePromoCodeBottomSheet());
            } else if (reason) {
              resolve(reason);
              dispatch(DISPATCH_STATE.setNewPrice(null));
              dispatch(DISPATCH_STATE.setAmountOff(null));
              dispatch(DISPATCH_STATE.setPercentOff(null));
              dispatch(DISPATCH_STATE.setPromoCode(null))
              dispatch(appLoadingEnd());
              dispatch(DISPATCH_STATE.togglePromoCodeBottomSheet());
            }
          }
        })
    })
  }
}