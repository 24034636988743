import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {localStorageGetToken} from "../../utils/functions/local-storage";
import {isUserinActive} from '../../utils/functions/check-user-activation';

const LOCATION_BASE_COMPONENT = ['/search', '/search/map'];

export class AuthRequired extends React.Component {

    render() {
        if(!this.props.auth && (_.isNull(this.props.map.location.lat) || _.isNull(this.props.map.location.lng))) {
            return <Redirect to="/map" />;
        } else {
            return this.props.orRender;
        }


        // if(!this.props.auth) {
        //     return(<Redirect to='/welcome' /> );
        // } else if (LOCATION_BASE_COMPONENT.includes(this.props.orRender.props.location.pathname) ) {
        //     return (this.props.orRender);
        // } else if (isUserinActive(this.props.common.client)) {
        //     if (this.props.router.location.pathname === '/home') {
        //         return (this.props.orRender);
        //     } else {
        //         return(<Redirect to='/home' /> );
        //     }
        // } else if ((_.isNull(this.props.map.location.lat) || _.isNull(this.props.map.location.lng))) {
        //     return(<Redirect to='/search' /> );
        // } else {
        //     return (this.props.orRender);
        // }
    }
}

const mapStateToProps = state => ({
    common: state.common,
    map: state.map,
    router: state.router
});

export default connect(mapStateToProps)(AuthRequired);