import * as CONSTANT_ACTIONS from './billingConstant';

export const fetchPaymentMethodSuccess = (methods) => ({
    type: CONSTANT_ACTIONS.FETCH_PAYMENT_METHOD_SUCCESS,
    payload: methods
})
export const billingPaymentMethodEmpty = () => ({
    type: CONSTANT_ACTIONS.BILLING_PAYMENT_METHOD_NULL,
})

export const fetchAccountingBalanceSuccess = (balance) => ({
    type: CONSTANT_ACTIONS.FETCH_ACCOUNTING_BALANCE_SUCCESS,
    payload: balance
})

export const billingDeletePaymentMethod = (methods) => ({
    type: CONSTANT_ACTIONS.BILLING_DELETE_PAYMENT_METHOD,
    payload: methods
})

export const billingErrorPayment = (message = null) => ({
    type: CONSTANT_ACTIONS.BILLING_ERROR_PAYMENT,
    payload: message
})
  
export const billingUnSetErrorPayment = () => ({
    type: CONSTANT_ACTIONS.BILLING_UN_SET_ERROR_PAYMENT
})

export const billingOnAddPaymentMethodsInChoosePage = () => ({
    type: CONSTANT_ACTIONS.BILLING_ON_ADD_PAYMENT_METHODS_IN_CHOOSE_PAGE
})

export const billingSetAdyen = (ady) => ({
    type: CONSTANT_ACTIONS.BILLING_SET_ADYEN,
    payload: ady
});

export const toggleBillingTopUpBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET,
});

export const billingTopUpMyKoin = () => ({
    type: CONSTANT_ACTIONS.BILLING_TOP_UP_MYKOIN,
});

export const setNewAccountBalance = (balanceAmount) => ({
    type: CONSTANT_ACTIONS.SET_NEW_ACCOUNT_BALANCE,
    payload: balanceAmount
});

export const deletePaymentErrorText = (error) => ({
    type: CONSTANT_ACTIONS.DELETE_PAYMENT_ERROR_TEXT,
    payload: error
});

export const setDefaultValueOfDeletePaymentError = () => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR
});

export const setClientBalances = (data) => ({
    type: CONSTANT_ACTIONS.SET_CLIENT_BALANCES,
    payload: data
})