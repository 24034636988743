import {
    REGISTER_FETCH_COUNTRY_CODE,
    SET_DEFAULT_COUNTRY_CODE,
    REGISTER_CHANGE_COUNTRY_SELECTED,
    REGISTER_FETCH_CITIES,
    REGISTER_CODE_VALUE_SET,
    REGISTER_PHONE_NUMBER_VALUE_SET,
    REGISTER_PHONE_NUMBER_VALUE_UNSET,
    REGISTER_INIT_OBJ_CODE_VERIFICATION,
    REGISTER_PHONE_NUMBER_POST_REQUEST,
    REGISTER_INIT_CHECK_CODE_PAGE,
    REGISTER_BACK_TO_WELCOME,
    REGISTER_CHECK_CODE_REDUCE_COUNT,
    REGISTER_CHECK_CODE_INTERVAL,
    CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL
} from '../actions/registerActions';

const INTERVAL_NUMBER_CHECK_CODE = 59;

const initialState = {
    code: null, // for check code page
    countries: {},
    cities: {},
    countrySelected: {},
    number: null,
    // numberCountValid: false,
    codeVerificationObj: {},
    requestNumberCheckCode: INTERVAL_NUMBER_CHECK_CODE,
    requestNumberCheckCodeInterval: null,
};

export default function registerReducer(state = initialState, action) {
    switch (action.type) {
        case REGISTER_FETCH_COUNTRY_CODE:
            return {
                ...state,
                countries: action.payload,
                countrySelected: action.payload[ 0 ],
            }
        case SET_DEFAULT_COUNTRY_CODE:
            return {
                ...state,
                countries: action.payload,
                countrySelected: action.payload,
            }
        case REGISTER_CHANGE_COUNTRY_SELECTED:
            return {
                ...state,
                countrySelected: action.payload,
            }
        case REGISTER_FETCH_CITIES:
            return {
                ...state,
                cities: action.payload,
            }
        case REGISTER_CODE_VALUE_SET:
            return {
                ...state,
                code: action.payload,
            }
        case REGISTER_INIT_OBJ_CODE_VERIFICATION:
            return {
                ...state,
                codeVerificationObj: action.payload,
            }
        case REGISTER_PHONE_NUMBER_VALUE_SET:
            return {
                ...state,
                number: action.payload,
                // numberCountValid: /^([0-9]{10})$/.test(action.payload)
            }
        case REGISTER_PHONE_NUMBER_VALUE_UNSET:
            return {
                ...state,
                number: null,
                // numberCountValid: false
            }
        case REGISTER_BACK_TO_WELCOME:
            return {
                ...state,
                number: null,
                // numberCountValid: false,
                code: null,
                countrySelected: {},
            }
        case REGISTER_PHONE_NUMBER_POST_REQUEST:
            return {
                ...state,
                requestNumberCheckCodeInterval: null,
                requestNumberCheckCode: INTERVAL_NUMBER_CHECK_CODE
            }
        case CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL:
            return {
                ...state,
                requestNumberCheckCodeInterval: null,
            }
        case REGISTER_INIT_CHECK_CODE_PAGE:
            return {
                ...state,
                number: action.payload.number,
                countrySelected: { code: action.payload.code },
            }
        case REGISTER_CHECK_CODE_INTERVAL:
            return {
                ...state,
                requestNumberCheckCodeInterval: action.payload
            }
        case REGISTER_CHECK_CODE_REDUCE_COUNT:
            return {
                ...state,
                requestNumberCheckCode: action.payload
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}