import {
    toast
} from 'react-toastify';
import _ from 'lodash';
import { push } from 'connected-react-router';

import * as DISPATCH_STATE from '../jobsChangeState';
import * as CONSTANT_ACTIONS from '../jobsConstant';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from '../../commonActions';
import {emptyNewMessages} from '../../chatActions/chatChangeState';
import * as LOG_EVENT from "../../../../analytics";
import { jobStateCheckRequestBody, unratedJobAndJobStateCheckerRequestBody } from "../jobs-gql";
/**
 *  setup interval request for job completed
 */
export const intervalJobStateChecker = () => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(DISPATCH_STATE.initIntervalJobStateCheck(setInterval(() => {
                const requestBody = {
                    query: unratedJobAndJobStateCheckerRequestBody,
                    variables: {
                        "requestId": getState().jobs.job.id,
                        "offset": 0,
                        "limit": 1
                    }
                }
                GraphRequest.all(requestBody)
                    .then((res) => {
                        const { unratedJobs, request } = res.data.data;
                        if (_.has(res.data.data, "unratedJobs") && 
                            unratedJobs.totalItems > 0 && 
                            getState().jobs.job.id === unratedJobs.id) {
                            dispatch(DISPATCH_STATE.fetchJobsUnratedSuccess(unratedJobs.items));
                        }
                        if (_.has(res.data.data, "unratedJobs") && request) {
                            dispatch(JobChangeStateIntervalChat(request));
                        }
                    }).catch((error) => dispatch(appFetchFailure(error)));
            }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_COMPLITION)));
        } else {
            const logObj = {
                jobDetails: {
                    serviceId: getState().jobs.job.product.id,
                    commercialName: getState().jobs.job.product.commercialName
                },
                requestId: getState().jobs.job.id,
                value: getState().jobs.job.jobCost.value + 0.00,
                currency: 'PHP'
            };
            LOG_EVENT.logEvent(LOG_EVENT.REQUEST_COMPLETED_CWA, logObj);
        }
    }
}
/**
 * Check state job state and redirect user to home if his|her state is COMPLETED|CANCELED
 * @param {*} job 
 */
export const JobChangeStateIntervalChat = (job) => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== job.state) {
            const requestBody = {
                query: jobStateCheckRequestBody,
                variables: {
                    "requestId": getState().jobs.job.id
                }
            }
            GraphRequest.all(requestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.request) { 
                        dispatch(DISPATCH_STATE.jobChangeStateIntervalChat(res.data.data.request));
                    }
                }).catch(error => dispatch(appFetchFailure(error)));
        }
        if (job.state === CONSTANT_ACTIONS.JOB_STATE_CANCELLED) {
            toast(`Job is ${CONSTANT_ACTIONS.JOB_STATE_CANCELLED}!`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                limit: 1,
                className: "toast-rejected-payment",
                bodyClassName: "toastify-inner",
            });
            dispatch(push('/home'));
            dispatch(checkForActiveJobCount());
        } else if (job.state === CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(push('/home'));
            dispatch(emptyNewMessages());
            dispatch(checkForActiveJobCount());
        }
    }
}
/**
 * When job is completed|canceled check active jobs count
 */
const checkForActiveJobCount = () => {
    return (dispatch, getState) => {
        if (getState().jobs.activeJobsCount !== 0) {
            dispatch(DISPATCH_STATE.jobsActiveCountDecrementOne(getState().jobs.activeJobsCount));
        }
    }
}