import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import * as CONSTANT_ACTIONS from '../savedPlacesConstant';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import { createSavedPlaceObject, createBookmarkedPlaceObject } from "../../../../functions/create-place-object-from-google-suggestions";
import { fetchSavedPlaces } from './savedPlaces-list';
import { createSavedPlaceRequestBody } from "./saved-places-gql";
/**
 * request for saving new saved places
 * @param {*} name 
 */
export const addSavedPlace = (name) => {
    return (dispatch, getState) => {
		const addSavedPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": createSavedPlaceObject(name, getState) }
		}
        dispatch(appLoadingBegin());
		GraphRequest.all(addSavedPlaceRequestBody)
            .then((res) => {
				if (res.data.type !== CONSTANT_ACTIONS.SAVED_PLACE_CUSTOM_TYPE) {
					if (!_.isNull(getState().savedPlaces.searchPointer)) {
						if (getState().savedPlaces.searchPointer === '/account') {
							dispatch(push('/saved-places'));
						} else {
							dispatch(push(getState().savedPlaces.searchPointer));
						}
					}
				} else {
					dispatch(push('/saved-places'));
				}
				if (!_.isNull(res.data.data) && res.data.data.createSavedPlace) {
					dispatch(DISPATCH_STATE.clearSavedPlaceAndType());
					dispatch(appLoadingEnd());
					toast(`Added address`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				} else {
					dispatch(appLoadingEnd());
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
            })
            .catch((e) => {
                console.error('error map', e)
                dispatch(appFetchFailure(e));
            })
    }
}

export const addBookmarkedPlace = (savedPlace) => {
	return (dispatch, getState) => {
		const addBookmarkPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": createBookmarkedPlaceObject(savedPlace) }
		}
		dispatch(requestAddBookmarkedPlace(addBookmarkPlaceRequestBody));
	};
};

export const undoBookmarkedPlace = (savedPlace) => {
	return (dispatch, getState) => {
		let addSavedPlaceRequestBody;
		if (savedPlace.location.id || _.isNull(savedPlace.location.id)) delete savedPlace.location.id;
		if (savedPlace.id || _.isNull(savedPlace.id)) delete savedPlace.id;
		addSavedPlaceRequestBody = {
			query: createSavedPlaceRequestBody,
			variables: { "data": savedPlace }
		}
		dispatch(requestAddBookmarkedPlace(addSavedPlaceRequestBody))
	};
};

export const requestAddBookmarkedPlace = (requestBody) => {
	return (dispatch, getState) => {
		dispatch(appLoadingBegin());
		GraphRequest.all(requestBody)
			.then((res) => {
				if (!_.isNull(res.data.data) && res.data.data.createSavedPlace) {
					dispatch(DISPATCH_STATE.clearSavedPlaceAndType());
					dispatch(fetchSavedPlaces());
					dispatch(appLoadingEnd());
					toast(`Added address`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				} else {
					dispatch(appFetchFailure(res.data.errors[0].message));
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			}).catch((e) => {
				dispatch(appFetchFailure(e));
			});
	}
}