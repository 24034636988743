export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS'; // fetch payment methods of user
export const BILLING_PAYMENT_METHOD_NULL = 'BILLING_PAYMENT_METHOD_NULL'; // put null methods
export const FETCH_ACCOUNTING_BALANCE_SUCCESS = 'FETCH_ACCOUNTING_BALANCE_SUCCESS'; // fetch balance of user
export const BILLING_ON_ADD_PAYMENT_METHODS_IN_CHOOSE_PAGE = 'BILLING_ON_ADD_PAYMENT_METHODS_IN_CHOOSE_PAGE'; // when user click on add payment methods
export const BILLING_REQUEST_FOR_CHANGE_PRIMARY = 'BILLING_REQUEST_FOR_CHANGE_PRIMARY'; // billing request for change primary
export const BILLING_DELETE_PAYMENT_METHOD = 'BILLING_DELETE_PAYMENT_METHOD'; // billing delete payment method
export const BILLING_SET_ADYEN = 'BILLING_SET_ADYEN'; // set adyen billing object
export const BILLING_ERROR_PAYMENT = 'BILLING_ERROR_PAYMENT'; // when error price eccured
export const BILLING_UN_SET_ERROR_PAYMENT = 'BILLING_UN_SET_ERROR_PAYMENT'; // when error price eccured
export const TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET = "TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET";
export const BILLING_TOP_UP_MYKOIN = "BILLING_TOP_UP_MYKOIN";
export const SET_NEW_ACCOUNT_BALANCE = "SET_NEW_ACCOUNT_BALANCE";
export const DELETE_PAYMENT_ERROR_TEXT = "DELETE_PAYMENT_ERROR_TEXT";
export const SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR = "SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR";
export const SET_CLIENT_BALANCES = "SET_CLIENT_BALANCES";