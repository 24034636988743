export const getPriceExtendJobRequestBody = `query ProductPriceList($priceModel: ClientProductPriceModelInput!, $productId: Int!) {
  productPriceList(priceModel: $priceModel, productId: $productId) {
    expirationDateTime
    isInstantMatch
    items {
      jobTime {
        cubeNumbers
        plate {
          ...priceFields
        }
        cube {
          ...priceFields
        }
        total {
          ...priceFields
        }
      }
      price {
        value
        currency
        displayValue
      }
    }
  }
}

fragment priceFields on ProductPriceTimeType {
  value
  unit
  displayUnit
  displayValue
}`;

export const getPrice = `query ProductPriceList($priceModel: ClientProductPriceModelInput!, $productId: Int!) {
  productPriceList(priceModel: $priceModel, productId: $productId) {
    isInstantMatch
    minCubeNumbers
    cachedPriceId
    items {
      jobTime {
        cubeNumbers
        plate {
          ...priceFields
        }
        cube {
          ...priceFields
        }
        total {
          ...priceFields
        }
      }
      price {
        value
        currency
        displayValue
      }
    }
  }
}

fragment priceFields on ProductPriceTimeType {
  value
  unit
  displayUnit
  displayValue
}`;

export const fetchProduct = `query Product($id: Int!) {
  product(productId: $id) {
    id
    mode
    commercialName
    descriptionTitle
    description
    image
    icon
    minPoints
    maxPoints
    howItWorks
    hasAddOnCharge
    priceComponent {
      plateTime {
        ...priceFields
      }
      cubeTime {
        ...priceFields
      }
      maximumTime {
        ...priceFields
      }
    }
  }
}

fragment priceFields on ProductPriceComponentTimeType {
  value
  unit
  displayUnit
  displayValue
}`;

export const extendJobRequestBody = `mutation ExtendJob($extendJobData: ClientJobExtendInput!, $jobId: Int!) {
  extendJob(extendJobData: $extendJobData, jobId: $jobId) {
    id
    state
  }
}`;

export const createRequest = `mutation CreateRequest($requestData: ClientCreateRequestInput!) {
  createRequest(requestData: $requestData) {
    id
    state
  }
}`;

export const createQueuedRequest = `mutation CreateQueueRequest($requestData: ClientQueuedRequestInput!) {
  queueRequest(requestData:$requestData) {
    id
    state
  }
}`;

export const createScheduledRequest = `mutation CreateScheduleRequest($requestData: ClientCreateScheduledRequestInput!) {
  createScheduleRequest(requestData:$requestData) {
    id
    state
  }
}`;