import _ from "lodash";
import { push } from "connected-react-router";
import $ from "jquery";
import { toast } from "react-toastify";	

import * as DISPATCH_STATE from "./homeChangeState";
import { GraphRequest } from "../../../../axios";
import {
	appLoadingBegin,
	appLoadingEnd,
	appFetchFailure,
	appLoadingKuyaBegin,
	appLoadingKuyaEnd,
	fetchClient
} from "../commonActions";
import { bookingSetProductFromHome } from "../bookingActions";
import { fetchJobsUnratedSuccess, jobsActive } from "../jobsActions";
import { mapGetcurrentLocationLocalstorage } from "../mapActions";
import { fetchNews } from "../newsActions";
import * as LOG_EVENT from "../../../analytics";
import { getHomeObject, getHomeObjectWithoutAuth } from "./home-gql";
import { locationStorageGetData } from "../../../functions/local-storage";
/**
 * Show more button
 */
export const clickShowMoreServices = () => {
	return (dispatch, getState) => {
		if (getState().home.isShowMore) {
			$(".Home-services-hidden").slideUp(600);
		} else {
			$(".Home-services-hidden").slideDown(600);
		}
		dispatch(DISPATCH_STATE.clickSHowMoreServices());
	};
};

/**
 * navigate user to specific service by clicking on services in home
 * @param {*} service
 */
export function clickService(service) {
	return (dispatch, getState) => { 
		dispatch(bookingSetProductFromHome(service));
		dispatch(DISPATCH_STATE.clickOnServiceForShowDetail());
		LOG_EVENT.logEvent(LOG_EVENT.PRODUCT_OPENED_CWA, {
			serviceId: service.id,
			commercialName: service.commercialName
		});
		dispatch(push("/booking/" + service.id));
	};
}

export function clickSubCategories(service) {
	return (dispatch, getState) => {
		dispatch(push("/subcategories/" + service.id));
	};
}
export function backToHme() {
	return (dispatch, getState) => {
		dispatch(push("/home/" ));
	};
}

/**
 * Fetch element that is needed by home page
 */
export function fetchHome() {
	return (dispatch, getState) => {
		dispatch(appLoadingBegin());
		dispatch(DISPATCH_STATE.fetchHomeBegin());
		
		let location = getState().map.location;
		const storageLocation = locationStorageGetData(getState);
		dispatch(mapGetcurrentLocationLocalstorage());

		if (window.location.host.split(".")[0] !== "localhost:3000" && window.location.host.split(".")[0] !== "app") {
			const shopInfoRequestBody = {
				query: `query ShopInfo( $url: String!){
					shopUrl: enterprisePartnerByShopURL(url: $url){
						shop {
						title
						}
					}
				}`,
				variables: {
					url: window.location.host.split(".")[0] === "localhost:3000" ? "mydeliveryshop" : window.location.host.split(".")[0],
				}
			};
			GraphRequest.all(shopInfoRequestBody)
				.then((res) => {
					if (res.data.data.shopUrl && res.data.data.shopUrl.shop) {
						dispatch(DISPATCH_STATE.setShopInfo(res.data.data.shopUrl.shop));
					}
				})
		}
		// console.log("homestate", getState().common.auth , getState().common.authCheck)
		const homeRequestBody = {
			query: getState().common.authCheck ? getHomeObject : getHomeObjectWithoutAuth,
			variables: {
				lat: _.isNull(location.lat) ? storageLocation.lat : location.lat,
				lng: _.isNull(location.lng) ? storageLocation.lng : location.lng,
				isWhatsNew: true,
				
			},
		};
		return new Promise((resolve, reject) => {
			GraphRequest.all(homeRequestBody)
				.then(res => {
					if(res.data && res.data.data) {
						const { productCatalog, announcements, activeJobs, unratedJobs } = res.data.data;
						let showInHomeProducts = [];
						if (res.data.data.productCatalog) {
							let allSubCategories = [];
							productCatalog.map(categories => {
								allSubCategories.push(categories);
								categories.subCategories.map((subCategories) => {
									if(subCategories.showInHome) {
										subCategories.products.map((products) => showInHomeProducts.push(products))
									}
								})
							});						
							dispatch(DISPATCH_STATE.setShowInHomeProducts(showInHomeProducts));
							dispatch(checkEmptyProductsList(allSubCategories));
						}
						
						dispatch(DISPATCH_STATE.fetchHomeSuccess(productCatalog, announcements));
						dispatch(getRandomProduct(productCatalog))
							.then((res) => {
								if(res) {
									dispatch(DISPATCH_STATE.setCategorizedProducts(res));
								}
							})
						if (res.data.data.unratedJobs && res.data.data.unratedJobs.totalItems > 0) {
							dispatch(fetchJobsUnratedSuccess(unratedJobs["items"][0]));
						}
						
						dispatch(jobsActive(activeJobs));
						dispatch(appLoadingEnd());
						dispatch(appLoadingKuyaEnd());
						// dispatch(DISPATCH_STATE.homeProductListShouldUpdated(false));
						resolve(res.data.data);
					} else {
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(res.data.errors[0].message));
					}
				})
			});

	};
}

export const getRandomProduct = (arr) => {
	return (getState, dispatch) =>  {
		return new Promise((resolve) => {
			let allProducts = [];
			arr.map((category) => {
				category.subCategories.map((products) => {
					if(products.showInHome) {
						products.products.map(product => {
							allProducts.push(product);
						});
					}
				});

			});
			if (!_.isEmpty(allProducts)) {
				const shuffled = allProducts.sort(function () { return .5 - Math.random() });
				const randomedProducts = shuffled.slice(0, 4);

				if (allProducts.length < 6) {
					resolve({ randomedProducts: null, allProducts: allProducts, showBottomsheet: true })
				} else {
					resolve({ randomedProducts, allProducts, showBottomsheet: false});
				}
			} else {
				resolve({ randomedProducts: null, allProducts: null, showBottomsheet: false})
			}
		});
	}
}

/**
 * Check products exist in array or not.
 * @param {*} products
 */
export const checkEmptyProductsList = allSubCategories => {
	return dispatch => {
		if (_.isEmpty(allSubCategories)) {
			dispatch(DISPATCH_STATE.homeProductCheckExist(false));
		} else {
			dispatch(DISPATCH_STATE.homeProductCheckExist(true));
		}
	};
};

/**
 * Show balance modal when user's balance is negative
 */
export function checkRejectedPayment() {
	return (dispatch, getState) => {
		if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance < 0) {
			dispatch(DISPATCH_STATE.homeRejectedPayment());
		}
	};
}

/**
 * Check for get client for balance
 */
export function checkForGetClientWhenForBalance() {
	return (dispatch, getState) => {
		dispatch(fetchClient(false)).then(r => {
			dispatch(checkRejectedPayment());
		});
	};
}

/**
 * Check user can close rejected payment whether has acive jobs
 */
export function checkHasActiveJob() {
	return (dispatch, getState) => {
		if (getState().jobs.activeJobsCount > 0) {
			dispatch(DISPATCH_STATE.homeRejectedPaymentClose());
		}
	};
}

/**
 * If product is not exist in specific location user can change its location
 */
export function navigateToSearch() {
	return dispatch => {
		dispatch(push("/search"));
	};
}

export const fetchProductCatalog = (data) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.fetchProductCatalogBegin());
		dispatch(appLoadingBegin());
		const productCatalogRequestBody = {
			query: `query ProductCatalog($lat: Float!, $lng: Float!) {
				productCatalog(lat: $lat, lng: $lng) {
					title
					subCategories {
					id
					title
					showInHome
					icon
					products {
						id
						commercialName
						icon
					}
					}
				} 
			}`,
			variables: {
				"lat": Number(data.lat),
				"lng": Number(data.lng),
			}
		};
		return new Promise(resolve => {
			GraphRequest.all(productCatalogRequestBody)
				.then((res) => {
					const { productCatalog } = res.data.data;
					if (res.data.data.productCatalog) {
						let allSubCategories = [];
						productCatalog.map(subCategories => {
							allSubCategories.push(subCategories);
						});
						dispatch(checkEmptyProductsList(allSubCategories));
						resolve();
					}
	
					dispatch(DISPATCH_STATE.fetchProductCatalogSuccess(productCatalog));
					dispatch(appLoadingEnd());
				})
		})
	}
}
