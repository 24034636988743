export const SELECT_SAVED_PLACES_ADDRESS = 'SELECT_SAVED_PLACES_ADDRESS'; // when user toch one item from result of google places results for choosing saved places
export const SAVED_PLACES_FETCH_ALL = 'SAVED_PLACES_FETCH_ALL'; // fetch all saved places from server
export const SAVED_PLACES_FIND_HOME_WORK = 'SAVED_PLACES_FIND_HOME_WORK'; // try to find home address in all saved places
export const SAVED_PLACES_SET_TYPE = 'SAVED_PLACES_SET_TYPE'; // set type for saved places
export const SAVED_PLACES_CLEAR_TYPE_AND_PLACE = 'SAVED_PLACES_CLEAR_TYPE_AND_PLACE'; // clear saved place and type 
export const SAVED_PLACES_SELECT_MAP = 'SAVED_PLACES_SELECT_MAP';
export const SAVED_PLACES_UNSELECT_MAP = 'SAVED_PLACES_UNSELECT_MAP'; // 
export const SAVED_PLACES_WANTED_TO_DELETE_PLACE = 'SAVED_PLACES_WANTED_TO_DELETE_PLACE'; // saved place wanted to delete place
export const SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE = 'SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE'; // set null saved place wanted to delete place
export const SAVED_PLACES_SET_SEARCH_LOCATION = 'SAVED_PLACES_SET_SEARCH_LOCATION'; // set pointer to previous location
export const SAVED_PLACES_UNSET_SEARCH_LOCATION = 'SAVED_PLACES_UNSET_SEARCH_LOCATION'; // unset pointer to previous location
export const SAVED_PLACES_CLICK_SELECTED_PLACE = 'SAVED_PLACES_CLICK_SELECTED_PLACE'; // click on selected place
export const SAVED_PLACES_CLEAR_SELECTED_PLACE = 'SAVED_PLACES_CLEAR_SELECTED_PLACE'; // clear selected place
export const UNDO_REMOVE_BOOKMARK_SAVED_PLACE = 'UNDO_REMOVE_BOOKMARK_SAVED_PLACE';
export const EMPTY_TEMP_STORED_BOOKMARKED = 'EMPTY_TEMP_STORED_BOOKMARKED';

export const SAVED_PLACE_CUSTOM_TYPE = 'Custom';
export const SAVED_PLACE_HOME_TYPE = 'Home';
export const SAVED_PLACE_WORK_TYPE = 'Work';

export const LOCATION_SEARCH_NAME = '/search';