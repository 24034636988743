import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import {
    messageObjectCreatorFromTwilio,
    messageObjectForMe,
    MESSAGE_STATUS_RECEIVED,
    MESSAGE_STATUS_SENT,
    MESSAGE_STATUS_WAITING,
    messageObjectCreator,
    messageObjectCreatorFromChatService,
    messageObjectForMeWithNewService
} from '../../../../functions/message-object-creator';

/**
 * messages from twilio when user has job active
 * @param {*} messages 
 */
export const initMessagesFromTwilioFirstTime = (messages) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.initMessagesFromTwilioFirstTimeDispatch(messageObjectCreatorFromTwilio(messages, getState().common.client)));
    }
}
/**
 * Scroll page to top
 */
export const chatScrollToBottom = () => {
    return (dispatch) => {
        let scroll = document.getElementById('v-scrollable-chat');
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
        }

        dispatch(DISPATCH_STATE.chatScrollToBottomDispatch());
    }
};
/**
 * Update state of messages by seen last index of seen message
 * @param {*} last 
 */
export function updateMemberMessageReadStatus(last) {
    return (dispatch, getState) => {
        let messages = getState().chat.messages;
        let newMessages = [];
        newMessages = messages.map((message) => {
            if (_.has(message, 'index') && message.index <= last) {
                message.status = MESSAGE_STATUS_RECEIVED
            }
            return message;
        });
        dispatch(DISPATCH_STATE.initMessagesFromTwilioFirstTimeDispatch(newMessages));
    }
}
/**
 * Get message from live channel and put it in messages array in redux
 * @param {*} message 
 * @param {*} file 
 */
export const putMessageToMessagesFromChannelLive = (message, file = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let messages = getState().chat.messages;
            if (getState().common.client.id !== parseInt(message.author)) {
                let newMessage = messageObjectForMe(message, file);
                if (_.has(messages[ messages.length - 1 ], 'index')) {
                    newMessage[ 'index' ] = messages[ messages.length - 1 ][ 'index' ] + 1;
                }
                messages.push(newMessage);
                dispatch(DISPATCH_STATE.chatPutMessageToMessagesArray(messages));
            }
            resolve();
        });
    }
}
/**
 * add prev messages items to exist messages
 * @param {*} messages 
 */
export const addPrevMessagesItemsToExistMessages = (messages) => {
    return (dispatch, getState) => {
        let existMessages = getState().chat.messages;
        let prevMessages = messageObjectCreatorFromTwilio(messages, getState().common.client)
        let newMessages = [ ...prevMessages, ...existMessages ];
        dispatch(DISPATCH_STATE.initMessagesFromTwilioFirstTimeDispatch(newMessages));
    }
}
/**
 * Add message from me to messages
 * @param {*} message 
 * @param {*} file 
 */
export const putMessageToMessages = (message, file = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let messages = getState().chat.messages;
            let newMessage = messageObjectForMe(message, file);
            if (_.has(messages[ messages.length - 1 ], 'index')) {
                newMessage[ 'index' ] = messages[ messages.length - 1 ][ 'index' ] + 1;
                newMessage[ 'status' ] = MESSAGE_STATUS_WAITING;
            }
            messages.push(newMessage);
            dispatch(DISPATCH_STATE.chatPutMessageToMessagesArray(messages));
            resolve()
        });
    }
}
/**
 * Change status index of message for show message sent successfully
 * @param {*} index 
 */
export const changeStatusOfMessage = (index) => {
    return (dispatch, getState) => {
        let messages = getState().chat.messages;
        messages = messages.map((message, i) => {
            if (message.index === index) {
                message.status = MESSAGE_STATUS_SENT;
            }
            return message;
        });
        dispatch(DISPATCH_STATE.chatPutMessageToMessagesArray(messages));
    }
}
/**
 * put messages from histroy
 * @param {*} job 
 */
export const initMessagesFromJobChatHistory = (job) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.initMessagesFromJobChatHistoryDispatch(messageObjectCreator(job[ 0 ].data, getState().common.client)));
    }
}

/**
 * messages when user has job active
 * @param {*} messages 
 */
export const initMessages = (messages) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.initMessagesDispatch(messageObjectCreatorFromChatService(messages, getState().common.client)));
    }
}

/**
 * Update message list when user scroll
 * @param {*} messages 
 */
export const paginateMessageList = (messages) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.updateMessageList(messageObjectCreatorFromChatService(messages, getState().common.client)));
    }
}

/**
 * Add message from me to messages
 * @param {*} message
 * @param {*} file
 */
export const putNewMessage = (message, file = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (typeof (getState().chat.newMessages[0]) !== 'undefined') {
                let messages = getState().chat.newMessages[ 0 ].items;
                let newMessage = messageObjectForMeWithNewService(message, file);
                if (_.has(messages[ messages.length - 1 ], 'index')) {
                    newMessage[ 'index' ] = messages[ messages.length - 1 ][ 'index' ] + 1;
                    newMessage[ 'status' ] = MESSAGE_STATUS_WAITING;
                }
                messages.push(newMessage);
                const messageList = {
                    "0": {
                        "items": messages,
                        "totalItems": messages.length
                    }
                }
                dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
                resolve();
            }
        });
    }
}
/**
 * Change status index of message for show message sent successfully
 * @param {*} index 
 */
export const changeStatusOfMessageNewService = (index) => {
    return (dispatch, getState) => {
        let messages = getState().chat.newMessages[ 0 ].items;
        messages = messages.map((message, i) => {
            if (message.index === index) {
                message.status = MESSAGE_STATUS_SENT;
            }
            return message;
        });
        const messageList = {
            "0": {
                "items": messages,
                "totalItems": messages.length
            }
        }
        dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
    }
}