import _ from 'lodash';
import $ from 'jquery';
import {push} from "connected-react-router";

import * as DISPATCH_STATE from '../mapChangeState';
import * as CONSTANT_ACTIONS from '../mapConstant';
import {
    appGoBack
} from '../../commonActions';
import {
    getExactAddress
} from '../../../../functions/nearby-search';
import {
    createPlaceObjectFromGoogleGetExactAddress,
    addUserLocationToPlace
} from '../../../../functions/create-place-object-from-google-suggestions';
import {
    savedPlaceSelectedAddress,
    savedPlaceUnSelectMap
} from '../../savedPlacesActions';
import {
    mapSetCurrentLocationLocalstorage
} from './map-current-location';
import {
    // createMarkerObject, 
    createMarkerPartnerObject
} from '../../../../functions/create-marker';
// import CurrentHolderImg from '../../../../../assests/icons/current-holder.png';
import kuyaPartner from '../../../../../v5-icons/partner-avatar.png';
import PinSvg from '../../../../../assests/images/jobs/end-resized.png';
import {
    calculateGoogleMapOffsetRippleEffect
} from '../../../../functions/calculate-google-map-offset';
import {
    JOB_MATCHING_CIRCLE_RIPPLE
} from '../../jobsActions';
import * as LOG_EVENT from '../../../../analytics';
import { enableAddNextStop } from '../../bookingActions';
/**
 * When user confirm marker on map we create marker on specific location on map
 */
export function createMarker(params = {}) {
    return (dispatch, getState) => {
        let location = {
            lat: getState().map.googleMap.getCenter().lat(),
            lng: getState().map.googleMap.getCenter().lng(),
        }
        dispatch(DISPATCH_STATE.mapCreateMarker());
        dispatch(DISPATCH_STATE.initializeMapHide());
        dispatch(createMarkerSetLocationPlaces(location, params));
    }
}

export function setLocation(params = {}) {
	return (dispatch, getState) => {
		let location = {
			lat: getState().map.googleMap.getCenter().lat(),
			lng: getState().map.googleMap.getCenter().lng(),
		};
		dispatch(DISPATCH_STATE.mapCreateMarker());
		dispatch(DISPATCH_STATE.initializeMapHide());
		dispatch(createMarkerSetLocationPlacesWithMap(location, params));
	};
}

/**
 * When user show location on map by confirm marker we try to find nearest location to selected location by user
 * @param {*} location 
 */
export function createMarkerSetLocationPlaces(location, params) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapCreateMarkerSetLocationPlaces());
        dispatch(fetchSearchPlacesGoogleByMarkerLocation(location, params));
    }
}

export function createMarkerSetLocationPlacesWithMap(location, params) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapCreateMarkerSetLocationPlaces());
		dispatch(fetchSearchPlacesGoogleByMarkerLocationWithMap(location, params));
	};
}
export function fetchSearchPlacesGoogleByMarkerLocationWithMap(location, params) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapSearchPlacesFetchByMarkerLocation());
		getExactAddress(location, (results, status) => {
			let place = createPlaceObjectFromGoogleGetExactAddress(
				results,
				location,
				CONSTANT_ACTIONS.GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY,
			);
			if (_.isEmpty(params)) {
				dispatch(mapSetPlaceGoogleSource(place));
				dispatch(push("/home"));
			}
		});
	};
}
/**
 * Fetch google places for when set merker with map and get first place for put in location source or destination
 * @param {*} location 
 */
export function fetchSearchPlacesGoogleByMarkerLocation(location, params) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapSearchPlacesFetchByMarkerLocation());
        getExactAddress(location, (results, status) => {
            let place = createPlaceObjectFromGoogleGetExactAddress(results, location, CONSTANT_ACTIONS.GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY);
            if (getState().savedPlaces.savedMap) {
                dispatch(savedPlaceSelectedAddress(place));
                dispatch(savedPlaceUnSelectMap());
            } else if (_.isEmpty(params)) {
                dispatch(mapSetPlaceGoogleSource(place));
                dispatch(appGoBack());
            } else if (_.has(params, 'id')) {
                const { minPoints } = getState().booking.product;
                const { currentRefName } = getState().search;

                if (
					minPoints === 2 &&
					_.isEmpty(getState().map.googlePlaces) &&
					(currentRefName === "destination" || currentRefName === "second-source")
				) {
                    dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination([place]));
					dispatch(enableAddNextStop());
				} else if (!_.isEmpty(getState().map.googlePlaces)) {
                    const attachedPlaces = [...getState().map.googlePlaces, place];
                    dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(attachedPlaces));
				} else {
                    dispatch(mapSetPlaceGoogleSource(place));
                }
                
                dispatch(appGoBack());
            } else if (_.has(params, 'index')) {
                let index = parseInt(params['index']);
                if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
                    dispatch(mapSetPlaceGoogleSource(place));
                    dispatch(appGoBack());
                } else if (index >= CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
                    let places = getState().map.googlePlaces;
                    places[index - 1] = place;
                    dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
                    dispatch(appGoBack());
                } else if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
                    let places = getState().map.googlePlaces;
                    places.push(place);
                    dispatch(appGoBack(getState().router.location.pathname.replace('/-1/map', '')));
                    dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
                }
            }
            LOG_EVENT.logEvent(LOG_EVENT.GOOGLE_GEOCODER_BY_MARKER_LOCATION_CWA);
        });

    };
}

export const mapSetPlaceGoogleSource = (place) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapGetCurrentLocationSet(addUserLocationToPlace(getState, place)));
        dispatch(mapSetCurrentLocationLocalstorage(place))
        dispatch({
            type: CONSTANT_ACTIONS.MAP_SET_PLACE_GOOGLE_SOURCE
        });
    }
}

export function removeMarkerForSelectingLocation() {
    return (dispatch, getState) => {
        if (!_.isEmpty(getState().map.googleMarker)) {
            getState().map.googleMarker.setMap(null);
        }
        if (!_.isNull(getState().map.googlePartnerMarkers)) {
            getState().map.googlePartnerMarkers.map((marker) => {
                marker.setMap(null);
                return marker;
            })
        }
        dispatch(DISPATCH_STATE.mapRemoveMarkerForSelectingLocation());
    }
}

/**
 * Put marker for location of partner in live mode
 */
export function createMarkerPartnerLocation(location = {}) {
    return (dispatch, getState) => {
        if (_.isNull(getState().map.googlePartnerMarkers)) {
            getState().map.googleMap.setZoom(14);
            getState().map.googleMap.panTo(location);
            dispatch(DISPATCH_STATE.mapCreateMarkerPartner(createMarkerPartnerObject(getState, location, kuyaPartner, PinSvg)));
        } else {
            getState().map.googlePartnerMarkers[0].setPosition(location);
            dispatch(DISPATCH_STATE.mapCreateMarkerSetPosition());
        }

    }
}
/**
 * When user allows to get current location from browser api we show marker for showing current location exactly
 * @param {*} position 
 */
export function createCurrentLocationMarkerForMatching() {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapIntervalMarkerMatching(setInterval(() => {
            if (!_.isEmpty(getState().map.googleMap)) {
                let rippleCss = calculateGoogleMapOffsetRippleEffect();
                rippleCss['display'] = 'block';
                let location = getState().map.location;
                // location.lat -= 0.00200;
                getState().map.googleMap.panTo({lat: location.lat - 0.00200, lng: location.lng});
                $(JOB_MATCHING_CIRCLE_RIPPLE).css(rippleCss);       
                // dispatch(DISPATCH_STATE.mapCreateCurrentMarker( createMarkerObject(getState, getState().map.location, CurrentHolderImg) ));
                getState().map.googleMap.setOptions({
                    draggable: false,
                    zoomControl: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true
                });
                dispatch(DISPATCH_STATE.mapClearIntervalMarkerMatching());
            }
        }, 300)));
    }
}