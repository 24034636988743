import _ from 'lodash';
import { Howl } from 'howler';
import { push } from 'connected-react-router';
import { toast } from "react-toastify";	

import * as CONSTANT_ACTIONS from '../jobsConstant';
import * as DISPATCH_STATE from '../jobsChangeState';
import { appGoHome, appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import matchedTrack from '../../../../../assests/images/jobs/matched-track.mp3';
import * as LOG_EVENT from '../../../../analytics';
import { onClearIntervalCancelRequest } from "../jobsActions";
import { jobMatchingWillUnMount } from "../jobsChangeState";
import { jobStateCheckRequestBody, fetchPartnerOfJob } from '../jobs-gql';
import {
	setPercentOff,
	setAmountOff,
	setPromoCode,
	toggleConfirmationBottomSheet,
	setNewPrice,
	setDefaultConfirmationBottomSheet,
} from "../../bookingActions";
import { mapClearIntervalMarkerMatching } from "../../mapActions";
/**
 * Check state of job when job in matching state
 */
export const fetchStateOfJob = () => {
    return (dispatch, getState) => {
        if (_.isEmpty(getState().jobs.job)) {
            dispatch(appGoHome());
        } else {
            if (getState().jobs.jobMatchingState) {
                dispatch(intervalRequestState());
            }
        }
        dispatch({
            type: CONSTANT_ACTIONS.FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING
        })
    }
}
// setup interval request
export const intervalRequestState = () => {
    return (dispatch, getState) => {
        const requestBody = {
            query: jobStateCheckRequestBody,
            variables: {
                "requestId": getState().jobs.job.id
            }
        }
        dispatch(DISPATCH_STATE.initIntervalMatchingJobRequest(setInterval(() => {
        GraphRequest.all(requestBody)
			.then((res) => {
                if(res.data && res.data.data.request) {
                    if (res.data.data.request) {
                        dispatch({
                            type: CONSTANT_ACTIONS.FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING_SUCCESS,
                            payload: res.data.data.request,
                        });
                    }
                    if (res.data.data.request.state === CONSTANT_ACTIONS.SUCCESS_STATE_OF_INTREVAL_REQUEST_MATCHED) {
                        dispatch(fetchPartnerOfMatchedJob(res.data.data.request.id));
                        dispatch(cancelSetInterval()); // cancel interval
                        if (getState().booking.confirmationBottomSheet) {
                            dispatch(toggleConfirmationBottomSheet());
                        }
                        LOG_EVENT.logEvent(LOG_EVENT.REQUEST_MATCHED_CWA, {
                            requestId: res.data.data.request.id,
                            jobDetails: {
                                serviceId: getState().booking.product.id,
                                commercialName: getState().booking.product.commercialName,
                            },
                            currentPrice: getState().booking.currentPrice,
                        });
                    } else if (res.data.data.request.state === CONSTANT_ACTIONS.SUCCESS_STATE_OF_INTREVAL_REQUEST_UNFULFILLED) {
                        dispatch(cancelSetInterval()); // cancel interval
                        if (getState().booking.confirmationBottomSheet) {
                            dispatch(toggleConfirmationBottomSheet());
                        }
                        LOG_EVENT.logEvent(LOG_EVENT.REQUEST_EXPIRED_CWA, {
                            requestId: res.data.data.request.id,
                            jobDetails: {
                                serviceId: getState().booking.product.id,
                                commercialName: getState().booking.product.commercialName,
                            },
                            currentPrice: getState().booking.currentPrice,
                        });
                    } else if (res.data.data.request.state === CONSTANT_ACTIONS.SUCCESS_STATE_OF_INTREVAL_REQUEST_CANCELLED || res.data.data.request.state === CONSTANT_ACTIONS.SUCCESS_STATE_OF_INTREVAL_REQUEST_STARTED) {
                        dispatch(cancelSetInterval()); // cancel interval
                        dispatch(push("/home"));
                        toast("Request is cancelled", {
                            position: "bottom-center",
                            autoClose: 5000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
                } else {
                    dispatch(cancelSetInterval()); // cancel interval
                    dispatch(push("/home"));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appFetchFailure(res.data.errors[0].message));
                }
				
			})
			.catch(() => dispatch(appFetchFailure()));
        }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_STATE)));
    }
}
/**
 * Get partner of matched job from server
 * @param {*} partner_id 
 */
export const fetchPartnerOfMatchedJob = (partner_id) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.fetchPartnerOfMatchedJobBegin());
        dispatch(appLoadingBegin());
        const getPartnerRequestBody = {
            query: fetchPartnerOfJob,
            variables: {
                "requestId": Number(partner_id)
            }
        }
        GraphRequest.all(getPartnerRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.request) {
                    dispatch(appLoadingEnd());
                    dispatch(playTrackMatched());
                    dispatch(DISPATCH_STATE.fetchPartnerOfMatchedJobSuccess(res.data.data.request));
                }
            }).catch(() => dispatch(appFetchFailure()))
    }
}
/**
 * Cancel Interval request
 */
export const cancelSetInterval = () => {
    return (dispatch, getState) => {
        clearInterval(getState().jobs.matchingTimeout);
        dispatch(DISPATCH_STATE.cancelSetIntervalJob());
    }
}
// play track matched
export const playTrackMatched = () => {
    return (dispatch) => {
        let sound = new Howl({
            src: [ matchedTrack ],
            preload: false,
            html5: true,
            format: [ 'mp3' ]
        });
        sound.play();
        dispatch(DISPATCH_STATE.playTrackMatchedDispatch(sound));
    }
}
/**
 * Cancel request by send request
 */
export const cancelRequestMatching = () => {
    return (dispatch, getState) => {
        if (getState().jobs.matchingRequest && getState().jobs.matchingRequest.state === CONSTANT_ACTIONS.JOB_STATE_MATCHING) {
            dispatch(cancelSetIntervalAndGoHome());
        }
        dispatch(DISPATCH_STATE.cancelSetIntervalJob());
    }
}
/**
 * Cancel Interval request and go home page
 */
export const cancelSetIntervalAndGoHome = () => {
    return (dispatch, getState) => {
        dispatch(cancelSetInterval());
        dispatch(onClearIntervalCancelRequest());
        dispatch(appLoadingBegin());
        dispatch(appLoadingEnd());
        if (!getState().booking.confirmationBottomSheet) {
            dispatch(push("/booking/" + getState().booking.product.id));
        } 
        LOG_EVENT.logEvent(LOG_EVENT.REQUEST_CANCELLED_BEFORE_MATCHED_CWA, {  
            jobDetails: {
                serviceId: getState().booking.product.id,
                commercialName: getState().booking.product.commercialName,
            },
            currentPrice: getState().booking.currentPrice,
            // service: getState().booking.product
        });
    }
}
/**
 * Cancel Interval request and go home page in unfulfilled state
 */
export const cancelSetIntervalAndGoHomeInUnfulfilled = () => {
    return (dispatch, getState) => {
        dispatch(setNewPrice(null));
        dispatch(setAmountOff(null));
        dispatch(setPercentOff(null));
        dispatch(setPromoCode(null));
        dispatch(cancelSetInterval());
        dispatch(push('/booking/' + getState().booking.product.id));
    }
}

export const unMountJobMatching = () => {
    return (dispatch, getState) => {
        dispatch(mapClearIntervalMarkerMatching());
		dispatch(cancelRequestMatching());
		dispatch(cancelSetInterval());
		dispatch(jobMatchingWillUnMount());
		dispatch(setNewPrice(null));
		dispatch(setAmountOff(null));
		dispatch(setPercentOff(null));
		dispatch(setPromoCode(null));
		dispatch(setDefaultConfirmationBottomSheet());
    }
}