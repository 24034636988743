export const FETCH_BOOKING_BEGIN   = 'FETCH_BOOKING_BEGIN';
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
export const FETCH_JOB_EXTEND_PRICE_SUCCESS = 'FETCH_JOB_EXTEND_PRICE_SUCCESS'; // fetch job price
export const BOOKING_TOGGLE_SHOW_DESCRIPTION = 'BOOKING_TOGGLE_SHOW_DESCRIPTION'; // When Description is toggled
export const BOOKING_TOGGLE_SHOW_OPTION = 'BOOKING_TOGGLE_SHOW_OPTION'; // When Option is toggled
export const BOOKING_PLUS_CUBE = 'BOOKING_PLUS_CUBE';
export const BOOKING_MINUS_CUBE = 'BOOKING_MINUS_CUBE';
export const BOOKING_SET_PRODUCT_FROM_HOME = 'BOOKING_SET_PRODUCT_FROM_HOME'; // when user is in home and click product put product in state for useing in booking search location
export const BOOKING_ON_REQUEST_NOW_BUTTON_CLICK = 'BOOKING_ON_REQUEST_NOW_BUTTON_CLICK'; // when user click on request now button
export const BOOKING_NAVIGATE_TO_LOCATION_FROM_BOOKING = 'BOOKING_NAVIGATE_TO_LOCATION_FROM_BOOKING';
export const BOOKING_ON_CHANGE_SPECIAL_TEXT = 'BOOKING_ON_CHANGE_SPECIAL_TEXT'; // when user change text of special textarea
export const BOOKING_ON_FOCUS_SPECIAL_TEXT = 'BOOKING_ON_FOCUS_SPECIAL_TEXT'; // when user focus text of special textarea
export const BOOKING_WILL_UNMOUNT = 'BOOKING_WILL_UNMOUNT'; // when booking component will unmount
export const BOOKING_EMPTY_NOTE = 'BOOKING_EMPTY_NOTE'; //empty note in homepage
export const BOOKING_SET_NULL_SELECTED_OPTIONS = 'BOOKING_SET_NULL_SELECTED_OPTIONS'; //set null note in homepage
export const BOOKING_ERROR_LOCATION_PRICE = 'BOOKING_ERROR_LOCATION_PRICE'; // when error price eccured
export const BOOKING_UN_SET_ERROR_LOCATION_PRICE = 'BOOKING_UN_SET_ERROR_LOCATION_PRICE'; // when error price eccured
export const BOOKING_REMOVE_PRICE = 'BOOKING_REMOVE_PRICE';  // remove booking price
export const BOOKING_SET_CURRENT_PRICE = 'BOOKING_SET_CURRENT_PRICE'; // booking set current price
export const BOOKING_GET_SELECTED_OPTIONS = 'BOOKING_GET_SELECTED_OPTIONS'; // booking get selected options

export const BOOKING_OPTIONS_FORM_ID = '#Booking-options-checkbox-form';

export const FETCH_BOOKING_V5_SUCCESS = "FETCH_BOOKING_V5_SUCCESS";
export const CLOSE_LOCATION_SEARCH_BOTTOM_SHEET = "CLOSE_LOCATION_SEARCH_BOTTOM_SHEET";
export const OPEN_LOCATION_SEARCH_BOTTOM_SHEET = "OPEN_LOCATION_SEARCH_BOTTOM_SHEET";
export const ENABLE_ADD_NEXT_STOP = "ENABLE_ADD_NEXT_STOP";
export const NAVIGATE_TO_REQUEST_PAGE = "NAVIGATE_TO_REQUEST_PAGE";
export const REQUEST_CHECK_BOOKING_PRODUCT_EXIST = "REQUEST_CHECK_BOOKING_PRODUCT_EXIST";
export const FETCH_PRICE_BEGIN = "FETCH_PRICE_BEGIN";
export const FETCH_PRICE_SUCCESS = "FETCH_PRICE_SUCCESS";
export const ENABLE_BOOKING_BOTTOM_SHEET = "ENABLE_BOOKING_BOTTOM_SHEET";
export const BACK_TO_BOOKING_PAGE = "BACK_TO_BOOKING_PAGE";
export const NAVIGATE_TO_REVIEW_BOOKING = "NAVIGATE_TO_REVIEW_BOOKING";
export const REVIEW_BOOKING_PRICE_AND_LOCATION_EXIST = "REVIEW_BOOKING_PRICE_AND_LOCATION_EXIST";
export const CHANGE_BOOKING_TIME_FORMAT = "CHANGE_BOOKING_TIME_FORMAT";
export const CLIENT_HAS_PAYMENT_METHOD = "CLIENT_HAS_PAYMENT_METHOD";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const CLOSE_BOOKING_PAYMENT_BOTTOM_SHEET = "CLOSE_BOOKING_PAYMENT_BOTTOM_SHEET";
export const OPEN_BOOKING_PAYMENT_BOTTOM_SHEET = "OPEN_BOOKING_PAYMENT_BOTTOM_SHEET";
export const SET_IS_INSTANT_MATCH = "SET_IS_INSTANT_MATCH";
export const FETCH_PAYMENT_METHOD_SUCCESS = "FETCH_PAYMENT_METHOD_SUCCESS";
export const CHANGE_PRIMARY_PAYMENT = "CHANGE_PRIMARY_PAYMENT";
export const CLIENT_HAS_REJECTED_PAYMENT = "CLIENT_HAS_REJECTED_PAYMENT";
export const TOGGLE_TOPUP_BOTTOM_SHEET = "TOGGLE_TOPUP_BOTTOM_SHEET";
export const SET_TOP_UP_AMOUNT = "SET_TOP_UP_AMOUNT";
export const TOP_UP_MYKOIN_BEGIN = "TOP_UP_MYKOIN_BEGIN";
export const REDIRECT_CLIENT_TO_ADYEN_FOR_TOP_UP = "REDIRECT_CLIENT_TO_ADYEN_FOR_TOP_UP";
export const SET_PRODUCT_ON_REVIEW_BOOKING = "SET_PRODUCT_ON_REVIEW_BOOKING";
export const SET_PRICE_ON_REVIEW_BOOKING = "SET_PRICE_ON_REVIEW_BOOKING";
export const TOGGLE_PAYMENT_METHODS_BOTTOM_SHEET = "TOGGLE_PAYMENT_METHODS_BOTTOM_SHEET";
export const TOGGLE_REJECTED_PAYMENT = "TOGGLE_REJECTED_PAYMENT";
export const TOGGLE_CANCEL_BOOKING_BOTTOM_SHEET = "TOGGLE_CANCEL_BOOKING_BOTTOM_SHEET";
export const WILL_UN_MOUNT_REVIEW_BOOKING = "WILL_UN_MOUNT_REVIEW_BOOKING";
export const CLIENT_HAS_POSITIVE_BALANCE = "CLIENT_HAS_POSITIVE_BALANCE";
export const RETRY_WITH_TOPUP = "RETRY_WITH_TOPUP";
export const SET_DEFAULT_RETRY_WITH_TOPUP = "SET_DEFAULT_RETRY_WITH_TOPUP";
export const RETRY_WITH_CARD = "RETRY_WITH_CARD";
export const SET_DEFAULT_RETRY_WITH_CARD = "SET_DEFAULT_RETRY_WITH_CARD";
export const SET_DEFAULT_VALUE_REJECTED_PAYMENT = "SET_DEFAULT_VALUE_REJECTED_PAYMENT";
export const TOGGLE_PROMO_CODE_BOTTOM_SHEET = "TOGGLE_PROMO_CODE_BOTTOM_SHEET";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_NEW_PRICE = "SET_NEW_PRICE";
export const SET_AMOUNT_OFF = "SET_AMOUNT_OFF";
export const SET_PERCENT_OFF = "SET_PERCENT_OFF";
export const REMOVE_PROMO_CODE = "REMOVE_PROMO_CODE";
export const RESULT_APPPLIED_PROMO_CODE = "RESULT_APPPLIED_PROMO_CODE";
export const ADD_TEMPORARY_PROMO_CODE = "ADD_TEMPORARY_PROMO_CODE";
export const CHANGE_PROMO_CODE = "CHANGE_PROMO_CODE";
export const VOIP_IS_SUPPORTED = "VOIP_IS_SUPPORTED";
export const OPEN_QUEUED_JOB_BOTTOM_SHEET = "OPEN_QUEUED_JOB_BOTTOM_SHEET";
export const CLOSE_QUEUED_JOB_BOTTOM_SHEET = "CLOSE_QUEUED_JOB_BOTTOM_SHEET";
export const SET_QUEUED_JOB_TIME = "SET_QUEUED_JOB_TIME";
export const TOGGLE_SCHEDULE_BOOKING = "TOGGLE_SCHEDULE_BOOKING";
export const DISPATCH_SET_DEFAULT_SCHEDULE_DATE = "DISPATCH_SET_DEFAULT_SCHEDULE_DATE";
export const SET_SCHEDULE_BOOKING_DATE = "SET_SCHEDULE_BOOKING_DATE";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const TOGGLE_REQUEST_PLACED_MODAL = "TOGGLE_REQUEST_PLACED_MODAL";
export const SET_MAX_DATE = "SET_MAX_DATE";
export const SET_MIN_DATE = "SET_MIN_DATE";
export const TOGGLE_CONFIRMATION_BOTTOM_SHEET = "TOGGLE_CONFIRMATION_BOTTOM_SHEET";
export const SET_DEFAULT_CONFIRMATION_BOTTOM_SHEET = "SET_DEFAULT_CONFIRMATION_BOTTOM_SHEET";
export const PRODUCT_MODE_UNMOUNT = "PRODUCT_MODE_UNMOUNT";
