import _ from 'lodash';

import * as DISPATCH_STATE from '../commonChangeState';
import { GraphRequest } from '../../../../../axios';
import {appFetchFailure} from './common-failure';
import { setClientLocale, getClientLocale } from "../../../../functions/local-storage";
/**
 * fetch client
 * @param {*} loading 
 */
export function fetchClient(loading = true) {
    return (dispatch) => {
        if (loading) {
          dispatch(DISPATCH_STATE.appLoadingBegin());
        }
        const clientRequestBody = {
			query: `query Client {
            client {
              id
              givenName
              lastName
              emailAddress
              mobileNumber {
                number
                fullNumber
                countryCode {
                  icon
                  code
                }
              }
            employerCompany
            refereeCode
            referralCode
            livingCity {
                id
                displayValue
            }
            workingCity {
                id
                displayValue
            }
            state
            currency {
              unit
              countryCode
              locale
              signText
              signSvg
            }
          }}`,
		};
      return new Promise((resolve) => {
        GraphRequest.all(clientRequestBody)
          .then(json => {
            if (!_.isNull(json.data.data) && json.data.data.client) {
              dispatch(DISPATCH_STATE.appGetClient(json.data.data.client));
              if (!getClientLocale()) {
                setClientLocale(json.data.data.client.currency.locale);
              }
              resolve(json.data.data.client);
              dispatch(DISPATCH_STATE.appLoadingEnd());
            } else {
              dispatch(appFetchFailure(json.data.errors[0].message));
              dispatch(DISPATCH_STATE.appAuthCheckEnd());
            }
          })
      })
    };
  }