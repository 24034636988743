import {
	FETCH_HOME_BEGIN,
	FETCH_HOME_SUCCESS,
	CLICK_SHOW_MORE_SERVICES,
	HOME_WILL_UN_MOUNT,
	HOME_REJECTED_PAYMENT,
	HOME_REJECTED_PAYMENT_CLOSE,
	HOME_REJECTED_PAYMENT_PSP,
	HOME_PRODUCT_CHECK_EXIST,
	HOME_PRODUCT_LIST_SHOULD_UPDATED,
	FETCH_PRODUCT_CATALOG_SUCCESS,
	GO_TO_MAP_FROM_BOTTOM_SHEET,
  SET_SHOP_INFO,
  SET_CATEGORIZED_PRODUCTS,
  TOGGLE_NAVIGATE_BOTTOM_SHEET,
  SET_SHOW_IN_HOME_PRODUCTS
} from "../actions/homeActions";

  const initialState = {
    services: [],
    news: [],
    isShowMore: false,
    existProducts: false,
    isRejectedPayment: false,
    rejectedPaymentPsp: null,
    productExist: true,
    productListShouldUpdated: false,
    setLocationBottomSheet: true,
    goToMapFromBottomSheet: false,
    shopInfo: null,
    randomedProducts: null,
    allProducts: null,
    showBottomSheet: null,
    navigateBottomSheet: false,
    showInHomeProducts: null
  };
  
  export default function homeReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_HOME_BEGIN:
        return {
          ...state,
        };
  
      case FETCH_HOME_SUCCESS:
        return {
          ...state,
          services: action.payload.catalog, 
          news: action.payload.news,
          existProducts: true
        };
      case CLICK_SHOW_MORE_SERVICES:
        return {
          ...state,
          isShowMore: !state.isShowMore
        };
      case HOME_WILL_UN_MOUNT: 
        return {
          ...state,
          isShowMore: false,
          navigateBottomSheet: false,
          services: [],
          allProducts: null,
          existProducts: false
        }
      case HOME_REJECTED_PAYMENT: 
        return {
          ...state,
          isRejectedPayment: true
        }
      case HOME_REJECTED_PAYMENT_CLOSE: 
        return {
          ...state,
          isRejectedPayment: false,
          rejectedPaymentPsp: null,
        }
      case HOME_REJECTED_PAYMENT_PSP: 
        return {
          ...state,
          rejectedPaymentPsp: action.payload
        }
      case HOME_PRODUCT_CHECK_EXIST: 
        return {
          ...state,
          productExist: action.payload
        }
      case HOME_PRODUCT_LIST_SHOULD_UPDATED: 
        return {
          ...state,
          productListShouldUpdated: action.payload
        }
      case FETCH_PRODUCT_CATALOG_SUCCESS:
        return {
          ...state,
          services: action.payload
        }
      case GO_TO_MAP_FROM_BOTTOM_SHEET:
        return {
          ...state,
          goToMapFromBottomSheet: true
        }
      case SET_SHOP_INFO:
        return {
          ...state,
          shopInfo: action.payload
        }
      case SET_CATEGORIZED_PRODUCTS:
        return {
          ...state,
          randomedProducts: action.payload.randomedProducts,
          allProducts: action.payload.allProducts,
          showBottomSheet: action.payload.showBottomsheet
        }
      case TOGGLE_NAVIGATE_BOTTOM_SHEET:
        return {
          ...state,
          navigateBottomSheet: !state.navigateBottomSheet
        }
      case SET_SHOW_IN_HOME_PRODUCTS:
        return {
          ...state,
          showInHomeProducts: action.payload
        }
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }