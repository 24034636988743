import _ from 'lodash';
import * as DISPATCH_STATE from './chatChangeState';
import { bookingRemovePrice, bookingSetCurrentPrice, fetchPrimaryPayment} from '../bookingActions';
import { appLoadingBegin, appLoadingEnd} from '../commonActions';
/**
 * When user click on nevermind in extend modal in option modal
 */
export const onNeverMindExtend = () => {
    return (dispatch) => {
        dispatch(bookingRemovePrice());
        dispatch(bookingSetCurrentPrice(0));
        dispatch(DISPATCH_STATE.chatOnNevermindExtend());
    }
}
/**
 * When user typing inform twilio
 */
export const chatIsTyping = () => {
    return (dispatch, getState) => {
        if (getState().chat.channelRoom) {
            getState().chat.channelRoom.typing();
        }
    }
}
/**
 * when user click on job detail in menu of chat history
 */
export const jobChatDetailModal = () => {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        if (_.isNull(getState().common.primaryPayment)) {
            dispatch(fetchPrimaryPayment());
        }
        dispatch(DISPATCH_STATE.onJobDetail());
        dispatch(appLoadingEnd());
    }
}

export const jobChatDetailCancel = () => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.onJobDetail());
	}
}