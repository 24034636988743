import * as DISPATCH_STATE from '../chatChangeState';
import {
    appLoadingBegin,
    appLoadingEnd,
    appFetchFailure
} from '../../commonActions';
import {
    addPrevMessagesItemsToExistMessages
} from './chat-messages';

/**
 * chat init paging functionality
 * @param {*} messages 
 */
export const chatInitPagingFunctionality = (paging) => {
    return (dispatch, getState) => {
        let chatRes = paging;
        delete chatRes.items;
        dispatch(DISPATCH_STATE.chatInitPagingFunctionalityDispatch(paging));
    }
}
/**
 * check for get prev page chat
 */
export const chatPrevMessage = () => {
    return (dispatch, getState) => {
        if (getState().chat.chatPaging && getState().chat.chatPaging.hasPrevPage) {
            dispatch(appLoadingBegin());
            getState().chat.chatPaging.prevPage().then((res) => {
                dispatch(appLoadingEnd());
                dispatch(addPrevMessagesItemsToExistMessages(res['items']));
                dispatch(chatInitPagingFunctionality(res));
            }).catch((e) => dispatch(appFetchFailure(e)));
        }
    }
}