import Resizer from 'react-image-file-resizer';
import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import * as CONSTANT_ACTIONS from '../chatConstant';
import { putMessageToMessages, chatScrollToBottom, changeStatusOfMessage, putNewMessage, changeStatusOfMessageNewService } from './chat-messages';
import { getLastConsumedMessageIndex } from './chat-connecting';
import { createFormDataAttach, createFormDataRecorder } from '../../../../functions/create-formdata-attach';
import { toast } from 'react-toastify';
import { GraphRequest } from '../../../../../axios';
import { sendTextMessageRequestBody } from "./chat-gql";
import { appFetchFailure } from '../../commonActions'
/**
 * Send message to other side from me
 * @param {*} message 
 * @param {*} file 
 */
export const sendMessage = (message, file = null) => {
    return (dispatch, getState) => {
        if (getState().chat.channelRoom) {
            dispatch(DISPATCH_STATE.chatSendMessageToChannelRoom());
            dispatch(putMessageToMessages(message, file)).then(() => dispatch(chatScrollToBottom()));
            getState().chat.channelRoom
                .sendMessage(message)
                .then((res) => {
                    dispatch(changeStatusOfMessage(res));
                    let setTimeoutMessage = setTimeout(() => {
                        dispatch(getLastConsumedMessageIndex(getState().chat.channelRoom));
                        clearTimeout(setTimeoutMessage);
                    }, 1000);
                });
        }
    }
}
/**
 * When user attach file
 * @param {*} file 
 */
export const chatOnAttachFile = (file) => {
    return (dispatch) => {
        if (CONSTANT_ACTIONS.CHAT_ON_ATTACH_FILE_MAX_SIZE < file.size) {
            toast(`${CONSTANT_ACTIONS.CHAT_ON_ATTACH_FILE_MAX_SIZE_ERROR}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        } else {
            dispatch(DISPATCH_STATE.chatOnAttachFileDispatch());
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                let formData = createFormDataAttach(file);
                dispatch(sendMessage(formData, reader));
            }
        }

    }
}
/**
 * when user stop recording his|her voice
 * @param {*} file 
 */
export const chatOnRecordStop = (file) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.chatOnAttachFileDispatch());
        let formData = createFormDataRecorder(file.blob);
        dispatch(sendMessage(formData, file));
    }
}

/**
 * Send message to other side from me / new service
 * @param {*} message 
 * @param {*} file 
 */
export const sendMessageWithNewService = (message, file = null) => {
    return (dispatch, getState) => {
        var formdata = new FormData();
        let chatRequestBody;
        const requestQuery = {
            "query": `mutation CreateChatMessage($channelId: Int!, $file: Upload!) {
                chatSendFile(channelId: $channelId, file: $file) {
                    _id
                    index
                    media
                    type
                    consumedBy
                    authorId
                    authorType
                    createdAt
                }
            }`,
            "variables": {
                "channelId": getState().jobs.job.id,
                "file": null
            }
        }
        if (!_.isNull(file)) {
                formdata.append("operations", JSON.stringify(requestQuery));
                formdata.append("map", "{ \"0\": [\"variables.file\"] }");
                formdata.append("0", file.blob ? file.blob : file, file.name);
        } else {
            chatRequestBody = {
                query: sendTextMessageRequestBody,
                variables: {
                    "channelId": getState().jobs.job.id,
                    "text": message
                }
            }
        }
        dispatch(putNewMessage(message, file)).then(() => dispatch(chatScrollToBottom()))
            .then(() => {
                dispatch(DISPATCH_STATE.sendMessageBegin());
                GraphRequest.all(_.isNull(file) ? chatRequestBody : formdata)
                    .then((res) => {
                        if (!_.isNull(res.data.data) && res.data.data.chatSendText) {
                            dispatch(changeStatusOfMessageNewService(res.data.data.chatSendText.index));
                        } else if (!_.isNull(res.data.data) && res.data.data.chatSendFile) {
                            dispatch(changeStatusOfMessageNewService(res.data.data.chatSendFile.index));
                        } 
                        else {
                            dispatch(appFetchFailure(res.data.errors[0].message));
                        }
                    }).catch((error) => dispatch(appFetchFailure(error)));
            })
    }
}
/**
 * When user attach file / new service
 * @param {*} file 
 */
export const chatOnAttachFileWithNewService = (file) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.chatOnAttachFileNewServiceDispatch());
            //Reduce file size
            let convertedFile;
            Resizer.imageFileResizer(
                file,
                700,
                700,
                'JPEG',
                70,
                0,
                blob => {
                  convertedFile = new File([blob], new Date().toISOString(), {type: 'image/jpg'});
                  let reader = new FileReader();
                    reader.readAsDataURL(convertedFile);
                    reader.onload = (e) => {
                        convertedFile['reader'] = reader;                        
                        dispatch(sendMessageWithNewService('', convertedFile))
                    }
                },
                'blob'
              );   
    }
}
/**
 * when user stop recording his|her voice / new service
 * @param {*} file 
 */
export const chatOnRecordStopWithNewService = (file) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.chatOnAttachFileNewServiceDispatch());
        dispatch(sendMessageWithNewService('', file));
    }
}