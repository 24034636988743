import { push } from 'connected-react-router';
import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import * as LOG_EVENT from '../../../../analytics';
import { localStorageSetCountryFlag } from "../../../../functions/local-storage";
/**
 * send phone number to server for get code
 */
export function phoneNumberPostRequest() {
    return (dispatch, getState) => {
        // dispatch(push('/check-code/' + getState().register.countrySelected.code + getState().register.number));
        localStorageSetCountryFlag(getState().register.countrySelected.icon);
        dispatch(appLoadingBegin());
        const sendMobileNumberBodyRequest = {
            query: `mutation SendVerificationCode($input: ClientMobileNumberInput!) {
                sendVerificationCode(data: $input) {
                    succeed
                    result
                    details
                }
            }`,
            variables: {
                "input": initObjPhoneNumberRequest(getState)
            }
        }
        GraphRequest.all(sendMobileNumberBodyRequest)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.sendVerificationCode.succeed) {
                    dispatch(DISPATCH_STATE.registerPhoneNumberRequestFinish(json));
                    dispatch(appLoadingEnd());
                    LOG_EVENT.logEvent(LOG_EVENT.CUSTOMER_VERIFY_CODE_CWA);
                } else {
                    dispatch(appFetchFailure(json.data.data.sendVerificationCode.details))
                }
            })
            .catch(error => {
                dispatch(appFetchFailure(error))
            });
        dispatch(DISPATCH_STATE.registerPhoneNumberPostRequest());
    }
}
/**
 * Create obj that is needed for sending to server
 * @param {*} getState 
 */
const initObjPhoneNumberRequest = (getState) => {
    let obj = {
        number: parseInt(getState().register.number),
        countryCode: {
            code: getState().register.countrySelected.code,
            icon: getState().register.countrySelected.icon
        },
        fullNumber: getState().register.countrySelected.code + getState().register.number
    }
    return obj;
}