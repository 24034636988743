import { push } from 'connected-react-router';
import _ from 'lodash';
import queryString from 'query-string';
import { toast } from "react-toastify";	

import * as DISPATCH_STATE from './commonChangeState';
import { localStorageLogout, localStorageSetTokenAndUserName, localStorageSetFcmToken, localStorageGetFcmToken, localStorageSetAndroidApp, localStorageSetIosApp } from '../../../functions/local-storage';
import { checkBrowser, getBrowser, isIOS, isSafari } from '../../../functions/check-browser';
import { NAME_CHROME_BROWSER, NAME_FIREFOX_BROWSER, NAME_SAFARI_BROWSER } from '../../../../components/tutorial/add-to-home-screen';
import { GraphRequest } from "../../../../axios";
import { appFetchFailure } from './actions/common-failure';
import { localStorageHasDeviceId, localStorageSetTwa } from '../../../../utils/functions/local-storage';
import * as serviceWorker from '../../../../serviceWorker';
import { registerFcm } from './actions/common-fcm';
import * as LOG_EVENT from "../../../analytics";
import { localStorageGetCurrentBalance } from "../../../functions/local-storage";
import * as CONSTANT_ACTIONS from "./commonConstant";
import { MakeQuerablePromise } from "../chatActions/actions/chat-connecting";
import { setNewAccountBalance } from "../billingActions";
/**
 * try again internet
 */
export const appInternetTryAgain = () => {
  return (dispatch) => {
    dispatch(DISPATCH_STATE.appInternetTryAgainDispatch());
    window.location.reload();
  }
}
/**
 * Check Rotation of phone
 */
export const appCheckRotation = () => {
  return (dispatch) => {
    if ('orientation' in window) {
      dispatch(DISPATCH_STATE.appDetectRotationDevice())
      window.addEventListener("orientationchange", function () {
        dispatch(DISPATCH_STATE.appDetectRotationDevice())
      });
    }
  }
}
/**
 * set Token and usernname 
 * @param {*} data 
 */
export const appSetLocalStorage = (data) => {
  return (dispatch) => {
    localStorageSetTokenAndUserName(data);
    dispatch(DISPATCH_STATE.appSetLocalstorage(data));
  }
}
/**
 * Logout from application
 */
export function appLogout() {
  return (dispatch) => {
    let device_id = localStorageHasDeviceId();
    if (device_id) {
      LOG_EVENT.logEvent(LOG_EVENT.CUSTOMER_LOGOUT);
      DISPATCH_STATE.appLoadingBegin(); 
      const logoutRequestBody = {
        query: `mutation Logout($id: String!) {
          logout(deviceId: $id) {
            succeed
            result
          }
        }`,
        variables: { "id" : device_id }
      }
      GraphRequest.all(logoutRequestBody)
        .then((res) => {
          localStorageLogout();
          serviceWorker.unregisterAll();
          dispatch(DISPATCH_STATE.appProifleLogout());
          DISPATCH_STATE.appLoadingEnd();
          window.location.replace("/home");
        }).catch((e) => {
          dispatch(appFetchFailure(e));
        })
    } else {
      localStorageLogout();
      serviceWorker.unregisterAll();
      dispatch(DISPATCH_STATE.appProifleLogout());
      window.location.replace("/home");
    }
  }
}
/**
 * navigate to add home screen by browser
 */
export const navigateAddToHomeScreen = () => {
  return (dispatch) => {
    if (checkBrowser()) {
      let browser = getBrowser().toLowerCase();
      if (browser === NAME_CHROME_BROWSER.toLowerCase()) {
        dispatch(push('/tutorial/chrome'));
      } else if (browser === NAME_SAFARI_BROWSER.toLowerCase()) {
        dispatch(push('/tutorial/safari'));
      } else if (browser === NAME_FIREFOX_BROWSER.toLowerCase()) {
        dispatch(push('/tutorial/firefox'));
      } else {
        dispatch(push('/tutorial'));
      }
    }
  }
}
/**
 * Check standalone
 */
export const isRunningStandalone = () => {
  return (window.matchMedia('(display-mode: standalone)').matches);
}
/**
 * Check twa
 */
export const appCheckTwa = () => {
  return (dispatch) => {
    let query = queryString.parse(window.location.search);
    if (_.has(query, 'twa')) {
      localStorageSetTwa();
    }
    if (_.has(query, 'fcm')) {
      localStorageSetFcmToken(query.fcm);
      let fcmToken = localStorageGetFcmToken();

      dispatch(registerFcm(fcmToken));
    }
  }
}

export const appDetectTWA = () => {
  return dispatch => {
    if (document.referrer.includes('android-app://')) {
      localStorageSetAndroidApp();
    } else if (isIOS() || isSafari()) {
      localStorageSetIosApp();
    } else {
      localStorageSetAndroidApp();
    }
  }

}
export const setLocationPointer = () => {
	return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.setLocationPointer(getState().router.location.pathname));
    dispatch(push("/home"))
	};
};

export const checkIsTopUpSuccess = (topUpAmount) => {
  return (dispatch, getState) => {
    const currentBalance = parseInt(localStorageGetCurrentBalance());
    const accountBalance = getState().billing.balance[0].balance;
    if (!_.isNull(topUpAmount) && !_.isNaN(currentBalance)) {
      if (currentBalance + parseInt(topUpAmount) !== accountBalance) {
        dispatch(DISPATCH_STATE.appLoadingBegin());
        dispatch(setIntervalToCheckAccountBalance(topUpAmount));
      } else {
        localStorage.removeItem("currentBalance");
        toast(`${getState().common.client.currency.signText} ${topUpAmount} added to MyKoins`, {
          position: "bottom-center",
          autoClose: 5000,
          limit: 1,
          className: "toast-rejected-payment",
          bodyClassName: "toastify-inner",
          hideProgressBar: true,
          closeOnClick: false,
        });
      }
    }

  }
}

export const setIntervalToCheckAccountBalance = (topUpAmount) => {
	return (dispatch, getState) => {
    let requestSent;
    const getBalanceRequestBody = {
		  query: `query GetBalance($unit: String) {
        balances(unit: $unit) {
            unit
            balance
            rate
            displayValue
            currencySign
        }
      }`,
      variables: {
        unit: getState().common.client.currency.unit,
      },
	  };
    requestSent = GraphRequest.all(getBalanceRequestBody);
    dispatch(setAccountingRequestCount());
    let requestStatus = MakeQuerablePromise(requestSent);

      dispatch(
        DISPATCH_STATE.initIntervalCheckAccountBalance(
          setInterval(() => {
            if (!requestStatus.isPending()) {
              dispatch(DISPATCH_STATE.appLoadingBegin());
              requestSent = GraphRequest.all(getBalanceRequestBody).then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.balances) {
                  dispatch(setNewAccountBalance(res.data.data.balances));
                  dispatch(setAccountingRequestCount());
                  const accountBalance = res.data.data.balances[0].balance;
                  const currentBalance = parseInt(localStorageGetCurrentBalance());
    
                  if (currentBalance + parseInt(topUpAmount) === accountBalance) {
                    clearInterval(getState().common.intervalCheckAccountBalance);
                    dispatch(DISPATCH_STATE.clearIntervalCheckAccountBalance());
                    localStorage.removeItem("currentBalance");
                    toast(`${getState().common.client.currency.signText} ${topUpAmount} added to MyKoins`, {
                      position: "bottom-center",
                      autoClose: 5000,
                      limit: 1,
                      className: "toast-rejected-payment",
                      bodyClassName: "toastify-inner",
                      hideProgressBar: true,
                      closeOnClick: false,
                    });
                    dispatch(DISPATCH_STATE.appLoadingEnd());
                  } else if (getState().common.accountingRequestCount > 4) {
                    clearInterval(getState().common.intervalCheckAccountBalance);
                    dispatch(DISPATCH_STATE.clearIntervalCheckAccountBalance());
                    dispatch(DISPATCH_STATE.toggleChatWithSupportBottomSheet());
                    dispatch(DISPATCH_STATE.appLoadingEnd());
                  }
                }
              });
              requestStatus = MakeQuerablePromise(requestSent);
            }
            
          }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_ACCOUNT_BALANCE),
        ),
      );
	};
};

export const setAccountingRequestCount = () => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.setAccountingRequestCount(Number(getState().common.accountingRequestCount)));
  }
}