export const REGISTER_FETCH_COUNTRY_CODE = 'REGISTER_FETCH_COUNTRY_CODE';
export const SET_DEFAULT_COUNTRY_CODE = 'SET_DEFAULT_COUNTRY_CODE';
export const REGISTER_FETCH_CITIES = 'REGISTER_FETCH_CITIES';
export const REGISTER_CHANGE_COUNTRY_SELECTED = 'REGISTER_CHANGE_COUNTRY_SELECTED';
export const REGISTER_CODE_VALUE_SET = 'REGISTER_CODE_VALUE_SET';
export const REGISTER_PHONE_NUMBER_VALUE_SET = 'REGISTER_PHONE_NUMBER_VALUE_SET'; // set number when length is valid
export const REGISTER_PHONE_NUMBER_VALUE_UNSET = 'REGISTER_PHONE_NUMBER_VALUE_UNSET'; // remove number when length is not valid
export const REGISTER_PHONE_NUMBER_POST_REQUEST = 'REGISTER_PHONE_NUMBER_POST_REQUEST';
export const REGISTER_PHONE_NUMBER_REQUEST_FINISH = 'REGISTER_PHONE_NUMBER_REQUEST_FINISH';
export const REGISTER_INIT_CHECK_CODE_PAGE = 'REGISTER_INIT_CHECK_CODE_PAGE';
export const REGISTER_CHECK_VERIFICATION_CODE = 'REGISTER_CHECK_VERIFICATION_CODE';
export const REGISTER_INIT_OBJ_CODE_VERIFICATION = 'REGISTER_INIT_OBJ_CODE_VERIFICATION';
export const REGISTER_BACK_TO_WELCOME = 'REGISTER_BACK_TO_WELCOME';
export const REGISTER_CHECK_CLIENT_EXIST = 'REGISTER_CHECK_CLIENT_EXIST';
export const REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE = 'REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE';
export const REGISTER_CHECK_CODE_REDUCE_COUNT = 'REGISTER_CHECK_CODE_REDUCE_COUNT'; // when start to reduce number of register count number count
export const REGISTER_CHECK_CODE_INTERVAL = 'REGISTER_CHECK_CODE_INTERVAL'; // set interval
export const CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL = 'CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL'; // when interval clear out