import {
	MAP_INITIALIZE_SCRIPT,
	MAP_CREATE_GOOGLE_MAP,
	MAP_CREATE_MARKER,
	MAP_INITIALIZE_REF,
	MAP_INITIALIZE_SCRIPT_END,
	MAP_AFTER_INITIALIZE_SCRIPT_LOADED,
	MAP_INITIALIZE_FINISH_ICON,
	MAP_REMOVE_GOOGLE_MARKER,
	MAP_FINISH_SET_LEFT_TOP,
	MAP_GET_CURRENT_LOCATION,
	MAP_CREATE_CURRENT_MARKER,
	MAP_SEARCH_PLACES_FETCH,
	MAP_GET_CURRENT_LOCATION_SET,
	MAP_GET_CURRENT_LOCATION_LOCALSTORAGE,
	MAP_INPUT_SELECT_ADDRESS,
	MAP_REMOVE_MARKER_FOR_SELECTING_LOCATION,
	MAP_INPUT_SELECT_ADDRESS_FIRST_DESTINATION,
	MAP_SEARCH_PLACES_FETCH_FOR_CENTER_OF_MAP,
	MAP_SET_PLACE_GOOGLE,
	MAP_CLEAR_GOOGLE_MAP_ARRAYS_OF_DESTINATION,
	MAP_ON_DRAG_EVENT,
	MAP_CREATE_MARKER_PARTNER,
	MAP_INITIALIZE_SHOW,
	MAP_INITIALIZE_HIDE,
	MAP_INTERVAL_MARKER_MATCHING,
	MAP_CLEAR_INTERVAL_MARKER_MATCHING,
	INIT_USER_LOCATION_DETAIL,
	ADD_FIRST_STOP,
	DRAG_GOOGLE_PLACE_ITEM_END,
	CHANGE_CURRENT_LOCATION,
	SET_PICKUP_LOCATION_TO_MAP,
	TOGGLE_INFO_BOTTOM_SHEET,
	MAP_FINISH_SET_LEFT_TOP_FIRST_TIME,
} from "../actions/mapActions";
  
    const initialState = {
		init: false,
		googleScript: null,
		googleMap: {},
		googleMarker: {}, // marker in google map
		googlePartnerMarkers: null, // marker in google map for partner and current location
		googleCurrentMarker: {}, // marker for current locations
		googleMapRef: {}, // ref to div that has id map
		googlePlaces: [], // holds destinations of job request
		googleMapPlace: {
			// uses in google map when center is changed
			lat: null,
			lng: null,
			title: "",
			address: "",
		},
		googleSearchPlaces: [], // holds results of google search places
		finish: {},
		finishTop: null,
		finishLeft: null,
		location: {
			// holds current location of user
			lat: null,
			lng: null,
			defaultLat: 14.5887291,
			defaultLng: 121.0215046,
			title: "",
			address: "",
		},
		initMap: false,
		dragged: false, // for drag event of pin icon
		mapIntervalMarkerMatching: null, // for save interval in createCurrentLocationMarkerForMatching
		hasFirstStop: false,
		infoBottomSheet: true
	};
    
    export default function mapReducer(state = initialState, action) {
      switch (action.type) {
			case MAP_SEARCH_PLACES_FETCH_FOR_CENTER_OF_MAP:
			case MAP_SET_PLACE_GOOGLE:
			case SET_PICKUP_LOCATION_TO_MAP:
				return {
					...state,
					googleMapPlace: action.payload,
				};
			case MAP_INITIALIZE_SCRIPT:
				return {
					...state,
					googleScript: action.payload,
				};
			case MAP_INTERVAL_MARKER_MATCHING:
				return {
					...state,
					mapIntervalMarkerMatching: action.payload,
				};
			case MAP_CLEAR_INTERVAL_MARKER_MATCHING:
				return {
					...state,
					mapIntervalMarkerMatching: action.payload,
				};
			case MAP_INITIALIZE_SHOW:
				return {
					...state,
					initMap: true,
				};
			case MAP_INITIALIZE_HIDE:
				return {
					...state,
					initMap: false,
				};
			case MAP_CREATE_GOOGLE_MAP:
				return {
					...state,
					googleMap: action.payload,
				};
			case MAP_CREATE_MARKER:
				return {
					...state,
					googleMarker: action.payload,
				};
			case MAP_CREATE_MARKER_PARTNER:
				return {
					...state,
					googlePartnerMarkers: action.payload,
				};
			case MAP_CREATE_CURRENT_MARKER:
				return {
					...state,
					googleCurrentMarker: action.payload,
				};
			case MAP_INITIALIZE_REF:
				return {
					...state,
					googleMapRef: action.payload,
				};
			case MAP_INITIALIZE_SCRIPT_END:
				return {
					...state,
					init: true,
				};
			case MAP_INITIALIZE_FINISH_ICON:
				return {
					...state,
					finish: action.payload,
				};
			case MAP_AFTER_INITIALIZE_SCRIPT_LOADED:
				return {
					...state,
				};
			case MAP_REMOVE_GOOGLE_MARKER:
			case MAP_REMOVE_MARKER_FOR_SELECTING_LOCATION:
				return {
					...state,
					googleMarker: {},
					googlePartnerMarkers: null,
				};
			case MAP_FINISH_SET_LEFT_TOP:
			case MAP_FINISH_SET_LEFT_TOP_FIRST_TIME:
				return {
					...state,
					finishLeft: action.payload.left,
					finishTop: action.payload.top,
				};
			case MAP_GET_CURRENT_LOCATION:
				return {
					...state,
				};
			case MAP_GET_CURRENT_LOCATION_SET:
				return {
					...state,
					location: action.payload,
				};
			case MAP_GET_CURRENT_LOCATION_LOCALSTORAGE:
				return {
					...state,
					location: action.payload,
				};
			case MAP_INPUT_SELECT_ADDRESS:
				return {
					...state,
					location: action.payload,
				};
			case MAP_INPUT_SELECT_ADDRESS_FIRST_DESTINATION:
				return {
					...state,
					googlePlaces: action.payload,
				};
			case MAP_SEARCH_PLACES_FETCH:
				return {
					...state,
					googleSearchPlaces: action.payload,
				};
			case MAP_CLEAR_GOOGLE_MAP_ARRAYS_OF_DESTINATION:
				return {
					...state,
					googlePlaces: [],
				};
			case MAP_ON_DRAG_EVENT:
				return {
					...state,
					dragged: !state.dragged,
				};
			case INIT_USER_LOCATION_DETAIL:
				return {
					...state,
					location: action.payload,
				};
			case ADD_FIRST_STOP:
				return {
					...state,
					hasFirstStop: true,
				};
			case DRAG_GOOGLE_PLACE_ITEM_END:
				return {
					...state,
					googlePlaces: action.payload,
        };
		case CHANGE_CURRENT_LOCATION:
			return {
			...state,
			location: action.payload
		}
		case TOGGLE_INFO_BOTTOM_SHEET: 
			return {
				...state,
				infoBottomSheet: !state.infoBottomSheet,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
		}
    }