import _ from 'lodash';
import {
    push
} from 'connected-react-router';

import * as DISPATCH_STATE from './searchChangeState';
import {
    appGoBack
} from '../commonActions';
import {
    setGooglePlace,
    initializeMapShow
} from '../mapActions';

/**
 * Navigate user to add more stop component for adding more places
 */
export const addMoreStopPlaces = () => {
    return (dispatch, getState) => {
        dispatch(push(getState().router.location.pathname + '/add'));
        dispatch(DISPATCH_STATE.searchNavigateToAddMoreStopPlaces());
    }
}

/**
 * If user does not have product in booking in location search booking redirect user to home page
 */
export const searchCheckBookingProductExist = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.searchCheckBookingProductExistDispatch());
        if (_.isEmpty(getState().booking.product)) {
            dispatch(push('/home'));
        }
    }
}

/**
 * If minPoints for product is 2 confirm button is showed and navigate user to booking product
 */
export const searchNavigateToBookingFromLocationSearchBooking = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.searchNavigateToBookingFromLocationSearchBookingDispatch());
        dispatch(push('/booking/' + getState().booking.product.id));
    }
}

export const searchNavigateToMapFromLocationSearch = (params) => {
    return (dispatch, getState) => {
        dispatch(initializeMapShow());
        dispatch(setGooglePlace(params));
        const pathname = (getState().router.location.pathname === "/" || getState().router.location.pathname === "/home") ? "/home" : getState().router.location.pathname;
        dispatch(push(pathname + '/map'));
        dispatch(DISPATCH_STATE.searchNavigateToMapFromLocationSearchBookingDispatch());
    }
}

export const searchNavigateToMapFromHome = () => {
    return (dispatch, getState) => {
        dispatch(initializeMapShow());
        dispatch(setGooglePlace({}));
        dispatch(push('search/map'));
        dispatch(DISPATCH_STATE.searchNavigateToMapFromLocationSearchBookingDispatch());
    }
}

/**
 * When user click on input for specify new location or change current places navigate user to location search
 * @param {*} index 
 */
export const searchBookingLocationMoreStopOnFocus = (index) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.searchBookingLocationMoreStopOnFocusDispatch());
        dispatch(push(getState().router.location.pathname + '/' + index))
    }
}

/**
 * Check for what should put in search box in location search booking
 * @param {*} ref 
 * @param {*} index 
 */
export const searchBookingSearchInit = (ref, index) => {
    return (dispatch, getState) => {
        if (index === 0) {
            ref.current.value = getState().map.location.title || getState().map.location.address;
        } else if (index >= 1) {
            if (index - 1 in getState().map.googlePlaces) {
                ref.current.value = getState().map.googlePlaces[index - 1].title || getState().map.googlePlaces[index - 1].address;
            } else {
                dispatch(appGoBack());
            }
        }
        ref.current.select();
        dispatch(DISPATCH_STATE.searchBookingLocationSearchInitCheckWitchBoxWasId());
    }
}

/**
 * Check when first time location search booking add more component load 
 * @param {*} ref 
 * @param {*} index 
 */
export const searchBookingLocationStopInit = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.searchBookingLocationMoreStopPlacesExists());
        if (getState().booking.product.maxPoints <= 2) {
            dispatch(appGoBack());
        }
    }
}
/**
 * Navigate user to booking page
 */
export const searchBookingStopConfirm = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.searchBookingStopConfirmDispatch());
        dispatch(push('/booking/' + getState().booking.product.id));
    }
}