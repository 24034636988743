import * as CONSTANT_ACTIONS from './searchConstant';

export const searchSetCurrentReference = (reference) => ({
    type: CONSTANT_ACTIONS.SEARCH_SET_CURRENT_REFERENCE,
    payload: reference
})

export const searchSetCurrentReferenceName = (name) => ({
    type: CONSTANT_ACTIONS.SEARCH_SET_CURRENT_REFERENCE_NAME,
    payload: name
})

export const searchNavigateToAddMoreStopPlaces = () => ({
    type: CONSTANT_ACTIONS.SEARCH_NAVIGATE_TO_ADD_MORE_STOP_PLACES
})

export const searchCheckBookingProductExistDispatch = () => ({
    type: CONSTANT_ACTIONS.SEARCH_CHECK_BOOKING_PRODUCT_EXIST
})

export const searchNavigateToBookingFromLocationSearchBookingDispatch = () => ({
    type: CONSTANT_ACTIONS.SEARCH_NAVIGATE_TO_BOOKING_FROM_LOCATION_SEARCH_BOOKING
})

export const searchNavigateToMapFromLocationSearchBookingDispatch = () => ({
    type: CONSTANT_ACTIONS.SEARCH_NAVIGATE_TO_MAP_FROM_LOCATION_SEARCH_BOOKING
})

export const searchBookingLocationMoreStopOnFocusDispatch = () => ({
    type: CONSTANT_ACTIONS.SEARCH_BOOKING_LOCATION_MORE_STOP_ON_FOCUS
})

export const searchBookingLocationSearchInitCheckWitchBoxWasId = () => ({
    type: CONSTANT_ACTIONS.SEARCH_BOOKING_LOCATION_SEARCH_INIT_CHECK_WITCH_BOX_WAS_ID
})

export const searchBookingLocationMoreStopPlacesExists = () => ({
    type: CONSTANT_ACTIONS.SEARCH_BOOKING_LOCATION_MORE_STOP_PLACES_EXISTS
})

export const searchBookingStopConfirmDispatch = () => ({
    type: CONSTANT_ACTIONS.SEARCH_BOOKING_STOP_CONFIRM
})