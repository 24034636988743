import React, { Component, Fragment, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '@adyen/adyen-web/dist/adyen.css';

import "./App.css";
import "../../shared-style/main.scss";
import { appAuthCheck, appDetectDesktopUser, appCheckRotation, appCheckTwa, appDetectTWA } from "../../utils/redux/actions/commonActions";
import WelcomeLoadingScreen from "../loading-screen/LoadingScreen";
import NotFound from "../not-found/not-found";
import LoadingScreen from "../../components/ui-elements/loading-screen/loading-screen";
import AuthRequired from "./auth-required";
import WelcomeRequired from "./welcome-required";
import LoadingScreenKuya from "../loading-screen/loading-screen-kuya";
import RotatePhone from "../rotate-phone/rotate-phone";
import Notification from "../notification/notification";
import InternetConnection from "../internet-connection/internet-connection";

const Welcome = lazy(() => import("../register/welcome/Welcome"));
const Tutorial = lazy(() => import("../tutorial/tutorial"));
const CheckCode = lazy(() => import("../register/CheckCode/CheckCode"));
const RegisterForm = lazy(() => import("../register/form/register-form"));
const Home = lazy(() => import("../HomePage/Home/Home"));
const News = lazy(() => import("../News/News"));
const NewsDetail = lazy(() => import("../News/NewsDetail/NewsDetail"));
const JobsHistory = lazy(() => import("../job-history/Jobs/Jobs"));
const Account = lazy(() => import("../Account/Account"));
const Profile = lazy(() => import("../Account/Profile/Profile"));
const Refer = lazy(() => import("../Account/refer/refer"));
const JobInformation = lazy(() => import("../job-history/JobInformation/JobInformation"));
const JobReceipt = lazy(() => import("../job-history/JobReceipt/JobReceipt"));
const OpenJobInfo = lazy(() => import("../job-history/Jobs/OpenJobInfo"));
const JobChatHistory = lazy(() => import("../job-history/JobChatHistory/JobChatHistory"));
const JobChatHistoryMenuPage = lazy(() => import("../job-history/JobChatHistory/JobChatHistoryMenuPage/JobChatHistoryMenuPage"));
const Booking = lazy(() => import("../Booking/Booking"));
const LocationSearch = lazy(() => import("../LocationSearch/LocationSearch"));
const LocationSearchBooking = lazy(() => import("../LocationSearchBooking/LocationSearchBooking"));
const LocationSearchMap = lazy(() => import("../LocationSearch/LocationSearchMap/LocationSearchMap"));
const BillingInformation = lazy(() => import("../Account/Billing/BillingInformation/BillingInformation"));
const BillingAddCardV2 = lazy(() => import("../Account/Billing/BillingAddCardV2/BillingAddCardV2"));
const LocationSearchBookingAddMoreStop = lazy(() =>
	import("../LocationSearchBooking/location-search-booking-add-more-stop/location-search-booking-add-more-stop"),
);
const LocationSearchBookingAddSearch = lazy(() =>
	import("../LocationSearchBooking/location-search-booking-add-search/location-search-booking-add-search"),
);
const JobMatching = lazy(() => import("../job-history/JobMatching/JobMatching"));
const TutorialSafari = lazy(() => import("../tutorial/tutorial-safari"));
const TutorialChrome = lazy(() => import("../tutorial/tutorial-chrome"));
const TutorialFirefox = lazy(() => import("../tutorial/tutorial-firefox"));
const SavedPlaces = lazy(() => import("../SavedPlaces/SavedPlaces"));
const SavedPlacesSearch = lazy(() => import("../SavedPlaces/SavedPlacesSearch/SavedPlacesSearch"));
const SavedPlacesAddress = lazy(() => import("../SavedPlaces/SavedPlacesAddress/SavedPlacesAddress"));
const Support = lazy(() => import("../support/support"));
const AboutMyKuya = lazy(() => import("../AboutMyKuya/AboutMyKuya"));
const BookingNote = lazy(() => import("../Booking/BookingNote/BookingNote"));
const ReviewBooking = lazy(() => import("../Booking/ReviewBooking/ReviewBooking"));
const HomeSubCategories = lazy(() => import("../HomePage/HomeSubCategories/HomeSubCategories"));
const VideoChat = lazy(() => import("../VideoChat/VideoChat"));
const SetLocationWithMap = lazy(() => import("../LocationSearch/LocationSearchMap/SetLocationWithMap"));
const MyJobsList = lazy(() => import("../MyJobsList/MyJobsList"));
class App extends Component {
	constructor(props) {
		super(props);

		this.props.dispatch(appAuthCheck());
		this.props.dispatch(appDetectDesktopUser());
		this.props.dispatch(appCheckTwa());
		this.props.dispatch(appDetectTWA());
	}

	componentDidMount() {
		this.props.dispatch(appCheckRotation());
	}

	fallback = () => {
		return this.props.router.location.pathname === "/home" && !this.props.goHomeFromOtherPage && !this.props.clickOneOnBottomBar ? (
			<LoadingScreenKuya />
		) : (
			<LoadingScreen />
		);
	};

	render() {
		// if (this.props.isDesktop) {
		//   alert('You should work with this application in mobile device.');
		//   return <LoadingScreenKuya/>;
		// }
		return (
			<div className="App fixed-wrapper fixed-wrapper__main">
				{this.props.loading ? <LoadingScreen /> : ""}
				{this.props.loadingKuya ? <LoadingScreenKuya /> : ""}

				{this.props.authCheck ? (
					<Fragment>
						<Suspense fallback={this.fallback()}>
							<Switch>
								<Route exact path="/" component={WelcomeLoadingScreen} />
								<Route exact path="/desktop" component={WelcomeLoadingScreen} />
								<Route
									exact
									path="/register"
									render={(props) => <WelcomeRequired auth={this.props.auth} orRender={<RegisterForm {...props} />} />}
								/>
								<Route
									exact
									path="/home"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<Home {...props} />} />}
								/>
								<Route
									exact
									path="/about-mykuya"
									render={(props) => <AboutMyKuya auth={this.props.auth} orRender={<Home {...props} />} />}
								/>
								<Route
									exact
									path="/booking/:id"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<Booking {...props} />} />}
								/>
								<Route
									exact
									path="/subcategories/:productsId"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<HomeSubCategories {...props} />} />}
								/>
								<Route
									exact
									path="/booking/:id/map"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
								/>
								<Route
									exact
									path="/booking/:id/note"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<BookingNote {...props} />} />}
								/>
								<Route
									exact
									path="/booking/:id/review"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<ReviewBooking {...props} />} />}
								/>
								<Route
									exact
									path="/search"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearch {...props} />} />}
								/>
								<Route
									exact
									path="/search/map"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
								/>
								<Route
									exact
									path="/search/:id/booking"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchBooking {...props} />} />}
								/>
								<Route
									exact
									path="/search/:id/booking/map"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
								/>
								<Route
									exact
									path="/search/:id/booking/add"
									render={(props) => (
										<AuthRequired auth={this.props.auth} orRender={<LocationSearchBookingAddMoreStop {...props} />} />
									)}
								/>
								<Route
									exact
									path="/search/:id/booking/add/:index"
									render={(props) => (
										<AuthRequired auth={this.props.auth} orRender={<LocationSearchBookingAddSearch {...props} />} />
									)}
								/>
								<Route
									exact
									path="/search/:id/booking/add/:index/map"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
								/>
								<Route
									exact
									path="/saved-places"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlaces {...props} />} />}
								/>
								<Route
									exact
									path="/saved-places/search"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlacesSearch {...props} />} />}
								/>
								<Route
									exact
									path="/saved-places/search/map"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
								/>
								<Route
									exact
									path="/saved-places/search/add"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlacesAddress {...props} />} />}
								/>
								<Route
									exact
									path="/my-jobs"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobsHistory {...props} />} />}
								/>
								<Route
									exact
									path="/jobs"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<MyJobsList {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobInformation {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/matching"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobMatching {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/receipt"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobReceipt {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/chat"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobChatHistory {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/chat/menu"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobChatHistoryMenuPage {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/chat/menu"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobChatHistoryMenuPage {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/chat/video"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<VideoChat {...props} />} />}
								/>
								<Route
									exact
									path="/jobs/:id/open-job"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<OpenJobInfo {...props} />} />}
								/>
								<Route
									exact
									path="/news"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<News {...props} />} />}
								/>
								<Route
									exact
									path="/news/:id"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<NewsDetail {...props} />} />}
								/>
								<Route
									exact
									path="/account"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<Account {...props} />} />}
								/>
								<Route
									exact
									path="/account/profile"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<Profile {...props} />} />}
								/>
								<Route
									exact
									path="/account/refer"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<Refer {...props} />} />}
								/>
								<Route
									exact
									path="/account/billing"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<BillingInformation {...props} />} />}
								/>
								<Route
									exact
									path="/account/billing/add"
									render={(props) => <AuthRequired auth={this.props.auth} orRender={<BillingAddCardV2 {...props} />} />}
								/>
								<Route exact path="/support" render={(props) => <Support {...props} />} />
								<Route exact path="/tutorial" render={(props) => <Tutorial {...props} />} />
								<Route exact path="/notification" render={(props) => <Notification {...props} />} />
								<Route exact path="/tutorial/safari" render={(props) => <TutorialSafari {...props} />} />
								<Route exact path="/tutorial/chrome" render={(props) => <TutorialChrome {...props} />} />
								<Route exact path="/tutorial/firefox" render={(props) => <TutorialFirefox {...props} />} />
								<Route path="*" component={NotFound} />
							</Switch>
						</Suspense>
					</Fragment>
				) : (
					<Fragment>
						<Suspense fallback={this.fallback()}>
							<Switch>
								{/* <LoadingScreenKuya /> */}
								<Route exact path="/map" render={(props) => <SetLocationWithMap {...props} />} />
								<Route exact path="/home" render={(props) => <Home {...props} />} />
								<Route exact path="/search" render={(props) => <LocationSearch {...props} />} />
								<Route exact path="/search/map" render={(props) => <LocationSearchMap {...props} />} />
								<Route exact path="/news" render={(props) => <News {...props} />} />
								<Route exact path="/news/:id" render={(props) => <NewsDetail {...props} />} />
								<Route exact path="/booking/:id" render={(props) => <Booking {...props} />} />
								<Route exact path="/booking/:id/map" render={(props) => <LocationSearchMap {...props} />} />
								<Route exact path="/subcategories/:productsId" render={(props) => <HomeSubCategories {...props} />} />
								<Route exact path="/booking/:id/note" render={(props) => <BookingNote {...props} />} />
								<Route exact path="/booking/:id/review" render={(props) => <ReviewBooking {...props} />} />
								<Route exact path="/about-mykuya" render={(props) => <AboutMyKuya {...props} />} />
							</Switch>
						</Suspense>
					</Fragment>
				)}
				<ToastContainer />
				{/* {this.props.isRotated ? <RotatePhone /> : ""} */}
				{this.props.isInternetDisconnected ? <InternetConnection /> : ""}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.common,
	router: state.router,
});

export default connect(mapStateToProps)(App);