import _ from 'lodash';

import * as CONSTANT_ACTIONS from './mapConstant';
import * as DISPATCH_STATE from './mapChangeState';
import { appGoHome } from '../commonActions';
import { searchSetCurrentReferenceName } from '../searchActions';
import { autocompleteSearch } from '../../../functions/nearby-search';
import { localStorageSetLatestPlace, localStorageGetLatestPlace } from '../../../functions/local-storage';
import * as LOG_EVENT from '../../../analytics';
import {
    debounceTimeSearching
} from '../../../functions/debounce';

const debounceTimeSearchingGoogle = debounceTimeSearching(CONSTANT_ACTIONS.DEBOUNCE_TIME_SEARCHING_GOOGLE);


/**
 * Fetch google search places for when user search via search box in location search component for showing to user
 * @param {*} keyword 
 */
export function fetchSearchPlacesGoogle(keyword) {
    return (dispatch, getState) => {
        if (keyword === '') {
            dispatch(DISPATCH_STATE.mapSearchPlacesFetch([]))
        } else {
            if (keyword.length > CONSTANT_ACTIONS.MIN_KEYWORD_LENGTH_FOR_GOOGLE_SUGGESTION) {
                debounceTimeSearchingGoogle(() => {
                    autocompleteSearch(getState, keyword, CONSTANT_ACTIONS.GOOGLE_PLACES_NEARBY_SEARCH_RADIUS, (rawResults, status) => {
                        let results = [];
                        if (status === 'OK') {
                            dispatch(DISPATCH_STATE.mapSearchPlacesFetch(rawResults));
                            localStorageSetLatestPlace(JSON.stringify(rawResults));
                            //get detail of each place via place_id
                            // rawResults.map((result) => {
                            //     getDetailPlaces(getState, result.place_id, (result, status) => {
                            //         results.push(result);
                            //         dispatch(DISPATCH_STATE.mapSearchPlacesFetch(results));
                            //         localStorageSetLatestPlace(JSON.stringify(results));
                            //         LOG_EVENT.logEvent(LOG_EVENT.GOOGLE_PLACE_SERVICE_TEXT_BY_SEARCH_CWA);
                            //     });
                            // });
                        } else {
                            console.error('GOOGLE_PLACE_SERVICE_TEXT_BY_SEARCH_CWA', results, status);
                        }
                        LOG_EVENT.logEvent(LOG_EVENT.GOOGLE_PLACE_SERVICE_TEXT_BY_SEARCH_CWA);
                    });
                });
            }
        }
    };
}

/**
 * Fetch google places search result for suggesting first time
 * @param {*} location 
 */
export function fetchSearchPlacesGoogleInit() {
    return (dispatch, getState) => {
        if (localStorageGetLatestPlace()) {
            const results = localStorageGetLatestPlace();
            dispatch(DISPATCH_STATE.mapSearchPlacesFetch(JSON.parse(results)));
        } else {
            return false;
        }
    };
}
/**
 * set place for source or destinations
 * @param {*} params 
 */
export const setGooglePlace = (params) => {
    return (dispatch, getState) => {
        if (_.isEmpty(params)) {
            dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.location));
        } else if (_.has(params, 'id') && !_.has(params, 'index')) {
            let name = getState().search.currentRef.current.name;
            dispatch(searchSetCurrentReferenceName(name));
            if (name === "source") {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.location));
            } else if (_.has(getState().map.googlePlaces, 0)) {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.googlePlaces[ 0 ]));
            } else {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.location));
            }
        } else if (_.has(params, 'index')) {
            let index = params[ 'index' ];
            if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.location));
            } else if (index >= CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.googlePlaces[ index - 1 ]));
            } else if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
                dispatch(DISPATCH_STATE.setGooglePlaceObject(getState().map.location));
            }
        }
    }
}
/**
 * Check map was intitilize or not
 */
export const checkShouldMapShow = () => {
    return (dispatch, getState) => {
        if (!getState().map.initMap) {
            dispatch(appGoHome());
        }
    }
}
/**
 * Remove specific step from places
 * @param {*} i 
 */
export const removeStepFromPlaces = (i) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            let steps = getState().map.googlePlaces;
            _.pullAt(steps, [ i ]);
            dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(steps));
            resolve();
        })
    }
}
/**
 * Remove destination
 * @param {*} i 
 */
export const removeDestinationPlace = () => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            if (_.has(getState().map.googlePlaces, 0)) {
                let steps = getState().map.googlePlaces;
                _.pullAt(steps, [ 0 ]);
                dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(steps));
                resolve();
            }
        })
    }
}

/**
 * update GoogleSearchPlaces when bookmark saved place 
 */
export const updateGoogleSearchPlaces = (index) => {
    return (dispatch, getState) => {
        const googleSearchPlace = getState().map.googleSearchPlaces;
        if (index > -1) {
            googleSearchPlace.splice(index, 1);
        }
        dispatch(DISPATCH_STATE.mapSearchPlacesFetch(googleSearchPlace));
        localStorageSetLatestPlace(JSON.stringify(googleSearchPlace));
    }
}

export const updatePickupLocation = (pickupLocation) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(DISPATCH_STATE.setPickupLocationToMap(pickupLocation));
            resolve();
        })
    }
}