import _ from 'lodash';

/**
 * check activation of user
 * @param {*} client 
 */
export function isUserinActive(client) {
    if (_.has(client, 'state') && client.state === 'Inactive') {
        return true;
    } else {
        return false;
    }
}
/**
 * check activation of user
 * @param {*} client 
 */
export function isUserinBanned(client) {
    if (_.has(client, 'state') && client.state === 'Banned') {
        return true;
    } else {
        return false;
    }
}