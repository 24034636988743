import $ from 'jquery';
import _ from "lodash";
import Video from "twilio-video";
import { push } from "connected-react-router";
import { toast } from "react-toastify";	

import * as DISPATCH_STATE from '../chatChangeState';
import { requestPriceExtendBooking } from "../../bookingActions";
import { GraphRequest } from "../../../../../axios";
import { appLoadingBegin, appLoadingEnd } from "../../commonActions/commonChangeState";
/**
 * When user click on option menu in chat page
 */
export const onOpenMenuOption = () => {
    return (dispatch, getState) => {
        if (getState().chat.optionMenu) {
            $('.Job-chat-history-menu').fadeOut();
          } else {
            $('.Job-chat-history-menu').fadeIn();
          }
        dispatch(DISPATCH_STATE.chatOnOpenMenuOption());
    }
}
/**
 * When user click on extend job on menu option in chat page
 */
export const onExtendJob = () => {
	return (dispatch, getState) => {
        if (!getState().chat.chatExtendModal) {
            document.getElementsByTagName("body")[0].classList.add("fixed-body")
        } else {
            document.getElementsByTagName("body")[0].classList.remove("fixed-body");

        }
        if (!getState().chat.chatExtendModal && _.isEmpty(getState().booking.prices)) {
            dispatch(requestPriceExtendBooking(getState().jobs.job.product.id, getState().jobs.job.jobLocations));
        }
        dispatch(DISPATCH_STATE.onExtendJob());
    }
}
/**
 * When user click on call partner on menu option in chat page
 */
export const onCallPartner = () => {
    return (dispatch, getState) => {
        window.open(`tel:${getState().jobs.job.partner.mobileNumber.fullNumber}`)
        dispatch(DISPATCH_STATE.chatOnCallPartner());
    }
}

export const checkVoipIsSupported = (tokenType) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.checkVoipIsSupportedBegin());
		dispatch(push("/jobs/" + getState().jobs.job.id + "/chat" + "/video"));
		dispatch(generatePeersToken(tokenType));
        dispatch(DISPATCH_STATE.checkVoipIsSupportedEnd());
    }
}

export const generatePeersToken = (tokenType) => {
	return async (dispatch, getState) => {
		dispatch(appLoadingBegin());
		dispatch(DISPATCH_STATE.generatePeersTokenBegin());
		const generatePeersTokenRequestBody = {
			query: `mutation GenerateToken($channelId: Int!, $tokenType: ChatCallTokenTypeEnum) {
                createCallPeersTokens(channelId:$channelId, tokenType: $tokenType) {
                    token
                    expiresAt
                }
            }`,
			variables: {
				channelId: getState().jobs.job.id,
				tokenType: tokenType,
			},
		};
		const { data } = await GraphRequest.all(generatePeersTokenRequestBody);
		try {
			if (!_.isNull(data.data) && data.data.createCallPeersTokens) {
				dispatch(DISPATCH_STATE.initPeersToken(data.data.createCallPeersTokens));
				dispatch(DISPATCH_STATE.generatePeersTokenSuccessful());
				dispatch(connectToRoom(data.data.createCallPeersTokens, tokenType));
				dispatch(DISPATCH_STATE.setTokenTypeForVoip(tokenType));
			}
		} catch {}
	};
};

export const joinToVideoChat = (jobId, tokenPayload, tokenType) => {
	return (dispatch, getState)	=> {
        dispatch(appLoadingBegin());
		dispatch(DISPATCH_STATE.generatePeersTokenBegin());
		dispatch(push("/jobs/" + jobId + "/chat" + "/video"));
		dispatch(connectToRoom(tokenPayload, tokenType));
    }
};
let previewTracks;

export const connectToRoom = (data, tokenType) => {
	return (dispatch, getState) => {
		const { token, expiresAt } = !_.isNull(getState().chat.videoCallData) ? getState().chat.videoCallData : data;
		let connectOptions = {
			name: tokenType === "VIDEO_CHAT_TOKEN" ? `VIDEO_CHAT_${getState().jobs.job.id}` : `VOICE_CHAT_${getState().jobs.job.id}`,
			audio: true,
			video: tokenType === "VIDEO_CHAT_TOKEN" ? true : false,
			dominantSpeaker: true
		};
		if (previewTracks) {
			connectOptions.tracks = previewTracks;
		}
		Video.connect(token, connectOptions).then(
			(room) => {
				dispatch(roomJoined(room));
			},
			(error) => {
				toast(`Unable to connect to Room: ${error.message}`, {
					position: "bottom-center",
					autoClose: 5000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
				dispatch(backToChatHistory(getState().jobs.job.id));
			},
		);
	};
};
export const roomJoined = (room) => {
    return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.setRoomInfo(room));
		
		if (getState().chat.tokenType === "VIDEO_CHAT_TOKEN") {
			navigator.mediaDevices.enumerateDevices().then((res) => {
				// dispatch(gotDevices(res))
			});
		}
		// Attach LocalParticipant's Tracks, if not already attached.
		var previewContainer = document.getElementById("local-media-container-id");
		if (!previewContainer.querySelector("video")) {
			attachParticipantTracks(room.localParticipant, previewContainer);
		}
		// Attach the Tracks of the Room's Participants.
		room.participants.forEach(function (participant) {
			var previewContainer = document.getElementById("remote-media-div");
			attachParticipantTracks(participant, previewContainer);
		});

		// Attach the Tracks of the Room's Participants.
		room.participants.forEach(function (participant) {
			// console.log("Already in Room: '" + participant.identity + "'");
			dispatch(DISPATCH_STATE.partnerJoined());
			// var previewContainer = document.getElementById('remote-media');
			// attachParticipantTracks(participant, previewContainer);
		});

		// When a Participant joins the Room, log the event.
		room.on("participantConnected", function (participant) {
			dispatch(DISPATCH_STATE.partnerJoined());
		});

		// When a Participant adds a Track, attach it to the DOM.
		room.on("trackSubscribed", function (track, trackPublication, participant) {
			var previewContainer = document.getElementById("remote-media-div");
			attachTracks([track], previewContainer);
		});

		// When a Participant removes a Track, detach it from the DOM.
		room.on("trackUnsubscribed", function (track, trackPublication, participant) {
			detachTracks([track]);
		});
	

		room.on('dominantSpeakerChanged', participant => {
			// console.log('The new dominant speaker in the Room is:', participant);
		});

		// When a Participant leaves the Room, detach its Tracks.
		room.on('participantDisconnected', function (participant) {
			// console.log("Participant '" + participant.identity + "' left the room", 
			// dispatch(DISPATCH_STATE.partnerNotJoined());
			// dispatch(backToChatHistory(!_.isEmpty(getState().jobs.job) ? getState().jobs.job.id : getState().chat.activeRoom.name));
			// detachParticipantTracks(participant);
		});

		// Once the LocalParticipant leaves the room, detach the Tracks
		// of all Participants, including that of the LocalParticipant.
		room.on("disconnected", function (room, error) {
			if(!_.isUndefined(error) && error.name === "TwilioError" && error.message === "Room completed") {
				dispatch(push("/jobs/" + Number(getState().jobs.job.id) + "/chat"));
			}
			toast(`Call ended`, {
				position: "bottom-center",
				autoClose: 3000,
				limit: 1,
				className: "toast-rejected-payment",
				bodyClassName: "toastify-inner",
				hideProgressBar: true,
				closeOnClick: true,
			});
			dispatch(DISPATCH_STATE.partnerNotJoined());
			if (previewTracks) {
				previewTracks.forEach(function (track) {
					track.stop();
				});
			}
			detachParticipantTracks(room.localParticipant);
			room.participants.forEach(detachParticipantTracks);
		});

		dispatch(appLoadingEnd());
	}
}

function attachParticipantTracks(participant, container) {
	var tracks = Array.from(participant.tracks.values()).map(function (trackPublication) {
		return trackPublication.track;
	});
	attachTracks(tracks, container);
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
  var tracks = Array.from(participant.tracks.values()).map(function(
    trackPublication
  ) {
    return trackPublication.track;
  });
  detachTracks(tracks);
}

export const updateVideoDevice = (event) => {
	return (dispatch, getState) => {
		const activeRoom = getState().chat.activeRoom;
		const select = event.target;
		const localParticipant = activeRoom.localParticipant;
		if (select.value !== "") {
			const tracks = Array.from(localParticipant.videoTracks.values()).map(function (trackPublication) {
				return trackPublication.track;
			});
			localParticipant.unpublishTracks(tracks);
			
			detachTracks(tracks);
			stopTracks(tracks);
			Video.createLocalVideoTrack({
				deviceId: { exact: select.value },
			}).then(function (localVideoTrack) {
				localParticipant.publishTrack(localVideoTrack);
				const previewContainer = document.getElementById("local-media-container-id");
				attachTracks([localVideoTrack], previewContainer);
			});
		}
	}
}

function attachTracks(tracks, container) {
	tracks.forEach(function (track) {
		if (track) {
			container.appendChild(track.attach());
		}
	});
}

export const disconnectRoom = (room) => {
   return (dispatch, getState) => {
		room.localParticipant.tracks.forEach((publication) => {
            publication.track.stop();
			const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
            if(!getState().chat.toggleVoice) dispatch(DISPATCH_STATE.toggleVoiceOnVideoChat());
            if (!getState().chat.toggleVideo) dispatch(DISPATCH_STATE.toggleVideoOnVideoChat());
		});
	    dispatch(DISPATCH_STATE.partnerNotJoined());
        room.disconnect();
   }; 
}

// Detach the Tracks from the DOM.
function detachTracks(tracks) {
  tracks.forEach(function(track) {
    if (track) {
      track.detach().forEach(function(detachedElement) {
        detachedElement.remove();
      });
    }
  });
}

export const backToChatHistory = (jobId) => {
    return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.backToChatHistory());
		dispatch(endCall(getState().chat.activeRoom.name));
        dispatch(push("/jobs/" + Number(jobId) + "/chat"));
    }
}

export const endCall = (roomName) => {
	return async (dispatch, getState) => {
		dispatch(DISPATCH_STATE.endCall());
		const endCallRequestBody = {
			query: `mutation CompleteCallRoom($roomName: String!) {
				completeCallRoom(roomName: $roomName) {
					succeed
					result
					details
				}
			}`,
			variables: {
				roomName: roomName ? roomName : getState().chat.activeRoom,
			},
		};
		const { data } = await GraphRequest.all(endCallRequestBody);
		try {
			// console.log("endCall", data.data.completeCallRoom.result);
		} catch {}
	};
};

export const toggleVoiceOnVideoChat = () => {
    return (dispatch, getState) => {
        const room = getState().chat.activeRoom;
        if (getState().chat.toggleVoice) {
            dispatch(DISPATCH_STATE.toggleVoiceOnVideoChat());
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.disable();
            });
        } else {
            dispatch(DISPATCH_STATE.toggleVoiceOnVideoChat());
            room.localParticipant.audioTracks.forEach((publication) => {
				publication.track.enable();
			});
        }
    }
}

export const toggleCameraOnVideoChat = () => {
	return (dispatch, getState) => {
		const video = document.getElementById("local-media-container-id");
		const select = document.getElementById("video-devices");

		const videoConstraints = {};
		// if (select.value === "") {
			videoConstraints.facingMode = "environment";
		// } else {
		// 	videoConstraints.deviceId = { exact: select.value };
		// }
		const constraints = {
			video: videoConstraints,
			audio: false,
		};
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				video.srcObject = stream;
				return navigator.mediaDevices.enumerateDevices();
			})
			.then((res) => {
				// dispatch(gotDevices(res));
			})
			.catch((error) => {
				console.error(error);
			});
    };
};

export const gotDevices = (mediaDevices) => {
	return (dispatch, getState) => {
		const select = document.getElementById("video-devices");
		select.innerHTML = "";
		select.appendChild(document.createElement("option"));
		let count = 1;
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === "videoinput") {
				const option = document.createElement("option");
				option.value = mediaDevice.deviceId;
				const label = mediaDevice.label || `Camera ${count++}`;
				const textNode = document.createTextNode(label);
				option.appendChild(textNode);
				select.appendChild(option);
			}
		});
	};
};

export const stopTracks = (tracks) => {
	tracks.forEach(function (track) {
		if (track) {
			track.stop();
		}
	});
};