import { goBack, push } from 'connected-react-router';

import * as DISPATCH_STATE from '../commonChangeState';
import { JOB_STATE_COMPLETED, myJobsSetSearchLocationPointer } from "../../jobsActions";
import buttonTimeout from '../../../../functions/button-timeout';

export function appAuthRedirectCheck(history, auth) {
    return dispatch => {
        dispatch(DISPATCH_STATE.appAuthRedirect());
        history.push("/home");
        // if (auth) {
        //     history.push('/home');
        // } else {
        //     history.push('/welcome');
        // }
    };
}
/**
 * When go back in application
 */
export function appGoBack(backUrl = null) {
    return (dispatch) => {
        if (!backUrl) {
            dispatch(goBack());
        } else {
            dispatch(push(backUrl));
        }
    }
}
/**
 * When go back job information
 */
export function appGoBackJobInformation() { 
    return (dispatch, getState) => {
        if (getState().jobs.job.state === JOB_STATE_COMPLETED) {
            dispatch(push('/jobs/' + getState().jobs.job.id));
        } else if (getState().jobs.searchPointer) {
            dispatch(push(getState().jobs.searchPointer));
            dispatch(myJobsSetSearchLocationPointer(null));
        } else {
            dispatch(push("/jobs"));
        }
    }
}
/**
 * When go back in application
 */
export function appGoHome() {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.appGoHomeCheck());
        dispatch(push('/home'));
    }
}
/**
 * When go welcome
 */
export function appGoToWelcome() {
    return (dispatch) => {
        dispatch(push('/welcome'));
    }
}
/**
 * Navigate by click on bottom bar link
 * @param {*} url 
 */
export function appGoPushBottomBar(url) {
    return (dispatch, getState) => {
        if ((url === "/jobs" || url === "/account") && !getState().common.auth && !getState().common.authCheck) {
			dispatch(DISPATCH_STATE.toggleLogInBottomSheet());
		} else {
			dispatch(DISPATCH_STATE.appUserClickOnBottomBarLink());
			buttonTimeout(() => {
				dispatch(push(url));
			});
		}

    }
}
    