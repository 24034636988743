export const getMessagesWithOffsetRequestBody = `query ChatMessages($channelId: Int!, $limit: Int, $offset: Int) {
  chatMessages(channelId: $channelId, limit: $limit, offset: $offset) {
    totalItems
    requestId
    lastConsumeIndex
    items {
      index
      text
      media
      type
      consumedBy
      createdAt
      authorId
      authorType
      tokenPayload {
        token
        expiresAt
      }
    }
  }
}`;

export const getMessageWithLastIndexRequestBody = `query ChatMessages($channelId: Int!, $lastIndex: Int) {
  chatMessages(channelId: $channelId, lastIndex: $lastIndex) {
    totalItems
    requestId
    lastConsumeIndex
    items {
      index
      text
      media
      type
      consumedBy
      createdAt
      authorId
      authorType
      tokenPayload {
        token
        expiresAt
      }
    }
  }
}`;

export const sendTextMessageRequestBody = `mutation CreateChatMessage($channelId: Int!, $text: String!) {
  chatSendText(channelId: $channelId, text: $text) {
    index
    text
    media
    type
    consumedBy
    authorId
    authorType
    createdAt
  }
}`;

export const sendMediaMessageRequestBody = `mutation CreateChatMessage($channelId: Int!,$text: String, $file: Upload) {
  chatSendFile(channelId: $channelId, text: $text, file: $file) {
    index
    text
    media
    type
    consumedBy
    authorId
    authorType
    createdAt
  }
}`;