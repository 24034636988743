import * as CONSTANT_ACTIONS from './chatConstant';

export const initJobChatChannelName = channel => ({
    type: CONSTANT_ACTIONS.INIT_JOB_CHAT_CHANNEL_NAME,
    payload: channel
});
/**
 * Chat will unmount
 */
export const chatWillUnmount = () => ({
    type: CONSTANT_ACTIONS.CHAT_WILL_UNMOUNT
})
/**
 * When user click on cancel on menu option in chat page
 */
export const onCancelJob = () => {
    return (dispatch) => {
        dispatch({
            type: CONSTANT_ACTIONS.CHAT_ON_CANCEL_JOB
        })
    }
}
/**
 * When user click on nevermind on menu option in chat page
 */
export const onNeverMind = () => {
    return (dispatch) => {
        dispatch({
            type: CONSTANT_ACTIONS.CHAT_ON_NEVERMIND_CANCEL
        })
    }
}
export const noteToPartner = () => {
	return (dispatch) => {
		dispatch({
			type: CONSTANT_ACTIONS.CHAT_NOTE_TO_PARTNER
		})
	}
}
/**
 * When user click on extend job on menu option in chat page
 */
export const onExtendJob = () => ({
	type: CONSTANT_ACTIONS.CHAT_ON_EXTEND_JOB,
});
/**
 * When click on a photo in chat list
 * @param {*} photo 
 */
export const onShowPhoto = (photo) => {
    return (dispatch) => {
        dispatch({
            type: CONSTANT_ACTIONS.CHAT_ON_PHOTO_MESSAGE,
            payload: photo
        });
    }
}
/**
 * When click somewhere on screen for close image show
 */
export const closePhotoShow = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_CLOSE_PHOTO_MESSAGE
})

export const chatScrollToBottomDispatch = () => ({
    type: CONSTANT_ACTIONS.CHAT_SCROLL_TO_BOTTOM
})

export const chatSendMessageToChannelRoom = () => ({
    type: CONSTANT_ACTIONS.CHAT_SEND_MESSAGE_TO_CHANNEL_ROOM
})

export const chatPutMessageToMessagesArray = (messages) => ({
    type: CONSTANT_ACTIONS.CHAT_PUT_MESSAGE_TO_MESSAGES_ARRAY,
    payload: messages
})

export const initMessagesFromTwilioFirstTimeDispatch = (messages) => ({
    type: CONSTANT_ACTIONS.INIT_MESSAGES_FROM_TWILIO_FIRST_TIME,
    payload: messages
})

export const chatInitPagingFunctionalityDispatch = (paging) => ({
    type: CONSTANT_ACTIONS.CHAT_INIT_PAGING_FUNCTIONALITY,
    payload: paging
})

export const initMessagesFromJobChatHistoryDispatch = (messages) => ({
    type: CONSTANT_ACTIONS.INIT_MESSAGES_FROM_JOB_CHAT_HISTORY,
    payload: messages
})

export const chatConnectToAvailableRoomDispatch = (channel) => ({
    type: CONSTANT_ACTIONS.CHAT_CONNECT_TO_AVAILABLE_ROOM,
    payload: channel
})

export const chatOnAttachFileDispatch = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_ATTACH_FILE,
})

export const chatOnOpenMenuOption = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_OPEN_MENU_OPTION,
})
/**
 * When user click job detail on option menu in chat page
 */
export const onJobDetail = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_JOB_DETAIL_NAVIGATION,
})

export const chatOnLiveLocationPartner = (interval) => ({
    type: CONSTANT_ACTIONS.CHAT_ON_LIVE_LOCATION_PARTNER,
    payload: interval
})

export const chatOnLiveLocationPartnerClose = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE
})

export const chatOnCallPartner = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_CALL_PARTNER
})

export const chatOnNevermindExtend = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_NEVERMIND_EXTEND
})

export const requestJobCancelBegin = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_BEGIN
})

export const requestJobCancelSuccess = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_SUCCESS
})

export const chatPartnerIsTypingStart = () => ({
    type: CONSTANT_ACTIONS.CHAT_PARTNER_IS_TYPING_START
})

export const chatPartnerIsTypingEnd = () => ({
    type: CONSTANT_ACTIONS.CHAT_PARTNER_IS_TYPING_END
})

export const initMessagesDispatch = (messages) => ({
    type: CONSTANT_ACTIONS.INIT_MESSAGES_DISPATCH,
    payload: messages
})

export const updateMessageList = (messages) => ({
    type: CONSTANT_ACTIONS.UPDATE_MESSAGE_LIST,
    payload: messages
})

export const initIntervalGetMessage = (interval) => ({
    type: CONSTANT_ACTIONS.INIT_INTERVAL_GET_MESSAGE,
    payload: interval
})

export const getMessageWillUnMountDispatch = () => ({
    type: CONSTANT_ACTIONS.GET_MESSAGE_WILL_UNMOUNT_DISPATCH
})

export const setLastIndex = (lastIndex) => ({
    type: CONSTANT_ACTIONS.SET_LAST_INDEX,
    payload: lastIndex
})

export const setLastConsumeIndex = (lastConsumedIndex) => ({
    type: CONSTANT_ACTIONS.SET_LAST_CONSUME_INDEX,
    payload: lastConsumedIndex
})

export const setOffset = (offset) => ({
    type: CONSTANT_ACTIONS.SET_OFFSET,
    payload: offset
})

export const setLimit = (limit) => ({
    type: CONSTANT_ACTIONS.SET_LIMIT,
    payload: limit
})

export const fetchMessagesSuccess = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_SUCCESS,
    payload: data
})

export const sendMessageBegin = () => ({
    type: CONSTANT_ACTIONS.SEND_MESSAGE_BEGIN
})

export const putNewMessageToMessagesArray = (messages) => ({
    type: CONSTANT_ACTIONS.PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY,
    payload: messages
})

export const chatOnAttachFileNewServiceDispatch = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_ATTACH_FILE_NEW_SERVICE_DISPATCH
})

export const fetchMessageBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_BEGIN
})

export const fetchMessageEnd = () => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_END
})

export const emptyNewMessages = () => ({
    type: CONSTANT_ACTIONS.EMPTY_NEW_MESSAGES
});

export const checkVoipIsSupportedBegin = () => ({
    type: CONSTANT_ACTIONS.CHECK_VOIP_IS_SUPPORTED_BEGIN
});

export const checkVoipIsSupportedEnd = () => ({
	type: CONSTANT_ACTIONS.CHECK_VOIP_IS_SUPPORTED_END,
});

export const generatePeersTokenBegin = () => ({
    type: CONSTANT_ACTIONS.GENERATE_PEERS_TOKEN_BEGIN
});

export const generatePeersTokenSuccessful = () => ({
    type: CONSTANT_ACTIONS.GENERATE_PEERS_TOKEN_SUCCESSFUL
});

export const initPeersToken = (data) => ({
    type: CONSTANT_ACTIONS.INIT_PEERS_TOKEN,
    payload: data
});

export const setRoomInfo = (roomName) => ({
    type: CONSTANT_ACTIONS.SET_ROOM_INFO,
    payload: roomName
});

export const participantJoinedToRoom = () => ({
    type: CONSTANT_ACTIONS.PARTICIPANT_JOINED_TO_ROOM
});

export const participantLeftRoom = () => ({
    type: CONSTANT_ACTIONS.PARTICIPANT_Left_ROOM
});

export const backToChatHistory = () => ({
    type: CONSTANT_ACTIONS.BACK_TO_CHAT_HISTORY
});

export const toggleVoiceOnVideoChat = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_VOICE_ON_VIDEO_CHAT
});

export const toggleVideoOnVideoChat = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_VIDEO_ON_VIDEO_CHAT,
});

export const setTokenTypeForVoip = (tokenType) => ({
    type: CONSTANT_ACTIONS.SET_TOKEN_TYPE_FOR_VOIP,
    payload: tokenType
});

export const addPartnerInfo = (data) => ({
    type: CONSTANT_ACTIONS.ADD_PARTNER_INFO,
    payload: data
});

export const partnerJoined = () => ({
    type: CONSTANT_ACTIONS.PARTNER_JOINED
});

export const partnerNotJoined = () => ({
    type: CONSTANT_ACTIONS.PARTNER_NOT_JOINED
});

export const endCall = () => ({
    type: CONSTANT_ACTIONS.END_CALL
})