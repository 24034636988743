import {
    push
} from 'connected-react-router';

import * as DISPATCH_STATE from './registerChangeState';

/**
 * Back to welcome page
 */
export const registerBackToWelcome = () => {
    return (dispatch) => {
        dispatch(push('/welcome'));
        dispatch(DISPATCH_STATE.registerBackToWelcomeDispatch());
    }
};