export const APP_LOADING_BEGIN = 'APP_LOADING_BEGIN'; // for loading spinner show
export const APP_LOADING_END = 'APP_LOADING_END'; // for loading spinner hidden
export const APP_LOADING_KUYA_BEGIN = 'APP_LOADING_KUYA_BEGIN'; // for loading screen kuya show
export const APP_LOADING_KUYA_END = 'APP_LOADING_KUYA_END'; // for loading screen kuya hidden
export const APP_AUTH_CHECK_BEGIN = 'APP_AUTH_CHECK_BEGIN';
export const APP_AUTH_CHECK_PASSED = 'APP_AUTH_CHECK_PASSED';
export const APP_INITIALIZE_COMMON = 'APP_INITIALIZE_COMMON';
export const APP_AUTH_CHECK_FAILED = 'APP_AUTH_CHECK_FAILED';
export const APP_AUTH_CHECK_END = 'APP_AUTH_CHECK_END';
export const APP_AUTH_CHECK = 'APP_AUTH_CHECK'; // put auth true in common reducer
export const APP_FETCH_FAILURE = 'APP_FETCH_FAILURE';
export const APP_AUTH_REDIRECTION_PATH = '/welcome';
export const APP_LOADING_PATH = '/';
export const APP_AUTH_REDIRECT = 'APP_AUTH_REDIRECT';
export const APP_AUTH_GET_CLIENT = 'APP_AUTH_GET_CLIENT';
export const APP_AUTH_GET_TWILIO_TOKEN = 'APP_AUTH_GET_TWILIO_TOKEN'; // get token from server for init Twilio
export const APP_GET_CLIENT = 'APP_GET_CLIENT'; // get client
export const APP_PROFILE_FETCH_CLIENT_BOUNDARY = 'APP_PROFILE_FETCH_CLIENT_BOUNDARY';
export const APP_PROFILE_LOGOUT = 'APP_PROFILE_LOGOUT';
export const APP_SET_LOCALSTORAGE = 'APP_SET_LOCALSTORAGE';
export const APP_GO_BACK = 'APP_GO_BACK'; // action when user go back in application in previous page or state
export const APP_INIT_TWILIO = 'APP_INIT_TWILIO'; // Action when app.js inititialize for first time
export const APP_GO_HOME_CHECK = 'APP_GO_HOME_CHECK'; // user go home from other pages
export const APP_DETECT_DESKTOP_USER = 'APP_DETECT_DESKTOP_USER'; // detect user device type
export const APP_DETECT_ROTATION_DEVICE = 'APP_DETECT_ROTATION_DEVICE';
export const APP_INTERNET_IS_DISCONNECTED = 'APP_INTERNET_IS_DISCONNECTED'; // when internet is disconnected
export const WRONG_VERIFICATION_CODE = 'WRONG_VERIFICATION_CODE'; //when user enter wrong verification code
export const APP_INTERNET_TRY_AGAIN = 'APP_INTERNET_TRY_AGAIN'; // try again for check connection
export const APP_USER_CLICK_ON_BOTTOM_BAR_LINK = 'APP_USER_CLICK_ON_BOTTOM_BAR_LINK';
export const CLIENT_HAS_NO_PAYMENT_METHOD = "CLIENT_HAS_NO_PAYMENT_METHOD";
export const STORE_BOOKING_URL = "STORE_BOOKING_URL"; //store booking route
export const GET_PRIMARY_PAYMENT = "GET_PRIMARY_PAYMENT";
export const SELECT_TAB = "SELECT_TAB";
export const RESET_WRONG_VERIFICATION_CODE_STATUS = 'RESET_WRONG_VERIFICATION_CODE_STATUS'; //when user back to welcome isWrongVerification code should be change
export const CHANGE_TIME_FORMAT = "CHANGE_TIME_FORMAT";
export const SET_CLIENT_HAS_NO_PAYMENT_METHOD_FALSE = "SET_CLIENT_HAS_NO_PAYMENT_METHOD_FALSE";

export const APP_MATERIAL_BUTTON_TIMEOUT = 190;

export const RETURN_ERROR_FROM_ADYEN = "RETURN_ERROR_FROM_ADYEN";
export const LOCATION_IS_CHANGED = "LOCATION_IS_CHANGED";
export const SET_LOCATION_POINTER = "SET_LOCATION_POINTER";
export const SET_CURRENT_BALANCE = "SET_CURRENT_BALANCE";
export const INIT_INTERVAL_CHECK_ACCOUNT_BALANCE = "INIT_INTERVAL_CHECK_ACCOUNT_BALANCE";
export const CLEAR_INTERVAL_CHECK_ACCOUNT_BALANCE = "CLEAR_INTERVAL_CHECK_ACCOUNT_BALANCE";
export const SET_ACCOUNTING_REQUEST_COUNT = "SET_ACCOUNTING_REQUEST_COUNT";
export const TOGGLE_CHAT_WITH_SUPPORT_BOTTOM_SHEET = "TOGGLE_CHAT_WITH_SUPPORT_BOTTOM_SHEET";
export const TIME_FOR_INTERVAL_REQUEST_FOR_ACCOUNT_BALANCE = 2000;

export const NAVIGATE_TO_SET_LOCATION = "NAVIGATE_TO_SET_LOCATION";
export const SET_LOCATION_SUCCESSFULLLY = "SET_LOCATION_SUCCESSFULLLY";
export const TOGGLE_LOG_IN_BOTTOM_SHEET = "TOGGLE_LOG_IN_BOTTOM_SHEET";
export const SAVE_LAST_PATH_NAME = "SAVE_LAST_PATH_NAME"