export const GEOLOCATION_ERROR_TEXT_NOT_SUPPORT = 'Geolocation is not supported by your browser';

export function navigatorGetCurrentLocation(callback, dispatch, endLoading) {
    navigator.geolocation.getCurrentPosition(
        callback,
        err => {
          alert(`Error (${err.code}): ${getPositionErrorMessage(err.code)}`);
          dispatch(endLoading());
        }
    );
}

export function getLocationFromPostionNavigator(position) {
    return {
        lat: position.coords.latitude,
        lng: position.coords.longitude 
    };
}

export function geolocationError() {
    alert(GEOLOCATION_ERROR_TEXT_NOT_SUPPORT);
    return new Error(GEOLOCATION_ERROR_TEXT_NOT_SUPPORT);
}

/**
 * Check get current position error user faced with
 * @param {*} code 
 */
export function getPositionErrorMessage(code) {
    switch (code) {
      case 1:
        return 'Your device is Permission denied, please choose your location on map';
      case 2:
        return 'Position unavailable.';
      case 3:
        return 'Timeout reached.';
      default :
        return 'Cant access';
    }
}