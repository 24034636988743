import * as CONSTANT_ACTIONS from './savedPlacesConstant';

/**
 * set map for saved place
 */
export const savedPlaceSelectMap = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_SELECT_MAP
});
/**
 * set location pointer 
 */
export const savedPlaceSetSearchLocationPointer = (pathname) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_SET_SEARCH_LOCATION,
    payload: pathname
});
/**
 * unset location pointer
 */
export const savedPlaceUnSetSearchLocationPointer = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_UNSET_SEARCH_LOCATION,
});
/**
 * select on place for useing search
 */
export const savedPlaceSelectPlace = (place) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_CLICK_SELECTED_PLACE,
    payload: place
});
/**
 * clear selected place
 */
export const savedPlaceClearSelectedPlace = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_CLEAR_SELECTED_PLACE
});

/**
 * set map for saved place
 */
export const savedPlaceUnSelectMap = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_UNSELECT_MAP
});
/**
 * set type before add address 
 * @param {*} type 
 */
export const savedPlacesSetType = (type) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_SET_TYPE,
    payload: type
})
/**
 * clear some state after saving
 */
export const clearSavedPlaceAndType = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_CLEAR_TYPE_AND_PLACE
})
/**
 * delete place modal from saved places
 * @param {*} place 
 */
export const deletePlaceOfSavedPlaces = (place) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_WANTED_TO_DELETE_PLACE,
    payload: place
})

export const selectSavedPlacesAddress = (place) => ({
    type: CONSTANT_ACTIONS.SELECT_SAVED_PLACES_ADDRESS,
    payload: place
})

export const savedPlacesFetchAll = (places) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_FETCH_ALL,
    payload: places
})

export const savedPlacesFindHomeWork = (places) => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_FIND_HOME_WORK,
    payload: places
})

export const savedPlacesSetNullWantedToDeletePlace = () => ({
    type: CONSTANT_ACTIONS.SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE
});

export const undoRemoveBookmarkSavedPlace = (place) => ({
    type: CONSTANT_ACTIONS.UNDO_REMOVE_BOOKMARK_SAVED_PLACE,
    payload: place
});

export const emptyTempStoredBookmarked = () => ({
    type: CONSTANT_ACTIONS.EMPTY_TEMP_STORED_BOOKMARKED,
});