export const LOCATION_INPUT_BOX_NAME_DESTINATION = "destination";
export const LOCATION_INPUT_BOX_NAME_SOURCE = "source";
export const LOCATION_INPUT_BOX_NAME_SECOND_SOURCE = "second-source";
export const HomeNavigateRows = [
	{
		id: 0,
		title: "Account",
		description: "Your MyKuya profile and preferences",
		icon: "Account",
		navigateLink: "/account",
	},
	{
		id: 1,
		title: "MyJobs",
		description: "All of the jobs you have booked",
		icon: "Bag",
		navigateLink: "/jobs",
	},
	{
		id: 2,
		title: "What’s New?",
		description: "Latest announcements, promos, etc.",
		icon: "Announce",
		navigateLink: "/news",
	},
	{ id: 3, title: "About MyKuya", description: "#YourHelpingHandOnDemand", icon: "MyKuya", navigateLink: "/about-mykuya" },
];