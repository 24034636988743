import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../jobsChangeState';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import { fetchAllJobs, fetchJobsQuery } from "../jobs-gql";
/**
 * Fetch list of jobs from server
 */
export function fetchJobs() {
    return dispatch => {
        dispatch(appLoadingBegin());
        dispatch(DISPATCH_STATE.fetchJobsBegin());
        const getJobsHistoryRequestBody =  {
            query: fetchAllJobs, 
            variables: {
                "offset": 0,
                "limit": 10
            }
        };
        GraphRequest.all(getJobsHistoryRequestBody)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.requests) {
                    const { totalItems } = json.data.data.requests;
                    if(totalItems === 0) {
                        const data = {
                            all: { items: [] },
                            active: { items: [] },
                            completed: { items: [] },
                            upcoming: { items: [] },
                            open: { items: [] },
                            totalItems: totalItems
                        }
                        dispatch(DISPATCH_STATE.fetchJobsSuccess(data));
                        dispatch(DISPATCH_STATE.jobsActiveCount(0))
                    } else {
                        const clonnedRequests = [...json.data.data.requests.items];
                        const activeJobs = clonnedRequests.filter((request) => request.state === "Matched" || request.state === "Started");
                        const completedJobs = clonnedRequests.filter((request) => request.state === "Completed");
                        const openJobs = clonnedRequests.filter(
                            (request) => request.state === "Queued" || request.state === "Scheduled" || request.state === "Matching",
                        );
                        const upcomingJobs = clonnedRequests.filter((request) =>  request.state === "Upcoming")
                        let data = {
                            all: { items: [...json.data.data.requests.items] },
                            active: { items: [...activeJobs] },
                            completed: { items: [...completedJobs] },
                            upcoming: { items: [...upcomingJobs] },
                            open: { items: [...openJobs] },
                            totalItems: json.data.data.requests.totalItems,
                            totalActive: activeJobs.length,
                        };
                        dispatch(DISPATCH_STATE.fetchJobsSuccess(data));
                        dispatch(DISPATCH_STATE.jobsActiveCount(activeJobs.length));
                        dispatch(DISPATCH_STATE.jobsActive(activeJobs));
                    }
					dispatch(appLoadingEnd());
				} else {
                    dispatch(appFetchFailure(json.data.errors[0].message));
                    toast(`${json.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                }
            })
            .catch(error => dispatch(appFetchFailure(error)));
    };
}
/**
 * Fetch list of jobs from server by limit
 */
export function fetchJobsByOffset() {
    return (dispatch, getState) => {
        if(getState().jobs.jobs.all !== undefined){
            const totalOfJobsExistInArray = getState().jobs.jobs.all.items.length;
			if (getState().jobs.jobs.totalItems > totalOfJobsExistInArray) {
				dispatch(appLoadingBegin());
                dispatch(DISPATCH_STATE.fetchJobsBegin());
                const getJobsHistoryRequestBody = {
                    query: fetchAllJobs,
                    variables: {
                        "offset": totalOfJobsExistInArray,
                        "limit": 10
                    }
                };
                GraphRequest.all(getJobsHistoryRequestBody)
					.then(json => {
                        if (!_.isNull(json.data.data) && json.data.data.requests) {
							const clonnedRequests = [...json.data.data.requests.items];
                            const completedJobs = clonnedRequests.filter((request) => request.state === "Completed");
                            const openJobs = clonnedRequests.filter(
								(request) => request.state === "Queued" || request.state === "Scheduled" || request.state === "Matching",
                            );
                            const upcomingJobs = clonnedRequests.filter((request) => request.state === "Upcoming");

							let data = {
								all: { items: [...getState().jobs.jobs.all.items, ...json.data.data.requests.items] },
								completed: { items: [...getState().jobs.jobs.completed.items, ...completedJobs] },
								active: { items: [...getState().jobs.jobs.active.items] },
								open: { items: [...getState().jobs.jobs.open.items, ...openJobs] },
								upcoming:
									getState().jobs.jobs.upcoming.items.length > 0
										? { items: [...getState().jobs.jobs.upcoming.items, ...upcomingJobs] }
										: { items: [...upcomingJobs] },
								totalItems: getState().jobs.jobs.totalItems,
								totalActive: getState().jobs.jobs.totalActive,
							};
							dispatch(DISPATCH_STATE.fetchJobsSuccess(data));
							dispatch(appLoadingEnd());
						} else {
                            dispatch(appFetchFailure(json.data.errors[0].message));
                            toast(`${json.data.errors[0].message}`, {
                                position: "bottom-center",
                                autoClose: 2000,
                                limit: 1,
                                className: "toast-rejected-payment",
                                bodyClassName: "toastify-inner",
                                hideProgressBar: true,
                                closeOnClick: false,
                            });
                            dispatch(appLoadingEnd());
                        }
					})
					.catch(error => dispatch(appFetchFailure(error)));
			}
        }
    };
}

export function fetchOpenJobs() {
	return (dispatch) => {
		dispatch(appLoadingBegin());
		dispatch(DISPATCH_STATE.fetchOpenJobsBegin());
		const fetchQueuedJobsRequestBody = {
			query: fetchJobsQuery,
		};
        
        GraphRequest.all(fetchQueuedJobsRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data) {
                    dispatch(DISPATCH_STATE.fetchOpenJobsSuccessfully(res.data.data));
                    dispatch(appLoadingEnd());
                }
            });
	};
}