import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import { GraphRequest } from '../../../../../axios';
import { appLoadingBegin, appFetchFailure, appSetLocalStorage, appAuthCheck, toggleLogInBottomSheet } from "../../commonActions";

/**
 * check code
 */
export function checkVerificationCodeAction() {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const checkVerificationCodeRequestBody = {
            query: `mutation CheckVerificationCode($input: ClientMobileVerificationInput!) {
                checkVerificationCode(data: $input) {
                    succeed
                    token
                    username
                    result
                }
            }`,
            variables: {
                "input": initObjCodeVerification(getState)
            }
        }
        GraphRequest.all(checkVerificationCodeRequestBody)
            .then(json => {
                if (json.data.data.checkVerificationCode.succeed) {
                    dispatch(appSetLocalStorage(json.data.data.checkVerificationCode));
                    dispatch(toggleLogInBottomSheet());
                    dispatch(registerCheckClientExist());
                } else {
                    dispatch(appFetchFailure(json.data.data.checkVerificationCode)) 
                }
            });
        dispatch(DISPATCH_STATE.registerCheckVerificationCode());
    }
}
/**
 * check client exist or not
 */
const registerCheckClientExist = () => {
    return (dispatch) => {
        const clientRequestBody = {
			query: `query Client {
                client {
                    id
                    givenName
                    lastName
                    emailAddress
                    mobileNumber {
                    number
                    fullNumber
                    countryCode {
                        icon
                        code
                    }
                }
                employerCompany
                refereeCode
                referralCode
                currency {
                    unit
                    countryCode
                    locale
                    signText
                    signSvg
                }
                livingCity {
                    id
                    displayValue
                }
                workingCity {
                    id
                    displayValue
                }
                state
                }
            }`,
		};
        GraphRequest.all(clientRequestBody)
        .then(json => {
            if (!_.isNull(json.data.data) && json.data.data.client) {
                dispatch(checkRedirectToRegister(json.data.data.client));
            } else {
                dispatch(appFetchFailure(json.data.errors[0].message));
            }
        });
        dispatch(DISPATCH_STATE.registerCheckClientExistDispatch());
    }
}
/**
 * Create obj for code verification for sending to server
 * @param {*} getState 
 */
const initObjCodeVerification = (getState) => {
    let obj = {
        "mobileNumber": {
            "number": parseInt(getState().register.number),
            "countryCode": {
                "code": getState().register.countrySelected.code,
                "icon": "🇵🇭"
            }
        },
        "verificationCode": getState().register.code
    }
    return obj;
}
/**
 * Check authority of client
 * @param {*} client 
 */
export const checkRedirectToRegister = (client) => {
    return (dispatch) => {
        dispatch(appAuthCheck(client));
        dispatch(DISPATCH_STATE.registerCheckRedirectToRegisterPage());
    }
}