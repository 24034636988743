import _ from 'lodash';
import * as DISPATCH_STATE from '../bookingChangeState';
import {push} from "connected-react-router";
import { toast } from "react-toastify";

import { jobMatchingWillUnMount, unsetMatchingRequest, initCancelRequestCountDown, updateJobByOpenStatus } from "../../jobsActions";
import { createQueuedRequest, createScheduledRequest } from "../booking-gql";
import {createScheduledObj} from "../../../../functions/create-booking-request-object";
import { GraphRequest } from "../../../../../axios";
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { setSelectedTabIndex } from "../../myJobsActions";
import * as Constant from "../../myJobsActions/myJobsConstant";
 
export const checkKeepLooking = () => {
    return (dispatch, getState) => {
        const queued = true;
        if(queued) {
            dispatch(jobMatchingWillUnMount());
            dispatch(DISPATCH_STATE.openQueuedJobBottomSheet());

        }
    }
}

export const setQueuedJobTime = (time) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.setQueuedJobTime(time));
    }
}

export const postQueuedRequest = () => {
    return (dispatch, getState) => {
        const {queuedJobTime} = getState().booking;
        dispatch(unsetMatchingRequest());
        dispatch(initCancelRequestCountDown());
        dispatch(DISPATCH_STATE.closeQueuedJobBottomSheet());
        const createQueuedJobRequestBody = {
			query: createQueuedRequest,
			variables: {
				requestData: {
                    dueDate: queuedJobTime,
                    requestId: getState().jobs.job.id
				},
			},
        };
        dispatch(appLoadingBegin());
        GraphRequest.all(createQueuedJobRequestBody)
            .then((res) => {
                if (res.data.data && res.data.data.queueRequest) {
                    dispatch(updateJobByOpenStatus(res.data.data.queueRequest));
                    dispatch(DISPATCH_STATE.toggleRequestPlacedModal());
					dispatch(push("/jobs"));
					dispatch(setSelectedTabIndex(2, Constant.Open));
					dispatch(appLoadingEnd());
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 3000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                }
            });
    }
}

export const setDefaultScheduleDate = (today) => {
    return (dispatch, getState) => {
        const calculatedTime = calculateHalfTime(today);
        let changedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), calculatedTime.h, calculatedTime.m, 0);
        dispatch(DISPATCH_STATE.dispatchSetDefaultScheduleDate(changedDate));
    };
};

export const setLimitationScheduleDate = (today) => {
    return (dispatch, getState) => {
        const lastMidnight = new Date(new Date(today).setHours(24, 0, 0));
        dispatch(setDefaultScheduleDate(lastMidnight));
        dispatch(DISPATCH_STATE.setMinDate(new Date(lastMidnight)));
        dispatch(DISPATCH_STATE.setMaxDate(new Date(lastMidnight.getTime() + 31 * 24 * 60 * 60 * 1000)));
    }
}

export const calculateHalfTime = (today) => {
    let minutes = today.getMinutes();
    let hours = today.getHours();
    return {
        
        m : (parseInt((minutes + 20) / 30) * 30) % 60,
        h : minutes > 30 ? (hours === 23 ? 0 : ++hours) : hours,
    }
}

export const requestScheduledBooking = (dueDate) => {
    return (dispatch, getState) => {
        dispatch(unsetMatchingRequest());
        dispatch(initCancelRequestCountDown());
        const createScheduledRequestRequestBody = {
			query: createScheduledRequest,
			variables: {
				requestData: createScheduledObj(getState, dueDate, getState().booking.jobLocations),
			},
		};
        dispatch(appLoadingBegin());
		GraphRequest.all(createScheduledRequestRequestBody).then((res) => {
            if (res.data.data && res.data.data.createScheduleRequest) {
                dispatch(updateJobByOpenStatus(res.data.data.createScheduleRequest));
                dispatch(DISPATCH_STATE.toggleRequestPlacedModal());
				dispatch(push("/jobs"));
                dispatch(setSelectedTabIndex(2, Constant.Open));
				dispatch(appLoadingEnd());
            } else {
                dispatch(appFetchFailure(res.data.errors[0].message));
                toast(`${res.data.errors[0].message}`, {
                    position: "bottom-center",
                    autoClose: 3000,
                    limit: 1,
                    className: "toast-rejected-payment",
                    bodyClassName: "toastify-inner",
                    hideProgressBar: true,
                    closeOnClick: false,
                });
                dispatch(appLoadingEnd());
            }
		});
    }
}