import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import * as CONSTANT_ACTIONS from '../chatConstant';
import { GraphRequest } from '../../../../../axios';
import { createMarkerPartnerLocation, removeMarkerForSelectingLocation } from '../../mapActions';
import { appFetchFailure } from '../../commonActions';

/**
 * When user click live partner location on option menu in chat page
 */
export const onLivePartnerLocation = (jobId) => {
    return (dispatch, getState) => {
        const getLocationrequestBody = {
			query: `query GetRequest($requestId: Int!) {
                request(requestId: $requestId) {
                    partnerLifeCycle {
                        location {
                            lat
                            lng
                        }
                    }
                }}`,
			variables: {
				requestId: Number(jobId),
			},
		};
        dispatch(DISPATCH_STATE.chatOnLiveLocationPartner(setInterval(() => {
            GraphRequest.all(getLocationrequestBody)
                .then((res) => {
					if (!_.isNull(res.data.data) && res.data.data.request.partnerLifeCycle) {
					    dispatch(createMarkerPartnerLocation(res.data.data.request.partnerLifeCycle.location));
					}
				}).catch((error) => dispatch(appFetchFailure(error)));
        }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_LOCATION_PARTNER)));
    }
}
/**
 * When user click close live partner location on option menu in chat page
 */
export const onCloseLivePartnerLocation = () => {
    return (dispatch, getState) => {
        dispatch(removeMarkerForSelectingLocation());
        clearInterval(getState().chat.liveInternal);
        dispatch(DISPATCH_STATE.chatOnLiveLocationPartnerClose());
    }
}