import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from "react-toastify";	
import AdyenCheckout from "@adyen/adyen-web";

import * as DISPATCH_STATE from './billingChangeState';
import { GraphRequest } from '../../../../axios';
import {
    appLoadingBegin,
    appLoadingEnd,
    appFetchFailure,
    fetchClient,
    returnErrorFromAdyen,
    clientHasNoPaymentMethod
} from '../commonActions';
import * as LOG_EVENT from '../../../analytics';
import { storeBookingUrl, setclientHasNoPaymentMethodFalse, getPrimaryPayment } from "../commonActions/commonChangeState";
import { togglePaymentMethodsBottomSheet, redirectClientToAdyenForTopUp, setDefaultValueRejectedPayment, checkRejectedPayment, checkRetryWithCard, fetchPrimaryPayment } from "../bookingActions";
import { adyenPaymentMethods, addPaymentMethod, add3dSecurePaymentMethod, paymentAndBalance } from "./billing-gql";
/**
 * when user click on add payment method in choose payment page
 */
export const billingOnAddPaymentMethodButton = () => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.billingOnAddPaymentMethodsInChoosePage());
        dispatch(push('/account/billing/add'));
    }
}
/**
 * Navigate user to account/billing
 */
export const navigateToAccountBilling = () => {
    return (dispatch) => {
        dispatch(push('/account/billing'));
    }
}
/**
 * Fetch list of payments methods from server
 */
export function fetchMethodsOfPayment() {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        return new Promise((resolve, reject) => {
            if (!_.isEmpty(getState().common.client)) {
                const payments = {
                    query: paymentAndBalance,
                    variables: {
                        unit: getState().common.client.currency.unit,
                    },
                };

                GraphRequest.all(payments)
                    .then(json => {
                        dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(json.data.data.paymentMethods.items));
                        dispatch(DISPATCH_STATE.fetchAccountingBalanceSuccess(json.data.data.balances));
                        resolve(json);
                        dispatch(appLoadingEnd());
                        if (json.data.data.paymentMethods.totalItems === 0) {
                            if (json.data.data.balances[0].balance < 0) {
                                dispatch(checkRejectedPayment());
                            } else if (json.data.data.balances[0].balance == 0) {
                                dispatch(clientHasNoPaymentMethod());
                            }
                        }
                    })
                    .catch(error => dispatch(appFetchFailure(error)));
            } else {
                dispatch(fetchClient())
                    .then((res) => {
                        const payments = {
                            query: paymentAndBalance,
                            variables: {
                                unit: res.currency.unit,
                            },
                        };

                        GraphRequest.all(payments)
                            .then(json => {
                                dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(json.data.data.paymentMethods.items));
                                dispatch(DISPATCH_STATE.fetchAccountingBalanceSuccess(json.data.data.balances));
                                resolve(json);
                                dispatch(appLoadingEnd());
                                if (json.data.data.paymentMethods.totalItems === 0) {
                                    if (json.data.data.balances[0].balance < 0) {
                                        dispatch(checkRejectedPayment());
                                    } else if (json.data.data.balances[0].balance == 0) {
                                        dispatch(clientHasNoPaymentMethod());
                                    }
                                }
                            })
                            .catch(error => dispatch(appFetchFailure(error)));
                    })
            }
        })
    };
}
/**
 * Request for change primary
 */
export function billingRequestForChagnePrimary(id) {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const setPrimaryRequestBody = {
            query: `mutation PrimaryPayment($id: String!) {
                primaryPaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                }
            }`,
            variables: { "id": id },
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(setPrimaryRequestBody)
                .then(json => {
                    // if (!_.isNull(json.data.data) && json.data.data.primaryPaymentMethod.succeed) { 
                    //     dispatch(checkRetryWithCard());
                    // } else {
                    //     toast(`${json.data.errors[0].message}`, {
                    //         position: "bottom-center",
                    //         autoClose: 5000,
                    //         limit: 1,
                    //         className: "toast-rejected-payment",
                    //         bodyClassName: "toastify-inner",
                    //         hideProgressBar: true,
                    //         closeOnClick: false,
                    //     });
                    //     dispatch(appFetchFailure(json.data.errors[0].message));
                    // }

                    if (!_.isNull(json.data.data) && json.data.data.primaryPaymentMethod.succeed) {
                        dispatch(checkRetryWithCard());
                        dispatch(fetchPrimaryPayment());
                        if (!_.isNull(getState().booking.retryWithCard) && getState().booking.retryWithCard) {
                            dispatch(billingRequestPaymentCheckout())
                                .then(() => {
                                    dispatch(togglePaymentMethodsBottomSheet());
                                    dispatch(fetchClient());
                                    dispatch(setDefaultValueRejectedPayment());
                                    //dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(json));
                                    //dispatch(changePrimaryPayment(primaryPayment));
                                    //dispatch(appLoadingEnd());
                                    toast("Checkout is done", {
                                        position: "bottom-center",
                                        autoClose: 5000,
                                        limit: 1,
                                        className: "toast-rejected-payment",
                                        bodyClassName: "toastify-inner",
                                        hideProgressBar: true,
                                        closeOnClick: false,
                                    });
                                })
                        } else {
                            dispatch(appLoadingEnd());
                        }
                    } else {
                        dispatch(appFetchFailure(json.data.errors[0].message));
                    }


                    resolve();
                });
        })
    };
}
/**
 * Request for change primary
 */
export function billingDeleteRequestPaymentMethod(id) {
    return (dispatch) => {
        dispatch(appLoadingBegin());
        dispatch(DISPATCH_STATE.billingPaymentMethodEmpty());
        const deletePaymentRequestBody = {
            query: `mutation DeletePayment($id: String!) {
                deletePaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                }
            }`,
            variables: { "id": id }
        }
        GraphRequest.all(deletePaymentRequestBody)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.deletePaymentMethod.succeed) {
                    dispatch(fetchMethodsOfPayment());
                    dispatch(getPrimaryPayment(null));
                } else if (!json.data.data.deletePaymentMethod.succeed) {
                    dispatch(fetchMethodsOfPayment());
                    dispatch(DISPATCH_STATE.deletePaymentErrorText(json.data.data.deletePaymentMethod.result))
                } else {
                    dispatch(appFetchFailure(json.data.data));
                }
            })
            .catch(error => dispatch(appFetchFailure(error)));
    };
}
/**
 * Request for payment checkout 
 */
export function billingRequestPaymentCheckout() {
    return (dispatch) => {
        dispatch(appLoadingBegin());
        return new Promise((resolve, reject) => {
            const checkoutRequestBody = {
                query: `mutation ClientCheckout {
                    checkoutPayment {
                        id
                        state
                    }
                }`
            }
            GraphRequest.all(checkoutRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.checkoutPayment) {
                    dispatch(fetchMethodsOfPayment());
                    dispatch(setDefaultValueRejectedPayment());
                    toast("Checkout is done", {
                        position: "bottom-center",
                        autoClose: 5000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                } else if (res.data.errors) {
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appFetchFailure(res.data.errors[0].message));
                }
            });
        })
    };
}
/**
 * Initilize adyen script to end of page whether does not exist 
 */
export function initAdyen() {
    return (dispatch, getState) => {
        var form = document.getElementById('adyen-encrypted-form');
        let options = {};
        var encryptedBlobFieldName = "adyen-encrypted-data";

        // options.name = encryptedBlobFieldName;
        options.onsubmit = (e) => {
            var encryptedData = form.elements[encryptedBlobFieldName].value;
            e.preventDefault();
            dispatch(appLoadingBegin());
            GraphRequest.all(encryptedData)
                .then((res) => {
                    dispatch(appLoadingEnd());
                    LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                }).catch((e) => {
                    dispatch(DISPATCH_STATE.billingErrorPayment());
                    dispatch(appFetchFailure());
                });
        };
        let ady = window.adyen.createEncryptedForm(form, options);
        dispatch(DISPATCH_STATE.billingSetAdyen(ady))
    }
}

var card = null;
export const availableMethods = () => {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        return new Promise((resolve) => {
            if (_.isEmpty(getState().common.client)) {
                dispatch(fetchClient())
                    .then((res) => {
                        const requestBody = {
                            query: adyenPaymentMethods,
                            variables: {
                                unit: res.currency.unit,
                                channel: "Web",
                            },
                        };
                        GraphRequest.all(requestBody)
                            .then((res) => {
                                resolve(res)
                            })
                    })
            } else {
                const requestBody = {
                    query: adyenPaymentMethods,
                    variables: {
                        unit: getState().common.client.currency.unit,
                        channel: "Web",
                    },
                };
                return GraphRequest.all(requestBody)
                    .then((res) => {
                        resolve(res)
                    })
            }
        }) 
    }
}
/**
 * Initilize adyen script to end of page whether does not exist 
 */
export function initAdyenV2(methods = {}) {
    return (dispatch, getState) => {
        dispatch(appLoadingEnd());
        const configuration = {
            locale: "en_US", // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/checkout/components-web/localization-components.
            environment: process.env.REACT_APP_ADYEN_ENV, // When you're ready to accept live payments, change the value to one of our live environments https://docs.adyen.com/checkout/components-web#testing-your-integration.  
            // originKey: process.env.REACT_APP_ADYEN_ORIGIN_KEY,
            clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY, 
            paymentMethodsResponse: methods,
            onSubmit: handleOnSubmit,
            // onChange: handleOnChange, // Your function for handling onChange event
        };
        const checkout = new AdyenCheckout(configuration);
        card = checkout.create('card', {
            billingAddressRequired: true,
            holderNameRequired: true,
            hasHolderName: true,
            enableStoreDetails: false,
            showPayButton: false,
            onAdditionalDetails: handleOnAdditionalDetails,
        }).mount('#component-container');

        function handleOnAdditionalDetails(state, component) {
            dispatch(appLoadingBegin());
            dispatch(checkAddCard3DSecure(state));
        }

        function handleOnSubmit(state, component) {
            dispatch(appLoadingBegin());
            dispatch(addCardV2(state));
        }
        return card;
    }
}

const addCardV2 = (state) => {
    return (dispatch, getState) => {
        delete state.data.clientStateDataIndicator;
        if (state.data.paymentMethod) {
            delete state.data.paymentMethod.brand;
        }
        const requestBody = {
			query: addPaymentMethod,
			variables: {
				paymentCard: state.data,
				unit: getState().common.client.currency.unit,
				shopperRedirectUrl: !_.isNull(getState().common.pathName)
					? `${window.location.origin}${getState().common.pathName}`
					: `${window.location.origin}/account/billing`, 
			},
		};
        GraphRequest.all(requestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.addAdyenPaymentMethod.succeed)  {
                    dispatch(appLoadingEnd());
                    LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    res.data.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(setclientHasNoPaymentMethodFalse());
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                } else {
                    if (_.has(res.data.data.addAdyenPaymentMethod, 'details')) {
                        const details = JSON.parse(res.data.data.addAdyenPaymentMethod.details)
                        if( _.has(details, 'action')) {
                            LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                            res.data.data.addAdyenPaymentMethod.details.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                            dispatch(handleAction(details));
                        } else if (_.has(details, 'refusalReasonCode')) {
                            dispatch(DISPATCH_STATE.billingErrorPayment(res.data.data.addAdyenPaymentMethod.details.description));
                            dispatch(appLoadingEnd());
                        }
                    } 
                }
            });
    }
}

const checkAddCard3DSecure = (state) => { 
    return (dispatch, getState) => {
        const requestBody = {
            query: add3dSecurePaymentMethod,
            variables: {
                "paymentCard": state.data
            }
        }
        GraphRequest.all(requestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.addAdyenSecurePaymentMethod.succeed) {
                    dispatch(appLoadingEnd());
                    LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                } else {
                    if (_.has(res.data.data.addAdyenSecurePaymentMethod, 'details')) {
                        const details = JSON.parse(res.data.data.addAdyenSecurePaymentMethod.details)
                        if (_.has(details, 'action')) {
                            LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                            res.data.data.addAdyenSecurePaymentMethod.details.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                            dispatch(handleAction(res.data.data.addAdyenSecurePaymentMethod));
                        } else if (_.has(details, 'refusalReasonCode')) {
                            dispatch(DISPATCH_STATE.billingErrorPayment(res.data.data.addAdyenSecurePaymentMethod.details.description));
                            dispatch(appLoadingEnd());
                        }
                    }
                }
            });
    }
}

const handleAction = (data) => {
    return (dispatch, getState) => {
        function handleOnAdditionalDetails(state, component) {
            dispatch(appLoadingBegin());
            dispatch(checkAddCard3DSecure(state));
        }

        const configuration = {
            // locale: "en_US",
            environment: process.env.REACT_APP_ADYEN_ENV,
            // originKey: process.env.REACT_APP_ADYEN_ORIGIN_KEY,
            clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY || "test_QY2O45V2QVDSTGALEVNZNFEJPQ32NBIQ",
            onAdditionalDetails: handleOnAdditionalDetails,
            // onChange: handleOnChange,
            // onError: onError,
        };

        const checkout = new AdyenCheckout(configuration);
        // checkout.createFromAction(data.details.action).mount('#component-container');
        checkout.createFromAction(data.action).mount('#component-container');
    }
};

export const billingTopUpMyKoin = (amount, url, unit) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.billingTopUpMyKoin());
        dispatch(appLoadingBegin());
        // let returnUrl = `${url}/account/billing?topUp=${amount}`;
        let returnUrl = window.location.host === "localhost:3000" ? 
            `http://${window.location.host}/account/billing?topUp=${amount}` : 
            `https://${window.location.host}/account/billing?topUp=${amount}`;

        const topUprequestBody = {
            query: `mutation Topup($topUp: ClientTopUpMykoinInput!) {
                topUpMykoinMethod(topUp: $topUp) {
                    url
                }
            }`,
            variables: { 
                "topUp" : {
                    amount: amount,
                    returnUrl: returnUrl,
                    unit: unit ? unit : getState().common.client.currency.unit
                }
            }
        }
        GraphRequest.all(topUprequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.topUpMykoinMethod) {
                    dispatch(redirectClientToAdyenForTopUp());
                    window.location.replace(res.data.data.topUpMykoinMethod.url);
                    dispatch(appLoadingEnd());
                } else if (res.data.errors) {
                    dispatch(returnErrorFromAdyen(res.data.errors[0].message));
                    dispatch(appLoadingEnd());
                }
            });
    }
};

export const fetchPaymentMethods = () => {
    return (dispatch, getState) => {
        const getPaymentsRequestBody = {
            query: `query PaymentMethods {
                paymentMethods {
                    totalItems
                    items {
                        id
                        type
                        icon
                        name
                        value
                        isPrimary
                        cardNumber
                        expirationMonth
                    }
            }}`
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(getPaymentsRequestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.paymentMethods) {
                        dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(res.data.data.paymentMethods.items));
                        resolve(res.data.data.paymentMethods)
                    }
                })
        })

    }
};

export const fetchBalances = () => {
    return (dispatch, getState) => {
        const getBalanceRequestBody = {
			query: `query GetBalance($unit: String) {
                balances(unit: $unit) {
                    unit
                    balance
                    rate
                    displayValue
                    currencySign
                }
            }`,
			variables: {
				unit: getState().common.client.currency.unit,
			},
        };
        dispatch(appLoadingBegin());
        GraphRequest.all(getBalanceRequestBody)
            .then((res) => {
                if(res.data.data && res.data.data.balances) {
                    dispatch(DISPATCH_STATE.setClientBalances(res.data.data.balances));
                    dispatch(appLoadingEnd());
                }
            })
    }
}