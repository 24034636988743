import {
	SET_SELECTED_TAB_INDEX,
	FETCH_OPEN_JOBS_SUCCESSFULLY,
	NO_OPEN_JOBS,
	FETCH_UPCOMING_JOBS,
	FETCH_ALL_JOBS,
	FETCH_COMPLETED_JOBS,
	FETCH_ACTIVE_JOBS,
	UNMOUNT_JOBS_LIST,
} from "../actions/myJobsActions";

import * as Constant from "../actions/myJobsActions/myJobsConstant";

const initialState = {
    selectedIndex: 0,
    selectedTab: Constant.All,
    openJobs: null,
    upcomingJobs: null,
    allJobs: null,
    completedJobs: null,
    activeJobs: null
};

export default function myJobsReducer(state = initialState, action) {
	switch (action.type) {
        case UNMOUNT_JOBS_LIST:
            return {
				...state,
				selectedIndex: 0,
				selectedTab: Constant.All,
				openJobs: null,
				upcomingJobs: null,
				allJobs: null,
				completedJobs: null,
				activeJobs: null,
			};
        case SET_SELECTED_TAB_INDEX:
            return {
				...state,
                selectedIndex: action.payload.index,
                selectedTab: action.payload.tabName
            };
        case FETCH_OPEN_JOBS_SUCCESSFULLY:
            return {
                ...state,
                openJobs: action.payload
            }
        case FETCH_UPCOMING_JOBS:
            return {
                ...state,
                upcomingJobs: action.payload
            }
        case FETCH_ALL_JOBS:
            return {
                ...state,
                allJobs: action.payload
            }
        case NO_OPEN_JOBS:
            return {
                ...state,
                openJobs: null
            }
        case  FETCH_COMPLETED_JOBS:
            return {
                ...state,
                completedJobs: action.payload
            }
        case  FETCH_ACTIVE_JOBS:
            return {
                ...state,
                activeJobs: action.payload
            }
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
