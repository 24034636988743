import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import {
    initMessagesFromTwilioFirstTime, updateMemberMessageReadStatus, putMessageToMessagesFromChannelLive, chatScrollToBottom,
    initMessages, paginateMessageList
} from './chat-messages';
import * as CONSTANT_ACTIONS from '../chatConstant';
import { chatInitPagingFunctionality } from './chat-paging';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from '../../commonActions';
import { MESSAGE_STATUS_RECEIVED } from '../../../../functions/message-object-creator';
import {JOB_STATE_COMPLETED} from '../../jobsActions/jobsConstant';
import { getMessagesWithOffsetRequestBody, getMessageWithLastIndexRequestBody } from "./chat-gql";
/**
 * connect to available room
 */
export const chatConnectToAvailableRoom = () => {
    return (dispatch, getState) => {
        getState().common.twilio.getChannelByUniqueName(getState().chat.channel.uniqueName)
            .then((channel) => {
                dispatch(DISPATCH_STATE.chatConnectToAvailableRoomDispatch(channel));
                dispatch(channelGetMessages(channel));
                dispatch(channelMessageAdd(channel));
                dispatch(channelTyping(channel));
                dispatch(channelMemberUpdated(channel));
            }).catch((e) => {
                // check for refresh page when twilsock is happened
                if (e.message === "Can't connect to twilsock"
                    || e.message === "Twilsock: request timeout"
                    || e.message === "Access forbidden for identity"
                ) {
                    window.location.reload();
                } else {
                    dispatch(chatConnectToAvailableRoom());
                }
                console.error(e);
            });
    }
}
/**
 * Get messages from channel twilio
 * @param {*} channel 
 */
const channelGetMessages = (channel) => {
    return (dispatch, getState) => {
        channel.getMessages().then((res) => {
            dispatch(initMessagesFromTwilioFirstTime(res[ 'items' ]));
            dispatch(chatInitPagingFunctionality(res));
            dispatch(channelConsumedAllMessage(channel));
        });
    }
}
/**
 * Handling Typing event
 * @param {*} channel 
 */
const channelTyping = (channel) => {
    return (dispatch, getState) => {
        channel.on('typingStarted', (member) => {
            dispatch(DISPATCH_STATE.chatPartnerIsTypingStart());
        });
        channel.on('typingEnded', (member) => {
            dispatch(DISPATCH_STATE.chatPartnerIsTypingEnd());
        });
    }
}
/**
 * listen on message is added to channel room
 * @param {*} channel 
 */
const channelMessageAdd = (channel) => {
    return (dispatch, getState) => {
        channel.on('messageAdded', function (message) {
            dispatch(putMessageToMessagesFromChannelLive(message)).then(() => dispatch(chatScrollToBottom()));
            dispatch(channelConsumedAllMessage(channel));
        });
    }
}
/**
 * listen on message is added to channel room
 * @param {*} channel 
 */
const channelMemberUpdated = (channel) => {
    return (dispatch, getState) => {
        channel.on('memberUpdated', function (data) {
            dispatch(getLastConsumedMessageIndex(channel))
        });
    }
}
/**
 * set messages on recieved state
 * @param {*} channel 
 */
const channelConsumedAllMessage = (channel) => {
    return (dispatch, getState) => {
        channel.setAllMessagesConsumed().then(() => {
            dispatch(getLastConsumedMessageIndex(channel));
        });
    }
}
/**
 * change state of message to recieved by last index of partner user
 * @param {*} channel 
 */
export const getLastConsumedMessageIndex = (channel) => {
    return (dispatch, getState) => {
        let members = channel.getMembers();
        members.then((currentMembers) => {
            currentMembers.forEach(function (member) {
                if (parseInt(getState().common.client.id) !== parseInt(member.identity)) {
                    dispatch(updateMemberMessageReadStatus(member.lastConsumedMessageIndex))
                }
            });
        });
    }
}

/**
 * Get all messages for the first time
 */
export const getAllMessages = (channelId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const offset = 0;
            const limit = 15; 
            const chatRequestBody = {
                query: getMessagesWithOffsetRequestBody,
                variables: {
                    "channelId": Number(channelId),
                    "offset": offset,
                    "limit": limit
                }
            }
            if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
                GraphRequest.all(chatRequestBody)
                    .then(res => {
                        if (!_.isNull(res.data.data) && res.data.data.chatMessages) {
                            if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
                                dispatch(setParams(res.data.data.chatMessages.totalItems, res.data.data.chatMessages.lastConsumeIndex));
                                dispatch(fetchMessagesWithOffset(channelId));
                                resolve();
                            } 
                            if(res.data.statusCode && res.data.statusCode === 401) {                        
                                console.log(res.data.statusCode + res.data.error);
                            }
                        }
                    }).catch((err) => console.log('error',err));
            } else {
                const chatRequestBody = {
                    query: getMessagesWithOffsetRequestBody,
                    variables: {
                        "channelId": Number(channelId)
                    }
                }
                GraphRequest.all(chatRequestBody)
                    .then((res) => {
                        if (!_.isNull(res.data.data) && res.data.data.chatMessages) {
                            dispatch(initMessages(res.data.data.chatMessages))
                        }
                    })
                    .then(() => dispatch(chatScrollToBottom()))
            }
        });
    }
}
/**
 * Fetch and store messages in the newMessages
 */
export const fetchMessagesWithOffset = (channelId) => {
    return (dispatch, getState) => {
        const offset = getState().chat.offset;
        const limit = getState().chat.limit;
        const chatRequestBody = {
            query: getMessagesWithOffsetRequestBody,
            variables: {
                "channelId": Number(channelId),
                "offset": offset,
                "limit": limit
            }
        }
        GraphRequest.all(chatRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.chatMessages) {
                    dispatch(initMessages(res.data.data.chatMessages))
                }
            })
            .then(() => dispatch(chatScrollToBottom()))
    }
}
/**
 * Store offset, limit, lastIndex 
 */
export const setParams = (totalItems, lastConsumeIndex) => {
    return (dispatch, getState) => {
        let offset;
        let limit;
        const lastIndex = totalItems - 1;
        if (totalItems < 15) {
            offset = 0;
            limit = totalItems
        } else {
            offset = totalItems - 15;
            limit = 15;
        }
        dispatch(DISPATCH_STATE.setOffset(offset));
        dispatch(DISPATCH_STATE.setLimit(limit));
        dispatch(DISPATCH_STATE.setLastIndex(lastIndex));
        dispatch(DISPATCH_STATE.setLastConsumeIndex(lastConsumeIndex));
    }
}
/**
 * Check lastIndex with storedLastIndex to find new message every 5sec
 */
export const setIntervalToCheckLastMessageIndex = (channelId) => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
            //add  first request
            let requestSent;
            const storedLastIndex = getState().chat.chatLastIndex;
            const chatRequestBody = {
                query: getMessageWithLastIndexRequestBody,
                variables: {
                    "channelId": Number(channelId),
                    "lastIndex": storedLastIndex
                }
            }
            requestSent = GraphRequest.all(chatRequestBody);
            let requestStatus = MakeQuerablePromise(requestSent);

            //add interval for get new messages
            dispatch(DISPATCH_STATE.initIntervalGetMessage(setInterval(() => {
                const storedLastIndex = getState().chat.chatLastIndex;
                const storedLastConsumeIndex = getState().chat.lastConsumeIndex;
                const chatRequestBody = {
                    query: getMessageWithLastIndexRequestBody,
                    variables: {
                        "channelId": Number(channelId),
                        "lastIndex": storedLastIndex
                    }
                }
                if (!requestStatus.isPending()) {
                    requestSent = GraphRequest.all(chatRequestBody)
                        .then(res => {
                            if (!_.isNull(res.data.data) && res.data.data.chatMessages) {
                                const { totalItems, lastConsumeIndex } = res.data.data.chatMessages;
                                const lastIndex = totalItems - 1;
                                if (lastIndex !== storedLastIndex) {
                                    dispatch(DISPATCH_STATE.setLastIndex(lastIndex));
                                    dispatch(getMessagesWithLastIndex(storedLastIndex, res.data.data.chatMessages));
                                } else if (storedLastConsumeIndex !== lastConsumeIndex) {
                                    dispatch(DISPATCH_STATE.setLastConsumeIndex(lastConsumeIndex));
                                    dispatch(changeStatusOfMessageToReceived(lastConsumeIndex));
                                }
                            }
                        });
                    requestStatus = MakeQuerablePromise(requestSent);
                } else {
                    console.log('has pending request', requestSent);
                }                
                
            }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_NEW_MESSAGE)));
        }
    }
}
/**
 * wrapper for findout request isResolved, isPending or isFulfilled
 */
export const MakeQuerablePromise = (promise) => {
    // Don't modify any promise that has been already modified.
    if (promise.isResolved) return promise;

    // Set initial state
    var isPending = true;
    var isRejected = false;
    var isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    var result = promise.then(
        function (v) {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        function (e) {
            isRejected = true;
            isPending = false;
            throw e;
        }
    );

    result.isFulfilled = function () { return isFulfilled; };
    result.isPending = function () { return isPending; };
    result.isRejected = function () { return isRejected; };
    return result;
}

/**
 * Change message status
 */
export const changeStatusOfMessageToReceived = (index) => {
    return (dispatch, getState) => {
        if (typeof (getState().chat.newMessages[0]) !== 'undefined') {
            let messages = getState().chat.newMessages[ 0 ].items;

            messages = messages.map((message, i) => {
                message.status = MESSAGE_STATUS_RECEIVED;
                return message;
            });
            const messageList = {
                "0": {
                    "items": messages,
                    "totalItems": messages.length
                }
            }
            dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
        }
    }
}
/**
 * Get Messages from server by lastIndex | Update message list
 */
export const getMessagesWithLastIndex = (lastIndex,messageList) => {
    return (dispatch, getState) => {
        messageList.items.shift(); 
        let newMessageList = [];
        messageList.items.map((message) => {
            if(message.authorType !== 'CLIENT') {
                newMessageList.push(message); 
            } 
        });
        const messageList2 = {
            "items": newMessageList,
            "totalItems": newMessageList.length
        }
        if (newMessageList.length) {
            dispatch(paginateMessageList(messageList2));
            if (typeof (getState().chat.newMessages[0]) !== 'undefined' && typeof (getState().chat.updatedMessages[0]) !== 'undefined') {
                let data = [{
                    items: [...getState().chat.newMessages[0].items, ...getState().chat.updatedMessages[0].items],
                    totalItems: getState().chat.newMessages[0].totalItems
                }];
                dispatch(DISPATCH_STATE.fetchMessagesSuccess(data));
            }
        } 
        dispatch(chatScrollToBottom());
    }
}
/**
 * Get Messages from server by offset
 */
export const fetchMessagesByOffset = (lastScrollHeight) => {
    return (dispatch, getState) => {
        const channelId = getState().jobs.job.id;
        let offset;
        let limit;
        if (getState().chat.offset > 15) {
            offset = getState().chat.offset - 15;
            limit = 15;
        } else {
            offset = 0;
            limit = getState().chat.offset;
        }
        const chatRequestBody = {
			query: getMessagesWithOffsetRequestBody,
			variables: {
				channelId: Number(channelId),
				offset: offset,
				limit: limit,
			},
		};
        if (getState().chat.offset !== 0) {
            dispatch(DISPATCH_STATE.fetchMessageBegin());
            dispatch(DISPATCH_STATE.setOffset(offset));
            dispatch(DISPATCH_STATE.setLimit(offset));

            GraphRequest.all(chatRequestBody)
				.then((res) => {
					if (!_.isNull(res.data.data) && res.data.data.chatMessages) {
						dispatch(paginateMessageList(res.data.data.chatMessages));
						let data = [
							{
								items: [...getState().chat.updatedMessages[0].items, ...getState().chat.newMessages[0].items],
								totalItems: getState().chat.newMessages[0].totalItems,
							},
						];
						dispatch(DISPATCH_STATE.fetchMessagesSuccess(data));

						const { updatedMessages } = getState().chat;
						const getPrevFirstIndex = updatedMessages[0].items[updatedMessages[0].items.length - 1].index;
						const e = document.getElementById(`message-box-${getPrevFirstIndex}`);
						let scroll = document.getElementById("v-scrollable-chat");
						if (scroll) {
							scroll.scrollTop = scroll.scrollHeight - lastScrollHeight - e.scrollHeight;
						}
						dispatch(DISPATCH_STATE.fetchMessageEnd());
					}
				})
				.catch((error) => {
					console.log("error", error);
					dispatch(appFetchFailure(error));
				});
        }
    };
}
/**
 * When user come out from chat page
 */
export const getMessagesWillUnmount = () => {
    return (dispatch, getState) => {
        clearInterval(getState().chat.getMessageSetInterval);
        dispatch(DISPATCH_STATE.getMessageWillUnMountDispatch());
    }
}