import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { push } from 'connected-react-router';

import * as DISPATCH_STATE from '../commonChangeState';
import {
	localStorageGetToken,
	localStorageIsTwa,
	locationStorageGetData,
	getClientLocale,
	setClientLocale,
} from "../../../../functions/local-storage";
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from './common-failure';
import { mapGetcurrentLocationLocalstorage, initializeMapShow, setGooglePlace } from "../../mapActions";
import * as LOG_EVENT from '../../../../analytics';
import { appInitFcm } from "./common-fcm";

/**
 * check authority of user
 * @param {*} history 
 */
export function appAuthCheck(client) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(DISPATCH_STATE.appAuthCheckBegin());
            let token = localStorageGetToken();
            const location = locationStorageGetData(getState);
            if (token) {
                dispatch(appInitializeCommon({ token }));
                if (client && !_.isNull(client) && _.isEmpty(getState().common.client)) {
                    if (!_.isNull(getState().common.lastPathName)) {
                        dispatch(push(getState().common.lastPathName));
                    }
                    dispatch(resolveClientRequest(client));
                    if (getState().router.location.pathname !== "/register") {
                        LOG_EVENT.logEvent(LOG_EVENT.APP_OPENED_CWA, { value: 1.00, currency: 'PHP' });
                    }
                } 
                else {
                    dispatch(DISPATCH_STATE.appAuthCheckEnd());
                    const clientRequestBody = {
						query: `query Client {
                            client {
                                id
                                givenName
                                lastName
                                emailAddress
                                mobileNumber {
                                    number
                                    fullNumber
                                countryCode {
                                    icon
                                    code
                                }
                            }
                            employerCompany
                            refereeCode
                            referralCode
                            livingCity {
                                id
                                displayValue
                            }
                            workingCity {
                                id
                                displayValue
                            }
                            state
                            currency {
                                unit
                                countryCode
                                locale
                                signText
                                signSvg
                            }
                        }}`,
					};
                    GraphRequest.all(clientRequestBody)
                        .then(json => {
                            if (!_.isNull(json.data.data) && json.data.data.client) {
                                dispatch(resolveClientRequest(json.data.data.client));
                                if (!getClientLocale()) {
									setClientLocale(json.data.data.client.currency.locale);
								}
                                LOG_EVENT.logEvent(LOG_EVENT.APP_OPENED_CWA, { value: 1.00, currency: 'PHP' });
                                resolve();
                            } else {
                                dispatch(appFetchFailure(json.data.errors[0].message));
                                dispatch(DISPATCH_STATE.appAuthCheckEnd());
                            }
                        })
                }
            } else if (_.isNull(getState().map.location.lat) || _.isNull(getState().map.location.lng)) {
                
                if (!_.isNull(location.lat) || !_.isNull(location.lng)) {
                    dispatch(push("/home"));
                    dispatch(DISPATCH_STATE.setLocationSuccessfully());
                } else {
                    dispatch(initializeMapShow());
                    dispatch(setGooglePlace({}));
                    dispatch(DISPATCH_STATE.navigateToSetLocation());
                    dispatch(push("/map"));
                }
			} else {
				dispatch(DISPATCH_STATE.appAuthCheckFailed(token, false));
				dispatch(DISPATCH_STATE.appAuthCheckEnd());

            }
        });
    };
}

export const resolveClientRequest = (client) => {
    return dispatch => {
        dispatch(configSentryScope(client));
        dispatch(DISPATCH_STATE.appAuthGetClient(client));
        if (!localStorageIsTwa()) {
            dispatch(appInitFcm());
        }
        dispatch(DISPATCH_STATE.appAuthCheckInit());
        dispatch(DISPATCH_STATE.appAuthCheckEnd());
        LOG_EVENT.identifyClientInSegment(client);
    }
}

export const appInitializeCommon = (data) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.appAuthCheckPassed(data.token));
        dispatch(mapGetcurrentLocationLocalstorage());
    }
}
/**
 * set user for sentry
 * @param {*} user 
 */
const configSentryScope = (user) => {
    return (dispatch) => {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user.id,
                email: user.emailAddress,
                username: user.mobileNumber.fullNumber
            });
        });
    }
}