export const adyenPaymentMethods = `query AdyenPaymentMethods($channel: PaymentMethodAdyenPaymentMethodChannelEnum, $unit: String) {
  adyenPaymentMethods(channel: $channel, unit: $unit) {
		groups {
      name
      types
    }
    paymentMethods {
      brands
      details {
        key
        type
        optional
      }
      name
      type
    }
  }
}`;

export const addPaymentMethod = `mutation AddAdyenPaymentMethod($shopperRedirectUrl: String, $paymentCard: ClientAddPaymentMethodPaymentCardInput!, $unit: String) {
  addAdyenPaymentMethod(paymentCard: $paymentCard, unit:$unit, shopperRedirectUrl: $shopperRedirectUrl) {
		succeed
    result
    details
  }
}`;

export const add3dSecurePaymentMethod = `mutation AddAdyenSecurePaymentMethod($paymentCard: ClientAddPaymentMethodPaymentCardInput!) {
  addAdyenSecurePaymentMethod(paymentCard: $paymentCard) {
		succeed
    result
    details
  }
}`;

export const paymentAndBalance = `query PaymentMethods($unit: String) {
  paymentMethods {
    totalItems
    items {
        id
        type
        icon
        name
        value
        isPrimary
        cardNumber
        expirationMonth
    }
  }
  balances(unit: $unit) {
    unit
    balance
    rate
    displayValue
    currencySign
  }
}`;