import axios from 'axios';

import { localStorageGetToken, getClientLocale } from "./utils/functions/local-storage";
import { VERSION_CWA_NUMBER } from "./utils/constants";
// const customAxios = axios.create({
//     baseURL: 'http://localhost:8085',
//     timeout: 1000,
//     headers: {
//         'Content-Type': 'application/json',
//     }
// });
// setup base url for different environment
axios.defaults.baseURL = process.env.REACT_APP_CLIENT_API_BASE_URL;
axios.defaults.headers.common[ 'x-app-type' ] = "cwa";
axios.defaults.headers.common["x-shop-url"] =
	window.location.host.split(".")[0] === "localhost:3000" ? "app" : window.location.host.split(".")[0];
axios.defaults.headers.common["x-app-version"] = VERSION_CWA_NUMBER;
axios.defaults.headers.common["x-locale"] = getClientLocale() ? getClientLocale() : "en";

axios.interceptors.request.use(function (config) {
    let token = getToken();
    if (token) {
        config.headers.Authorization = getToken();
    }
    return config;
});

export const GEO_BOUNDARY_COUNTRY_CODE = '/client/v1/geo-boundary/country-code';
export const MOBILE_NUMBER_VERIFICATION_CODE_SEND = '/client/v1/mobile-number/verification-code/send';
export const MOBILE_NUMBER_VERIFICATION_CODE_CHECK = '/client/v1/mobile-number/verification-code/check';
export const GEO_BOUNDARY_CITY_COVERD = '/client/v1/geo-boundary/city/covered';
export const CLIENT_SIGNUP = '/client/v1/client';
export const CLIENT_PRODUCT_CATALOG = '/client/v1/product/v2/catalog';
export const CLIENT_PRODUCT_ID = '/client/v1/product/';
export const CLIENT_ANNOUNCEMENT_NEW = '/client/v1/announcement/new';
export const CLIENT_REQUEST_HISTORY = '/client/v1/request/history';
export const CLIENT_REQUEST_ID = '/client/v1/request/';
export const CLIENT_ANNOUNCEMENT_HISTORY = '/client/v1/announcement/history';
export const CLIENT_ANNOUNCEMENT_ID = '/client/v1/announcement/';
export const CLIENT_CLIENT = '/client/v1/client';
export const CLIENT_CHAT_TOKEN = '/client/v1/chat/token';
export const CLIENT_JOB_ID = '/client/v1/job/';
export const CLIENT_JOB_UNRATED = '/client/v1/job/unrated';
export const CLIENT_JOB_ACTIVE = '/client/v1/job/active';
export const CLIENT_PAYMENT_METHOD = '/client/v1/client/payment-method';
export const CLIENT_PAYMENT_METHOD_V2 = '/client/v1/client/payment-method-v2';
export const CLIENT_PAYMENT_METHOD_V2_SECURE = '/client/v1/client/payment-method-v2/secure';
export const CLIENT_ACCOUNTING_BALANCE = '/client/v1/client/balance';
export const CLIENT_DEVICE = '/client/v1/device';
export const CLIENT_SAVED_PLACE = '/client/v1/saved-place';
export const CLIENT_LOGOUT = '/client/v1/client/logout';
export const CLIENT_PAYMENT_METHOD_EXIST = '/client/v1/client/payment-method/exist';
export const CLIENT_CHAT_HISTORY = '/client/v1/chat';
export const CLIENT_ADD_MESSAGE = '/client/v1/chat';
export const TOGGLE_BOOKEDMARKED_SAVEDPLACE = '/client/v1/saved-place';
export const CLIENT_TOP_UP_MYKOIN = "/client/v1/client/top-up";
export const MULTIPLE_REQUESTS = "/";

export const JOBS_HISTORY_LIMIT_NUMBER = 10; // limit number for get jobs from server
export const JOBS_UNRATED_LIMIT_NUMBER = 1; // limit number for get job unrated from server
export const ANNOUNCEMENT_HISTORY_LIMIT_NUMBER = 10; // limit number for get news from server
export const CHAT_HISTORY_LIMIT_NUMBER = 10; //limit number for get chat history from server

export const Payment = {
	// methods: (obj) => axios.post(CLIENT_PAYMENT_METHOD, obj),
	// change: (obj) => axios.post(CLIENT_PAYMENT_METHOD, obj),
	// delete: (obj) => axios.post(CLIENT_PAYMENT_METHOD, obj),
	create: (encryptedInfo) => axios.post(CLIENT_PAYMENT_METHOD, { encryptedInfo }),
	createV2: (data) => axios.post(CLIENT_PAYMENT_METHOD_V2, data), 
	adyenMethodsV2: (obj) => axios.post(CLIENT_PAYMENT_METHOD_V2, obj),
	createV2Secure: (data) => axios.post(CLIENT_PAYMENT_METHOD_V2_SECURE, data),
	checkout: (obj) => axios.post(CLIENT_PAYMENT_METHOD, obj),
	exist: () => axios.get(CLIENT_PAYMENT_METHOD_EXIST),
	// topup: (data) => axios.post(CLIENT_TOP_UP_MYKOIN, data),
};

export const Accounting = {
    // balance: (obj) => axios.post(CLIENT_ACCOUNTING_BALANCE, obj)
}

export const Product = {
    // all: (obj) => axios.post(CLIENT_PRODUCT_CATALOG, obj),
    // get: (obj) => axios.post(CLIENT_PRODUCT_ID, obj),
    // price: (obj) => axios.post(CLIENT_PRODUCT_ID, obj),
    // priceExtendJob: (obj) => axios.post(CLIENT_PRODUCT_ID, obj),
    // request: (obj) => axios.post(CLIENT_REQUEST_ID, obj)
}

export const Announcement = {
    // all: (obj) => axios.post(CLIENT_ANNOUNCEMENT_HISTORY, obj),
    // new: (obj) => axios.post(CLIENT_ANNOUNCEMENT_NEW, obj),
    // get: (id) => axios.post(CLIENT_ANNOUNCEMENT_ID, id),
}

export const Auth = {
    // updateClient: (obj) => axios.post(CLIENT_CLIENT, obj),
    // code: (obj) => axios.post(MOBILE_NUMBER_VERIFICATION_CODE_CHECK, obj),
    // client: (obj) => axios.post(CLIENT_CLIENT, obj),
    // phone: (obj) => axios.post(MOBILE_NUMBER_VERIFICATION_CODE_SEND, obj),
    // chatToken: () => axios.get(CLIENT_CHAT_TOKEN),
    // logout: (obj) => axios.post(CLIENT_LOGOUT, obj),
    // signup: (obj) => axios.post(CLIENT_SIGNUP, obj)
}

export const Request = {
    // all: (obj) => axios.post(CLIENT_REQUEST_HISTORY, obj),
    // get: (obj) => axios.post(CLIENT_REQUEST_ID, obj),
    // chatToken: (id) => axios.get(CLIENT_REQUEST_ID + id + '/chat/partner'),
    // state: (id) => axios.get(CLIENT_REQUEST_ID + id + '/state'),
    // partner: (id) => axios.get(CLIENT_REQUEST_ID + id + '/partner'),
    // partnerLocation: (obj) => axios.post(CLIENT_REQUEST_ID, obj),
    // cancel: (id) => axios.put(CLIENT_REQUEST_ID + id + '/cancel'),
}

export const Job = {
    // active: () => axios.get(CLIENT_JOB_ACTIVE),
    // cancel: (obj) => axios.post(CLIENT_JOB_ID, obj),
    // unrated: (obj) => axios.post(CLIENT_JOB_UNRATED, obj),
    // extend: (obj) => axios.post(CLIENT_JOB_ID, obj),
}

export const Partner = {
    // compliment: (obj) => axios.post(CLIENT_REQUEST_ID, obj),
    // rate: (obj) => axios.post(CLIENT_REQUEST_ID, obj)
}

export const Boundary = {
    // cities: () => axios.get(GEO_BOUNDARY_CITY_COVERD),
    // countries: (obj) => axios.post(GEO_BOUNDARY_COUNTRY_CODE, obj)
}

export const Device = {
    // signal: (obj) => axios.post(CLIENT_DEVICE, obj)
}

export const SavedPlace = {
    // all: (obj) => axios.post(CLIENT_SAVED_PLACE, obj),
    // create: (obj) => axios.post(CLIENT_SAVED_PLACE, obj),
    // delete: (obj) => axios.post(CLIENT_SAVED_PLACE , obj),
}

export const Chat = {
    // all: (obj) => axios.post(CLIENT_CHAT_HISTORY, obj),
    // add: (message) => axios.post(CLIENT_ADD_MESSAGE, message)
}

export const bookmarkSavedPlace = {
    // toggle: (id) => axios.put(TOGGLE_BOOKEDMARKED_SAVEDPLACE + '/' + id + '/bookmark'),
}

export const GraphRequest = {
    all: (obj) => axios.post(MULTIPLE_REQUESTS, obj),
}

function getToken() {
    let token = '';
    token = localStorageGetToken();
    return token;
}


export default axios;