import _ from 'lodash';

/**
 * 
 * @param {*} data 
 */
export function locationStorageSetData(data) {
    localStorage.setItem('lat', data.lat);
    localStorage.setItem('lng', data.lng);
    localStorage.setItem('address', data.address);
    localStorage.setItem('locationTitle', data.title);
}

/**
 * 
 * @param {*} getState 
 */
export function locationStorageGetData(getState) {
    return {
        ...getState().map.location,
        lat: _.isNull(localStorage.getItem('lat')) ? null : parseFloat(localStorage.getItem('lat')),
        lng: _.isNull(localStorage.getItem('lng')) ? null : parseFloat(localStorage.getItem('lng')),
        title: (_.isNull(localStorage.getItem('lat')) || _.isNull(localStorage.getItem('lng'))) ? "" : localStorage.getItem('locationTitle'),
        address: (_.isNull(localStorage.getItem('lat')) || _.isNull(localStorage.getItem('lng'))) ? "" : localStorage.getItem('address'),
    }
}
/**
 * Clear all localStorage
 */
export function localStorageLogout() {
    // localStorage.removeItem('lat');
    // localStorage.removeItem('lng');
    // localStorage.removeItem('address');
    // localStorage.removeItem('locationTitle');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('player_id');
    localStorage.removeItem('device_id');
    localStorage.removeItem('fcm');
    localStorage.removeItem('isFirstRequest');
    localStorage.removeItem('addStop');
    localStorage.removeItem('twa');
    localStorage.removeItem('isAgreementServiceFee');
    localStorage.removeItem("reviewBooking");
    localStorage.removeItem("countryFlag");
    localStorage.removeItem("locale");
}
/**
 * Set token and username
 * @param {*} data 
 */
export function localStorageSetTokenAndUserName(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('username', data.username);
}
/**
 * Get token from localstorage
 */
export function localStorageGetToken() {
    let token = localStorage.getItem('token');
    return token;
}
/**
 * Set player id in localstorage that is provided from oneSignal
 * @param {*} player_id 
 */
export function localStorageSetPlayerId(player_id) {
    if (player_id)
        localStorage.setItem('player_id', player_id);
}
/**
 * Get specific local storage player id
 */
export function localStorageGetPlayerId() {
    return localStorage.getItem('player_id');
}
/**
 * check user has player id
 */
export function localStorageHasPlayer() {
    return localStorage.getItem('player_id');
}
/**
 * Get specific local storage player id
 */
export function localStorageSetDeviceId(hash) {
    return localStorage.setItem('device_id', hash);
}
/**
 * check user has player id
 */
export function localStorageHasDeviceId() {
    return localStorage.getItem('device_id');
}
/*
 * local storage tutorial app
 */
export function localStorageTutorial() {
    return localStorage.getItem('tutorial');
}
/**
 * Set Storage Toturial
 */
export function localStorageSetTutorial() {
    localStorage.setItem('tutorial', 1);
}
/**
 * local storage tutorial app
 */
export function localStorageTutorialBrowser() {
    return localStorage.getItem('tutorialBrowser');
}
/**
 * Set Storage Toturial
 */
export function localStorageSetTutorialBrowser() {
    localStorage.setItem('tutorialBrowser', 1);
}
/**
 * Set Storage Toturial
 */
export function localStorageSetTwa() {
    localStorage.setItem('twa', 1);
}
/**
 * Set Storage Toturial
 */
export function localStorageIsTwa() {
    return localStorage.getItem('twa');
}
/**
 * Set Local Storage for FCM
 */
export function localStorageSetFcmToken(token) {
    localStorage.setItem('fcm', token);
}
/**
 * Get FCM Token 
 */
export function localStorageGetFcmToken() {
    return localStorage.getItem('fcm');
}
/**
 * Get specific local storage updated location
 */
export function localStorageGetUpdatedLocation() {
    return localStorage.getItem('updated_location');
}
/**
 * check user has updated location
 */
export function localStorageHasUpdatedLocation() {
    return localStorage.getItem('updated_location');
}
/**
 * Get specific local storage updated location
 */
export function localStorageSetUpdatedLocation() {
    return localStorage.setItem('updated_location', new Date().toUTCString());
}
/**
 * Set latest customer search place
 */
export function localStorageSetLatestPlace(place) {
    return localStorage.setItem('latest_place', place);
}
/**
 * Get latest customer search place
 */
export function localStorageGetLatestPlace() {
    return localStorage.getItem('latest_place');
}

export const localStorageSetAndroidApp = () => {
    return localStorage.setItem('android', 1);
}

export const localStorageGetAndroidApp = () => {
    return localStorage.getItem('android');
}

export const localStorageSetIosApp = () => {
    return localStorage.setItem('ios', 1);
}

export const localStorageGetIosApp = () => {
    return localStorage.getItem('ios');
}

export const localStorageSetCwa = () => {
    return localStorage.setItem('cwa', 1);
}

export const localStorageGetCwa = () => {
    return localStorage.getItem('cwa');
}

export const localStorageSetReminderRateApp = (date) => {
    return localStorage.setItem('reminderRateAppDate', date);
}

export const localStorageGetReminderRateApp = () => {
    return localStorage.getItem('reminderRateAppDate');
}

export const localStorageSetAppIsRated = () => {
    return localStorage.setItem('appIsRated', 1);
};

export const localStorageGetAppIsRated = () => {
    return localStorage.getItem('appIsRated');
};

export const localStorageSetIsAgreementServiceFee = (data) => {
    return localStorage.setItem("isAgreementServiceFee", data);
};

export const localStorageGetIsAgreementServiceFee = () => {
    return localStorage.getItem("isAgreementServiceFee");
};

export const localStorageSetIsFirstRequest = () => {
    return localStorage.setItem("isFirstRequest", 1);
};

export const localStorageGetIsFirstRequest = () => {
    return localStorage.getItem("isFirstRequest");
};

export const localStorageSetAddStop = () => {
    return localStorage.setItem("addStop", 1);
};

export const localStorageGetAddStop = () => {
    return localStorage.getItem("addStop");
};

export const localStorageSetProductInfoViwed = (service) => {
    return localStorage.setItem("productInfoViwed", service);
};

export const localStorageGetProductInfoViwed = () => {
    return localStorage.getItem("productInfoViwed");
};

export const localStorageGetUsername = () => localStorage.getItem('username')

export const localStorageGetLocation = () => ({
    lat: localStorage.getItem("lat"), 
    lng: localStorage.getItem("lng"), 
    locationTitle: localStorage.getItem("locationTitle"),
});

export const setReviewBookingLocalStorage = (reviewBooking) => {
    return localStorage.setItem("reviewBooking", reviewBooking);
};

export const getReviewBookingLocalStorage = () => {
	return localStorage.getItem("reviewBooking");
};

export const localStorageSetIdentifyClientInSegment = () => localStorage.setItem("identifyClientInSegment");
export const localStorageGetIdentifyClntInSegment = ()=> localStorage.getItem("identifyClientInSegment");

export const localStorageSetCurrentBalance = (currentBalance) => localStorage.setItem("currentBalance", currentBalance);
export const localStorageGetCurrentBalance = () => localStorage.getItem("currentBalance");

export const localStorageSetCountryFlag = (icon) => localStorage.setItem("countryFlag", icon);
export const localStorageGetCountryFlag = () => localStorage.getItem("countryFlag");

export const setClientLocale = (locale) => localStorage.setItem("locale", locale);
export const getClientLocale = () => localStorage.getItem("locale");

export const setStoredUrlLocalStorage = (storedUrl) => localStorage.setItem("storedUrl", storedUrl);
export const getStoredUrlLocalStorage = () => localStorage.getItem("storedUrl");