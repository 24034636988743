import {
	APP_LOADING_BEGIN,
	APP_LOADING_END,
	APP_AUTH_CHECK_BEGIN,
	APP_AUTH_CHECK_PASSED,
	APP_AUTH_CHECK_FAILED,
	APP_AUTH_CHECK_END,
	APP_AUTH_REDIRECT,
	APP_AUTH_GET_CLIENT,
	APP_GET_CLIENT,
	APP_FETCH_FAILURE,
	APP_PROFILE_FETCH_CLIENT_BOUNDARY,
	APP_PROFILE_LOGOUT,
	APP_SET_LOCALSTORAGE,
	APP_INIT_TWILIO,
	APP_AUTH_CHECK,
	APP_LOADING_KUYA_BEGIN,
	APP_LOADING_KUYA_END,
	APP_GO_HOME_CHECK,
	APP_DETECT_DESKTOP_USER,
	APP_DETECT_ROTATION_DEVICE,
	APP_INTERNET_IS_DISCONNECTED,
	WRONG_VERIFICATION_CODE,
	APP_INTERNET_TRY_AGAIN,
	APP_USER_CLICK_ON_BOTTOM_BAR_LINK,
	APP_AUTH_GET_TWILIO_TOKEN,
	CLIENT_HAS_NO_PAYMENT_METHOD,
	STORE_BOOKING_URL,
	GET_PRIMARY_PAYMENT,
	SELECT_TAB,
	RESET_WRONG_VERIFICATION_CODE_STATUS,
	CHANGE_TIME_FORMAT,
	SET_CLIENT_HAS_NO_PAYMENT_METHOD_FALSE,
	RETURN_ERROR_FROM_ADYEN,
	LOCATION_IS_CHANGED,
	SET_LOCATION_POINTER,
	INIT_INTERVAL_CHECK_ACCOUNT_BALANCE,
	CLEAR_INTERVAL_CHECK_ACCOUNT_BALANCE,
	SET_ACCOUNTING_REQUEST_COUNT,
	TOGGLE_CHAT_WITH_SUPPORT_BOTTOM_SHEET,
	TOGGLE_LOG_IN_BOTTOM_SHEET,
	SAVE_LAST_PATH_NAME
} from "../actions/commonActions";

const initialState = {
	loading: false,
	loadingKuya: false,
	goHomeFromOtherPage: false, // when user back home from other pages
	error: null,
	auth: false,
	// auth: true,
	authCheck: false,
	token: null,
	twilioToken: null,
	twilio: null,
	username: null,
	client: {},
	cities: {},
	countries: {},
	isDesktop: false, // user device type
	isRotated: false, // user device rotation
	isInternetDisconnected: false, // for showing internet connection'
	clickOneOnBottomBar: false, // when user just one click on bottom bar link
	isWrongVerificationCode: false,
	clientHasNoPaymentMethod: null,
	pathName: null,
	primaryPayment: null,
	selectedTabIndex: 0,
	formattedTime: null,
	errorMessageAdyen: null,
	locationIsChanged: false,
	lctionPointer: null,
	intervalCheckAccountBalance: null,
	accountingRequestCount: 0,
	toggleChatWithSupport: false,
	logInBottomSheet: false,
	lastPathName: null
};

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
		case APP_LOADING_BEGIN:
			return {
				...state,
				loading: true,
			};
		case APP_LOADING_END:
			return {
				...state,
				loading: false,
			};
		case APP_DETECT_DESKTOP_USER:
			return {
				...state,
				isDesktop: action.payload,
			};
		case APP_DETECT_ROTATION_DEVICE:
			return {
				...state,
				isRotated: action.payload,
			};
		case APP_LOADING_KUYA_BEGIN:
			return {
				...state,
				loadingKuya: true,
			};

		case APP_LOADING_KUYA_END:
			return {
				...state,
				loadingKuya: false,
			};
		case APP_AUTH_CHECK_BEGIN:
			return {
				...state,
			};
		case APP_GO_HOME_CHECK:
			return {
				...state,
				goHomeFromOtherPage: true,
			};
		case APP_SET_LOCALSTORAGE:
			return {
				...state,
				username: action.payload.username,
				token: action.payload.token,
			};
		case APP_AUTH_CHECK_PASSED:
			return {
				...state,
				// auth: action.payload.auth,
				token: action.payload.token,
			};
		case APP_AUTH_CHECK:
			return {
				...state,
				auth: true,
			};
		case APP_AUTH_CHECK_FAILED:
			return {
				...state,
				auth: action.payload.auth,
				token: action.payload.token,
			};
		case APP_AUTH_GET_CLIENT:
			return {
				...state,
				client: action.payload,
			};
		case APP_AUTH_GET_TWILIO_TOKEN:
			return {
				...state,
				twilioToken: action.payload,
			};
		case APP_GET_CLIENT:
			return {
				...state,
				client: action.payload,
			};
		case APP_INIT_TWILIO:
			return {
				...state,
				twilio: action.payload,
			};
		case APP_PROFILE_FETCH_CLIENT_BOUNDARY:
			return {
				...state,
				client: action.payload.client,
				countries: action.payload.country,
				// cities: action.payload[ 1 ].data,
			};
		case APP_AUTH_CHECK_END:
			return {
				...state,
				authCheck: true,
			};
		case APP_INTERNET_IS_DISCONNECTED:
			return {
				...state,
				isInternetDisconnected: true,
			};
		case WRONG_VERIFICATION_CODE:
			return {
				...state,
				isWrongVerificationCode: action.payload,
			};
		case APP_INTERNET_TRY_AGAIN:
			return {
				...state,
				isInternetDisconnected: false,
			};
		case APP_AUTH_REDIRECT:
			return {
				...state,
			};
		case APP_FETCH_FAILURE:
			return {
				...state,
				error: action.payload.error,
			};
		case APP_USER_CLICK_ON_BOTTOM_BAR_LINK:
			return {
				...state,
				clickOneOnBottomBar: true,
			};
		case APP_PROFILE_LOGOUT:
			return {
				...initialState,
			};
		case CLIENT_HAS_NO_PAYMENT_METHOD:
			return {
				...state,
				clientHasNoPaymentMethod: true,
			};
		case SET_CLIENT_HAS_NO_PAYMENT_METHOD_FALSE:
			return {
				...state,
				clientHasNoPaymentMethod: false,
			};
		case STORE_BOOKING_URL:
			return {
				...state,
				pathName: action.payload,
			};
		case GET_PRIMARY_PAYMENT:
			return {
				...state,
				primaryPayment: action.payload,
			};
		case SELECT_TAB:
			return {
				...state,
				selectedTabIndex: action.payload,
			};
		case RESET_WRONG_VERIFICATION_CODE_STATUS:
			return {
				...state,
				isWrongVerificationCode: false,
			};
		case CHANGE_TIME_FORMAT:
			return {
				...state,
				formattedTime: action.payload,
			};
		case RETURN_ERROR_FROM_ADYEN:
			return {
				...state,
				errorMessageAdyen: action.payload,
			};
		case LOCATION_IS_CHANGED:
			return {
				...state,
				locationIsChanged: !state.locationIsChanged,
			};
		case SET_LOCATION_POINTER:
			return {
				...state,
				lctionPointer: action.payload,
			};
		case INIT_INTERVAL_CHECK_ACCOUNT_BALANCE:
			return {
				...state,
				intervalCheckAccountBalance: action.payload,
			};
		case CLEAR_INTERVAL_CHECK_ACCOUNT_BALANCE:
			return {
				...state,
				intervalCheckAccountBalance: null,
			};
		case SET_ACCOUNTING_REQUEST_COUNT:
			return {
				...state,
				accountingRequestCount: state.accountingRequestCount + 1,
			};
		case TOGGLE_CHAT_WITH_SUPPORT_BOTTOM_SHEET:
			return {
				...state,
				toggleChatWithSupport: !state.toggleChatWithSupport,
			};
		case TOGGLE_LOG_IN_BOTTOM_SHEET:
			return {
				...state,
				logInBottomSheet: !state.logInBottomSheet,
			};
		case SAVE_LAST_PATH_NAME:
			return {
				...state,
				lastPathName: action.payload
			}
		default:
			return state;
	}
}