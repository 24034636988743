import $ from 'jquery';
import _ from 'lodash';

import {BOOKING_OPTIONS_FORM_ID} from '../redux/actions/bookingActions';
import { getReviewBookingLocalStorage } from "./local-storage";
/**
 * Create object for sending in body of post request in booking Request Now
 * @param {*} getState 
 * @param {*} locations 
 */
export function createReqeustObj(getState, locations) {
    let objRequest = {};
    const product = getState().booking.product;
    objRequest.cachedPriceId = getState().booking.prices.cachedPriceId;
    objRequest.productId = product.id;
    objRequest.cubeNumber = getState().booking.prices.minCubeNumbers;
    if (getReviewBookingLocalStorage()) {
        objRequest.jobLocations = addNameToLocations([...locations.jobLocations]);
    } else {
        objRequest.jobLocations = addNameToLocations([
            ...locations.jobLocations,
            ...getState().map.googlePlaces
        ]);
    }
    objRequest.note = getState().booking.note;
    objRequest.voucherCode = getState().booking.promoCode;
    
    return objRequest;
}

function addNameToLocations(jobLocations) {
    return jobLocations.map((location) => {
        if(_.isNull(location.id)) delete location.id;
        if (_.isNull(location.defaultLat)) delete location.defaultLat;
        if (_.isNull(location.defaultLng)) delete location.defaultLng;
        location.name = location.title;
        return location;
    });
}
/**
 * Handle options of product request
 */
function makeOptionsObject() {
    let options = [];
    let dom = $(BOOKING_OPTIONS_FORM_ID);
    if (dom) {
        options = $(BOOKING_OPTIONS_FORM_ID).serializeArray().map((option, index) => {
            return {
                title: option.value
            }
        });
    }
    
    return options;
}

export const createPlaceObjectForFetchingPrice = (obj) => {
    return {
        lat: obj.lat,
        lng: obj.lng,
        address: obj.address ? obj.address : obj.name
    }
}

export function createScheduledObj(getState, dueDate, locations) {
	let objRequest = {};
	const product = getState().booking.product;
	objRequest.cachedPriceId = getState().booking.prices.cachedPriceId;
	objRequest.productId = product.id;
	objRequest.cubeNumber = getState().booking.prices.minCubeNumbers;
	if (getReviewBookingLocalStorage()) {
		objRequest.jobLocations = addNameToLocations([...locations.jobLocations]);
	} else {
		objRequest.jobLocations = addNameToLocations([...locations.jobLocations, ...getState().map.googlePlaces]);
	}
	objRequest.note = getState().booking.note;
	objRequest.voucherCode = getState().booking.promoCode;
	objRequest.dueDate = dueDate;

	return objRequest;
}
