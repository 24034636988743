import {
  appGoBack
} from '../commonActions';

import * as DISPATCH_STATE from './supportChangeState';
import * as LOG_EVENT from '../../../analytics';

/**
 * Toggle web widget zendesk
 * @param {*} script 
 */
export const toggleWebWidget = () => {
  return (dispatch, getState) => {
    window.zE('webWidget', 'prefill', {
      name: {
        value: getState().common.client.givenName,
        readOnly: true // optional
      },
      email: {
        value: getState().common.client.emailAddress,
        readOnly: true // optional
      },
      phone: {
        value: getState().common.client.mobileNumber.fullNumber,
        readOnly: true // optional
      }
    });
    window.zE('webWidget', 'toggle');
    dispatch(DISPATCH_STATE.supportWebWidgetToggle());
    LOG_EVENT.logEvent(LOG_EVENT.CHAT_SUPPORT_OPENED_CWA);
  }
};
/**
 * Toggle web widget zendesk without client
 * @param {*} script 
 */
export const toggleWebWidgetWithoutClient = () => {
  return (dispatch, getState) => {
    window.zE('webWidget', 'toggle');
    dispatch(DISPATCH_STATE.supportWebWidgetToggle());
    LOG_EVENT.logEvent(LOG_EVENT.CHAT_SUPPORT_OPENED_CWA);
  }
};
/**
 * Toggle web widget zendesk without client
 * @param {*} script 
 */
export const closeSupportWidget = () => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.supportWebWidgetCloseByBack());
    window.zE('webWidget', 'close');
  }
};
/**
 * Set close listener just once
 */
export const setCloseListener = () => {
  return (dispatch, getState) => {
    if (!getState().support.initCloseLinstener) {
      dispatch(DISPATCH_STATE.initCloseLinstener());
      window.zE('webWidget:on', 'close', (e) => {
        if (!getState().support.closeByBack) {
          dispatch(appGoBack());
        }
      });
    }

  }
}