import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import { fetchSavedPlaces } from './savedPlaces-list';
import { deleteSavedPlaceRequestBody } from './saved-places-gql';
/**
 * delete request place from saved places
 * @param {*} place 
 */
export const deleteRequestPlaceOfSavedPlaces = (place) => {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        if (place.location.id || _.isNull(place.location.id)) delete place.location.id;
        const deleteRequestBody = {
            query: deleteSavedPlaceRequestBody,
            variables: { "id": place.id }
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(deleteRequestBody) 
                .then(res => {
                    if (!_.isNull(res.data.data) && res.data.data.deleteSavedPlace) {
                        dispatch(fetchSavedPlaces());
                    } else {
                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
                    dispatch(appLoadingEnd());
                    resolve();
                })
                .catch((e) => dispatch(appFetchFailure(e)))
        })
    }
}
/**
 * Nevermind delete request place from saved places
 */
export const nevermindDeleteRequest = () => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.savedPlacesSetNullWantedToDeletePlace());
        dispatch(fetchSavedPlaces());
    }
}