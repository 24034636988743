export const deleteSavedPlaceRequestBody = `mutation DeleteSavedPlace($id: Int!) {
  deleteSavedPlace(savedPlaceId: $id) {
    succeed
    result
  }
}`;

export const createSavedPlaceRequestBody = `mutation CreateSavedPlace($data: ClientCreateSavedPlaceInput!) {
  createSavedPlace(data: $data) {
    id
    clientId
    title
    isActive
    type
    isBookmarked
    location {
      lat
      lng
      address
    }
  }
}`;


