import React from 'react';
import './LoadingScreen.css';

import Splash from '../../assests/images/logo/splash.svg';
import MykuyaLoading from '../../assests/images/logo/mykuya-loading.svg';

export default function LoadingScreenKuya(props) {
    return (
        <div className="loading-screen-index">
            <img src={MykuyaLoading} className="loading-screen-index-image" alt="" />
            <img src={Splash} className="splash-image" alt="" />
        </div>
    );
}