import _ from 'lodash';
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import * as DISPATCH_STATE from './newsChangeState';
import { GraphRequest } from '../../../../axios';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../commonActions';

/**
 * Get news from server for news page for first time of loading
 */
export function fetchNews() {
  return (dispatch, getState) => {
    dispatch(appLoadingBegin());
    dispatch(DISPATCH_STATE.fetchNewsBegin());
    const getNewsRequestBody = {
      query: `query Announcements($offset: Int, $limit: Int) {
          announcements(offset: $offset, limit: $limit) {
            id
            title
            dateTime
            body
          }
        }`,
      variables: {
        "offset": 0
      }
    }
    GraphRequest.all(getNewsRequestBody)
      .then(json => {
        if (!_.isNull(json.data.data) && json.data.data.announcements) {
          dispatch(DISPATCH_STATE.fetchNewsSuccess(json.data.data.announcements));
          dispatch(appLoadingEnd());
        } else {
          dispatch(appFetchFailure(json.data.errors[0].message));
          toast(`${json.data.errors[0].message}`, {
            position: "bottom-center",
            autoClose: 2000,
            limit: 1,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
            hideProgressBar: true,
            closeOnClick: false,
          });
          dispatch(appLoadingEnd());
        }
      })
      .catch(error => dispatch(appFetchFailure(error)));
  };
}
/**
 * Fetch Article
 * @param {*} id 
 */
export function fetchArticle(id) {
  return (dispatch, getState) => {
    dispatch(appLoadingBegin());
    dispatch(DISPATCH_STATE.fetchNewsArticleBegin());
    if (!_.isEmpty(getState().news.article) && getState().news.article.id === id) {
      return new Promise((resolve, reject) => {
        let data = [{
          data: getState().news.article
        }, ];
        return resolve(data);
      }).then(json => {
        dispatch(DISPATCH_STATE.fetchNewsArticleSuccess(json));
        dispatch(appLoadingEnd());
      });
    } else {
      const getNewsDetailRequestBody = {
        query: `query AnnouncementDetail($id: Int!) {
          announcement(announcementId: $id) {
            id
            title
            body
            thumbnailImage
            dateTime
          }
        }`,
        variables: {
          "id": Number(id)
        }
      }
      GraphRequest.all(getNewsDetailRequestBody)
        .then(json => {
          if (!_.isNull(json.data.data) && json.data.data.announcement) {
            dispatch(DISPATCH_STATE.fetchNewsArticleSuccess(json.data.data.announcement));
            dispatch(appLoadingEnd());
          } else {
            dispatch(appFetchFailure(json.data.errors[0].message));
            toast(`${json.data.errors[0].message}`, {
              position: "bottom-center",
              autoClose: 2000,
              limit: 1,
              className: "toast-rejected-payment",
              bodyClassName: "toastify-inner",
              hideProgressBar: true,
              closeOnClick: false,
            });
            dispatch(appLoadingEnd());
          }
        })
        .catch(error => dispatch(appFetchFailure(error)));
    }
  };
};

// navigate from home
export const setNewsLocationPointer = () => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.newsSetSearchLocationPointer(getState().router.location.pathname));
  }
};

/**
 * When go back news
 */
export function appGoBackNews() {
  return (dispatch, getState) => {
    if (getState().news.searchLocationPointer) {
      dispatch(push(getState().news.searchLocationPointer));
      dispatch(DISPATCH_STATE.newsSetSearchLocationPointer(null));
    } else {
      dispatch(push("/news"));
    }
  }
}