import * as CONSTANT_ACTIONS from './homeConstant';

export const fetchHomeBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_HOME_BEGIN
});

export const fetchHomeSuccess = (catalog, news) => ({
    type: CONSTANT_ACTIONS.FETCH_HOME_SUCCESS,
    payload: { catalog, news}
});

export const homeWillUnMount = () => ({
    type: CONSTANT_ACTIONS.HOME_WILL_UN_MOUNT
});
/**
 * show rejected payment modal
 */
export const homeRejectedPayment = () => ({
    type: CONSTANT_ACTIONS.HOME_REJECTED_PAYMENT,
});
/**
 * close rejected payment modal
 */
export const homeRejectedPaymentClose = () => ({
    type: CONSTANT_ACTIONS.HOME_REJECTED_PAYMENT_CLOSE,
});
/**
 * show rejected payment modal
 */
export const homeRejectedPaymentPsp = (data) => ({
    type: CONSTANT_ACTIONS.HOME_REJECTED_PAYMENT_PSP,
    payload: data
});

export const clickSHowMoreServices = () => ({
    type: CONSTANT_ACTIONS.CLICK_SHOW_MORE_SERVICES
});

export const clickOnServiceForShowDetail = () => ({
    type: CONSTANT_ACTIONS.CLICK_ON_SERVICE_FOR_SHOW_DETAIL
});

export const homeProductCheckExist = (check) => ({
    type: CONSTANT_ACTIONS.HOME_PRODUCT_CHECK_EXIST,
    payload: check
});

export const homeProductListShouldUpdated = (status) => ({
    type: CONSTANT_ACTIONS.HOME_PRODUCT_LIST_SHOULD_UPDATED,
    payload: status
});

export const fetchProductCatalogBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_PRODUCT_CATALOG_BEGIN
});

export const fetchProductCatalogSuccess = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_PRODUCT_CATALOG_SUCCESS,
    payload: data
});

export const goToMapFromBottomSheet = () => ({
    type: CONSTANT_ACTIONS.GO_TO_MAP_FROM_BOTTOM_SHEET
});

export const setShopInfo = (shopInfo) => ({
    type: CONSTANT_ACTIONS.SET_SHOP_INFO,
    payload: shopInfo
});

export const setCategorizedProducts = (products) => ({
    type: CONSTANT_ACTIONS.SET_CATEGORIZED_PRODUCTS,
    payload: products
});

export const toggleNavigateBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_NAVIGATE_BOTTOM_SHEET
});
export const setShowInHomeProducts = (products) => ({
    type: CONSTANT_ACTIONS.SET_SHOW_IN_HOME_PRODUCTS,
    payload: products
})