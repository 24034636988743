import _ from 'lodash';
import { toast } from 'react-toastify';

import * as DISPATCH_STATE from '../bookingChangeState';
import { GraphRequest } from '../../../../../axios';
import { appLoadingBegin, appLoadingEnd, appFetchFailure } from '../../commonActions';
import { fetchUpdatedJob, fetchJobInformationSuccessDispatch } from '../../jobsActions';
import { onNeverMindExtend } from '../../chatActions';
import * as LOG_EVENT from '../../../../analytics';
import { getPriceExtendJobRequestBody, extendJobRequestBody } from "../booking-gql";
/**
 * Get price for extend job
 * @param {*} id 
 * @param {*} locations 
 */
export function requestPriceExtendBooking(id, locations) {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const getPriceRequestBody = {
            query: getPriceExtendJobRequestBody,
            variables: {
                "productId": id ? id : getState().jobs.job.product.id,
                "priceModel": {
                    "jobLocations": locations,
                    "requestId": getState().jobs.job.id
                }
            }
        }
        GraphRequest.all(getPriceRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
                    dispatch(DISPATCH_STATE.bookingSetCurrentPrice(getState().jobs.job.jobTime.cubeNumbers));
                    dispatch(DISPATCH_STATE.fetchJobExtendPriceSuccess(res.data.data.productPriceList));
                    dispatch(fetchJobInformationSuccessDispatch(getState().jobs.job));
                    dispatch(appLoadingEnd());
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                }
            }).catch(error => {
                dispatch(appFetchFailure(error));
            });
    };
}
/**
 * Extend job request
 */
export function requestExtendJob() {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        const extendJob = {
            query: extendJobRequestBody,
            variables: {
                "jobId": getState().jobs.job.id,
                "extendJobData": {
                    "cubeNumbers": getState().booking.currentPrice
                }
            }
        }
        GraphRequest.all(extendJob)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.extendJob) {
                    dispatch(fetchUpdatedJob());
                    dispatch(onNeverMindExtend());
                    toast(`Time extension request submitted`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                    });
                    LOG_EVENT.logEvent(LOG_EVENT.REQUEST_EXTENDED_CWA, { 
                        currentPrice: getState().booking.currentPrice,
                        requestId: getState().jobs.job.id
                    });
                }
                if (json.data.errors) {
                    dispatch(onNeverMindExtend());
                    const errorMessage = json.data.errors[0].message.split(" , ").join(", ");
                    toast(`${errorMessage}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                    });
                    dispatch(appFetchFailure(json.data.errors[0].message));
                }
            });
    }
}