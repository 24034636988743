import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { hotjar } from "react-hotjar";
// import { toast } from 'react-toastify';

import './index.css';
import App from './components/app/App';
// import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './utils/redux/store';
// if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_SENTRY_ENV});
// }

ReactDOM.render(
    (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>
    )
, document.getElementById('root'));

hotjar.initialize(2064896, 6);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// let config = {
//     onUpdate: (registration) => {
//         toast.info(`New Update available! Reload the App to see the latest juicy changes.`, {
//             position: "bottom-center",
//             autoClose: 10000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             onClose: () => {
//                 serviceWorker.unregisterServiceWorker();
//                 window.location.reload();
//             }
//         });
//     },
// }

// serviceWorker.register(config);