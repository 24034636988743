import jwt from 'jwt-decode'
import { 
    localStorageIsTwa, 
    localStorageGetUsername, 
    localStorageGetToken,
    localStorageGetLocation,
    localStorageGetIosApp,    
} from '../functions/local-storage';
import { VERSION_CWA } from "../constants/index";


// export const SIGNED_UP_CWA = 'SIGNED_UP_CWA';
// export const ACCOUNT_ACTIVATED_CWA = 'ACCOUNT_ACTIVATED_CWA';
// export const ADDED_A_PAYMENT_CARD_CWA = 'ADDED_A_PAYMENT_CARD_CWA';
// export const OPENED_PRODUCT_CWA = 'OPENED_PRODUCT_CWA';
// export const REQUEST_CREATION_CWA = 'REQUEST_CREATION_CWA';
// export const JOB_COMPLETED_CWA = 'JOB_COMPLETED_CWA';
// export const JOB_RATED_POSITIVE_CWA = 'JOB_RATED_POSITIVE_CWA';

export const CUSTOMER_SIGN_UP_CWA = 'Customer Registered'; //DONE
export const CUSTOMER_VERIFY_CODE_CWA = 'Customer Verified Code'; //DONE
export const PAYMENT_CARD_ADDED_CWA = 'Card Added'; //DONE
export const PAYMENT_CARD_ADDED_FIRST_TIME_CWA = 'Card Added First Time'; //DONE
export const PRODUCT_OPENED_CWA = 'Service Opened'; //DONE
export const REQUEST_CREATED_CWA = 'Request Created'; //DONE
export const JOB_RATE_CWA = 'Job Rated';  //DONE
// export const JOB_RATE_NEGATIVE_CWA = 'Job Negative Rated';  //DONE
export const REQUEST_EXPIRED_CWA = 'Request Expired'; //DONE
export const REQUEST_RETRIED_CWA = 'Request Retried'; //DONE
export const REQUEST_MATCHED_CWA = 'Request Matched'; //DONE
export const REQUEST_CANCELLED_BEFORE_MATCHED_CWA = 'Request Cancelled Before Match'; //DONE
export const REQUEST_CANCELLED_AFTER_MATCHED_CWA = 'Request Cancelled After Match'; //DONE
export const REQUEST_STARTED_CWA = 'Request Started';
export const REQUEST_COMPLETED_CWA = 'Request Completed';
export const REQUEST_EXTENDED_CWA = 'Request Extended'; //DONE
export const FEEDBACK_GIVEN_CWA = 'Feedback Given'; //DONE
export const COMPLIMENT_GIVEN_CWA = 'Compliment Given'; //DONE
export const APP_OPENED_CWA = 'Application Opened'; //DONE
export const APP_OPENED_FIRST_TIME_CWA = 'Application Opened First Time'; //DDONE
export const CHAT_SUPPORT_OPENED_CWA = 'Chat Support Opened'; //DONE
export const WHATS_NEW_OPENED_CWA = 'What`s New Opened'; //DONE
export const SIGNUP_COMPLETED_SUCCESSFULLY = 'Signup Completed Successfully'; //DONE
export const SIGNUP_FAILED = 'Signup Failed'; //DONE
export const CUSTOMER_ONBOARDING_FINISHED = 'Customer Onboarding Finished'; //DONE
export const CUSTOMER_LOGOUT = 'Customer Logged Out'; //DONE

export const GOOGLE_PLACE_SERVICE_BY_GET_CURRENT_CWA = 'Google Place Service By Get Current'; //DONE
export const GOOGLE_PLACE_SERVICE_TEXT_BY_SEARCH_CWA = 'Google Place Service Text By Search'; //DONE
export const GOOGLE_GEOCODER_BY_MARKER_LOCATION_CWA = 'Google Geocoder By Marker Location'; //DONE
 
/**
 * 
 * @param {*} event 
 */
const isTwaOrCwa = (event) => {
    if (localStorageIsTwa()) {
        event = event.replace('CWA', 'TWA');
    }
    return event;
}

const getUserId = () => {
    try {
        const token = localStorageGetToken();
        if(!token) {
            return {};
        }
        const userDataEncoded = token.split(' ')[1]
        const { userId } = jwt(userDataEncoded);
        return { userId, partyId: window.partyId };
    } catch(ex) {
        console.error(ex);
        return {};
    }
}

const getUserOS = () => {
    const os = localStorageGetIosApp() ? "iOS" : "Android"; 
    return os;
}
/**
 * Log Event for firebase and facebook
 * @param {*} event 
 */
export const logEvent = (event, eventData) => {
    try {
        // if (window.FB) {
        //     window.FB.AppEvents.logEvent(isTwaOrCwa(event));
        // }
        // if (window.firebase && window.firebase.analytics) {
        //     window.firebase.analytics().logEvent(isTwaOrCwa(event));
        // }

        if (window.analytics) {
            const { track } = window.analytics;
            const mobileNumber = localStorageGetUsername();
            const applicationId = "CLIENT_APP";
            const tokenDetails = getUserId();
            const location = localStorageGetLocation();
            const OS = getUserOS();
            const eventBody = eventData ? 
                            { 
                                applicationId, 
                                applicationVersion: VERSION_CWA, 
                                mobileNumber, 
                                OS,
                                ...tokenDetails, 
                                ...location,
                                ...eventData,
                            } : { 
                                applicationId, 
                                applicationVersion: VERSION_CWA, 
                                mobileNumber, 
                                OS,
                                ...tokenDetails,
                                ...location,
                            }; 
            if(!!eventData && eventData.value) {
                eventBody.revenue = eventData.value;
            }
            
            if(!!eventData && eventData.requestId) {
                eventBody.order_id = eventData.requestId;
            }
            
            track(isTwaOrCwa(event), eventBody);
            // console.log("segment track", { mobileNumber, eventData , event })
        }
        // console.log("event track", { eventData , event })
    } catch(e) {
        console.error(e);
    }
}

export const identifyClientInSegment = (client) => {
    try {
        if(window.analytics) {
            const { identify } = window.analytics;
            const { givenName, lastName, emailAddress } = client;
            const identifyData = {
                givenName, lastName, emailAddress
            };
            // if(livingCity & livingCity.displayValue) {
            //     identify.city = livingCity.displayValue;
            // };
            // alias(client.id, identifyData)
            window.partyId = client.id;
            identify(client.id , identifyData);
        }
    } catch(e) {
        console.error(e)
    }
}