import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';

/**
 * start check code counter
 */
export const registerCheckCodeStartToCount = () => {
    return (dispatch, getState) => {
        if (!_.isNull(getState().register.requestNumberCheckCodeInterval)) {
            dispatch(onClearIntervalCheckCodeRequestNumber());
        }
        let countNumber = getState().register.requestNumberCheckCode;
        let interval = setInterval(() => {
            if (countNumber !== 0) {
                countNumber -= 1;
                if (countNumber < 10) {
                    dispatch(DISPATCH_STATE.registerCheckCodeReduceCount('0' + countNumber));
                } else {
                    dispatch(DISPATCH_STATE.registerCheckCodeReduceCount(countNumber));
                }
            }
        }, 1000);
        dispatch(DISPATCH_STATE.registerCheckCodeInterval(interval));
    }
}
/**
 * init code and number from url
 * @param {*} number 
 */
export const registerInitCheckCodePage = (number) => {
    return (dispatch, get) => {
        // dispatch(DISPATCH_STATE.registerInitCheckCodePageDispatch({
        //     code: getCodeFromPhoneNumber(number),
        //     number: getPhoneFromPhoneNumber(number),
        // }));
        dispatch(registerCheckCodeStartToCount());
    }
};
/**
 * clear interval of timing send request
 */
export const onClearIntervalCheckCodeRequestNumber = () => {
    return (dispatch, getState) => {
        clearInterval(getState().register.requestNumberCheckCodeInterval);
        dispatch(DISPATCH_STATE.clearIntervalNumberCheckcodeInterval());
    }
}
/**
 * get code from phone number from string
 * @param {*} tel 
 */
const getCodeFromPhoneNumber = (tel) => {
    return tel.substring(0, 3);
}
/**
 * get phone number from string
 * @param {*} tel 
 */
const getPhoneFromPhoneNumber = (tel) => {
    return parseInt(tel.substring(3));
}