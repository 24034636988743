import {
	INIT_JOB_CHAT_CHANNEL_NAME,
	INIT_MESSAGES_FROM_TWILIO_FIRST_TIME,
	INIT_MESSAGES_FROM_JOB_CHAT_HISTORY,
	CHAT_CONNECT_TO_AVAILABLE_ROOM,
	CHAT_PUT_MESSAGE_TO_MESSAGES_ARRAY,
	CHAT_ON_OPEN_MENU_OPTION,
	CHAT_ON_LIVE_LOCATION_PARTNER,
	CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE,
	CHAT_ON_CANCEL_JOB,
	CHAT_ON_EXTEND_JOB,
	CHAT_ON_NEVERMIND_CANCEL,
	CHAT_NOTE_TO_PARTNER,
	CHAT_ON_NEVERMIND_EXTEND,
	CHAT_ON_JOB_DETAIL_NAVIGATION,
	CHAT_ON_PHOTO_MESSAGE,
	CHAT_ON_CLOSE_PHOTO_MESSAGE,
	CHAT_WILL_UNMOUNT,
	CHAT_INIT_PAGING_FUNCTIONALITY,
	CHAT_PARTNER_IS_TYPING_START,
	CHAT_PARTNER_IS_TYPING_END,
	INIT_MESSAGES_DISPATCH,
	INIT_INTERVAL_GET_MESSAGE,
	GET_MESSAGE_WILL_UNMOUNT_DISPATCH,
	SET_LAST_INDEX,
	SET_OFFSET,
	SET_LIMIT,
	FETCH_MESSAGES_SUCCESS,
	UPDATE_MESSAGE_LIST,
	PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY,
	FETCH_MESSAGES_BEGIN,
	FETCH_MESSAGES_END,
	EMPTY_NEW_MESSAGES,
	SET_LAST_CONSUME_INDEX,
	INIT_PEERS_TOKEN,
	SET_ROOM_INFO,
	PARTICIPANT_JOINED_TO_ROOM,
	PARTICIPANT_Left_ROOM,
	GENERATE_PEERS_TOKEN_BEGIN,
	TOGGLE_VOICE_ON_VIDEO_CHAT,
	TOGGLE_VIDEO_ON_VIDEO_CHAT,
	SET_TOKEN_TYPE_FOR_VOIP,
	ADD_PARTNER_INFO,
	PARTNER_JOINED,
	PARTNER_NOT_JOINED
} from "../actions/chatActions";

const initialState = {
	messages: [],
	newMessages: [],
	updatedMessages: [],
	channel: null,
	channelRoom: null,
	optionMenu: false,
	livePartner: false,
	liveInternal: null,
	chatCancelModal: false,
	chatExtendModal: false,
	chatNoteToPartner: false,
	chatJobDetailModal: false,
	chatShowPhoto: null,
	chatPaging: null,
	isTyping: false,
	getMessageSetInterval: null,
	chatLastIndex: null,
	lastConsumeIndex: null,
	offset: null,
	isFetching: false,
	videoCallData: null,
	activeRoom: null,
	hasClientJoinedRoom: false,
	hasParticipantsJoinedRoom: false,
	goToVideoChat: false,
	toggleVoice: true,
	toggleVideo: true,
	tokenType: null,
	partnerInfo: {},
	partnerJoined: false,
};

export default function chatReducer(state = initialState, action) {
	switch (action.type) {
		case INIT_JOB_CHAT_CHANNEL_NAME:
			return {
				...state,
				channel: action.payload[0].data,
			};
		case INIT_MESSAGES_FROM_TWILIO_FIRST_TIME:
		case CHAT_PUT_MESSAGE_TO_MESSAGES_ARRAY:
			return {
				...state,
				messages: action.payload,
			};
		case CHAT_INIT_PAGING_FUNCTIONALITY:
			return {
				...state,
				chatPaging: action.payload,
			};
		case INIT_MESSAGES_FROM_JOB_CHAT_HISTORY:
			return {
				...state,
				messages: action.payload,
				newMessages: action.payload,
			};
		case CHAT_CONNECT_TO_AVAILABLE_ROOM:
			return {
				...state,
				channelRoom: action.payload,
			};
		case CHAT_ON_OPEN_MENU_OPTION:
			return {
				...state,
				optionMenu: !state.optionMenu,
			};
		case CHAT_ON_LIVE_LOCATION_PARTNER:
			return {
				...state,
				livePartner: !state.livePartner,
				liveInternal: action.payload,
			};
		case CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE:
			return {
				...state,
				livePartner: false,
				liveInternal: null,
			};
		case CHAT_ON_CANCEL_JOB:
			return {
				...state,
				chatCancelModal: !state.chatCancelModal,
			};
		case CHAT_ON_NEVERMIND_CANCEL:
			return {
				...state,
				chatCancelModal: !state.chatCancelModal,
			};
		case CHAT_NOTE_TO_PARTNER:
			return {
				...state,
				chatNoteToPartner: !state.chatNoteToPartner,
			};
		case CHAT_ON_NEVERMIND_EXTEND:
			return {
				...state,
				chatExtendModal: !state.chatExtendModal,
			};
		case CHAT_ON_JOB_DETAIL_NAVIGATION:
			return {
				...state,
				chatJobDetailModal: !state.chatJobDetailModal,
			};
		case CHAT_ON_EXTEND_JOB:
			return {
				...state,
				chatExtendModal: !state.chatExtendModal,
			};
		case CHAT_ON_PHOTO_MESSAGE:
			return {
				...state,
				chatShowPhoto: action.payload,
			};
		case CHAT_ON_CLOSE_PHOTO_MESSAGE:
			return {
				...state,
				chatShowPhoto: null,
			};
		case CHAT_PARTNER_IS_TYPING_START:
			return {
				...state,
				isTyping: true,
			};
		case CHAT_PARTNER_IS_TYPING_END:
			return {
				...state,
				isTyping: false,
			};
		case CHAT_WILL_UNMOUNT:
			return {
				...state,
				channel: null,
				chatPaging: null,
				chatShowPhoto: null,
				chatCancelModal: false,
				chatExtendModal: false,
				chatJobDetailModal: false,
				chatNoteToPartner: false,
				newMessages: [],
			};
		case INIT_MESSAGES_DISPATCH:
		case FETCH_MESSAGES_SUCCESS:
		case PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY:
			return {
				...state,
				newMessages: action.payload,
			};

		case UPDATE_MESSAGE_LIST:
			return {
				...state,
				updatedMessages: action.payload,
			};
		case INIT_INTERVAL_GET_MESSAGE:
			return {
				...state,
				getMessageSetInterval: action.payload,
			};
		case GET_MESSAGE_WILL_UNMOUNT_DISPATCH:
			return {
				...state,
				getMessageSetInterval: null,
			};
		case SET_LAST_INDEX:
			return {
				...state,
				chatLastIndex: action.payload,
			};
		case SET_OFFSET:
			return {
				...state,
				offset: action.payload,
			};
		case SET_LIMIT:
			return {
				...state,
				limit: action.payload,
			};
		case FETCH_MESSAGES_BEGIN:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MESSAGES_END:
			return {
				...state,
				isFetching: false,
			};
		case EMPTY_NEW_MESSAGES:
			return {
				...state,
				newMessages: [],
			};
		case SET_LAST_CONSUME_INDEX:
			return {
				...state,
				lastConsumeIndex: action.payload,
			};
		case INIT_PEERS_TOKEN:
			return {
				...state,
				videoCallData: action.payload
			}
		case SET_ROOM_INFO:
			return {
				...state,
				activeRoom: action.payload,
				hasClientJoinedRoom: true,
			};
		case PARTICIPANT_JOINED_TO_ROOM:
			return {
				...state,
				hasParticipantsJoinedRoom: true
			}
		case PARTICIPANT_Left_ROOM:
			return {
				...state,
				hasParticipantsJoinedRoom: false
			}
		case GENERATE_PEERS_TOKEN_BEGIN:
			return {
				...state,
				goToVideoChat: true
			}
		case TOGGLE_VOICE_ON_VIDEO_CHAT:
			return {
				...state,
				toggleVoice: !state.toggleVoice
			}
		case TOGGLE_VIDEO_ON_VIDEO_CHAT:
			return {
				...state,
				toggleVideo: !state.toggleVideo
			}
		case SET_TOKEN_TYPE_FOR_VOIP:
			return {
				...state,
				tokenType: action.payload
			}
		case ADD_PARTNER_INFO:
			return  {
				...state,
				partnerInfo: action.payload
			}
		case PARTNER_JOINED:
			return {
				...state,
				partnerJoined: true,
			}
		case PARTNER_NOT_JOINED:
			return {
				...state,
				partnerJoined: false
			}
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}