import _ from 'lodash';

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import { savedPlacesHomeWork } from '../../../../functions/saved-places-home-work';
import { appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';

/**
 * Fetch all saved places
 */
export const fetchSavedPlaces = () => {
    return (dispatch) => {
        const allSavedPlaces = {
            query: `query SavedPlaces {
                savedPlaces {
                    specific{
                        id,
                        title,
                        location {
                            id,
                            name,
                            address,
                            lat,
                            lng
                        },
                        type,
                        isBookmarked
                    },
                    extra {
                        id,
                        title,
                        location {
                            id,
                            name,
                            address,
                            lat,
                            lng
                        },
                        type,
                        isBookmarked
                    }
                }
            }`
        }
        GraphRequest.all(allSavedPlaces)
            .then((json) => {
                if (!_.isNull(json.data.data) && json.data.data.savedPlaces) {
                    dispatch(DISPATCH_STATE.savedPlacesFetchAll(json.data.data.savedPlaces));
                    dispatch(DISPATCH_STATE.savedPlacesFindHomeWork(savedPlacesHomeWork(json.data.data.savedPlaces['specific'])));
                }
            })
            .catch((e) => dispatch(appFetchFailure(e)))
    }
}